import React, {useEffect, useState} from 'react'
import moment from 'moment-timezone'
import StatCard from './StatCard'
import db from '../../config/db';

export default function OnSite() {
    const firestore = db.firestore();
    const [todayDetails, setTodayDetails] = useState<any>({})

    useEffect(() => {
        let day = moment().tz('America/Argentina/Buenos_Aires').set({hour:0,minute:0,second:0,millisecond:0}).toDate()
        getFinishedToday().then((res: any[]) => {
            let data = {
                purchases: res?.filter(el => el.payment === "MercadoPago").length || '-',
                all: res?.filter(el => ['DONE', 'DONE:LAB', 'DONE:RESULT'].includes(el.status)).length || '-',
                antigenos: res?.filter(el =>  el.service === "Antígenos").length || '-',
                pcr: res?.filter(el =>  el.service === "PCR LABORATORIO").length || '-',
                pcrExpress: res?.filter(el =>  el.service === "PCR Express").length || '-'
            }
            setTodayDetails(data)
        })
    }, [])

    function getFinishedToday():any {
        let analysisQuery = firestore
            .collection("events/requests/analysis")
            .where("dt_cierre", ">=", moment().set({hour:0,minute:0,second:0,millisecond:0}).toDate())
        return new Promise(async (resolve, reject) => {
            const pending: any[] = []
            const query = await analysisQuery.get()
            query.forEach((el) => {
                let data = el.data()
                pending.push({ 
                    status: data.status,
                    service: data.service,
                    payment: data.payment_data?.method, id: el.ref.id
                })
            })
            return resolve(pending)
        })
    }

    return <div className="statistics__container">
        <h2>Hisopados presenciales {moment().format('DD/MM/YYYY')}</h2>
        <div className="statistics__general">
            <StatCard text="Total de hisopados cargados" number={todayDetails?.all || ''} forceClass="second" />
            <StatCard text="Comprados en la app" number={todayDetails?.purchases || ''} forceClass="first" />
            <StatCard text="Antígenos" number={todayDetails?.antigenos || ''} forceClass="fourth" />
            <StatCard text="PCR Laboratorio" number={todayDetails?.pcr || ''} forceClass="fifth" />
            <StatCard text="PCR Express" number={todayDetails?.pcrExpress || ''} forceClass="sixth" />
        </div>
    </div>
}
