export const validateHelper = {
    analysis:{
        allFields: JSON.stringify(["fullname", "dni", "sex", "dob", "ws", "address", "location", "service", "client", "user_email", "os_email", "corporate"]),
        requiredFields: JSON.stringify(["fullname", "dni", "sex", "dob", "ws", "address", "location", "service", "user_email"]),
        validations: JSON.stringify({"service":["PCR Express", "Antigenos", "Vacuna Antigripal", "PCR LABORATORIO"]})
    },
    delivery: {
        allFields: JSON.stringify(["fullname", "dni", "sex", "dob", "ws", "user_address", "service", "service_address", "service_location", "service_number", "service_floor", "promise", "client", "observations", "user_email", "os_email", "corporate"]),
        requiredFields: JSON.stringify(["fullname", "dni", "sex", "dob", "ws", "service_address", "service_location", "service", "user_email"]),
        validations: JSON.stringify({"service":["HISOPADO", "VACUNA ANTIGRIPAL", "PCR", "ANTIGENOS"], "_shouldBeDate":["dob", "promise"], "_shouldBeAddress": "service_address", "_shouldBeLocation": "service_location" })
    }
} as any