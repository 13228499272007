import "./constancia.scss"
import {texts} from './texts'
import { langHelper, logoHelper, methodHelper, resultsHelper, validatorHelper } from "./pdfHelpers"
import moment from "moment"
import QRCode from 'qrcode.react'

const Constancy = (props:any) => {
        const { data, id } = props;
        const {patient, lab, service, dt_cierre, dt_assign} = data

        const patientDataEs = [
            {label: 'Paciente', value: patient.fullname && patient.fullname !== 'undefined' ? patient.fullname : patient.user},
            {label: 'DNI', value: patient.dni},
            {label: 'Fecha', value: moment(dt_cierre ? dt_cierre.toDate() : new Date()).format("DD/MM/YYYY")},
            
        ]
        const patientDataEn = [
            {label: 'Patient', value: patient.fullname && patient.fullname !== 'undefined' ? patient.fullname : patient.user},
            {label: 'DNI', value: patient.dni},
            {label: 'Date', value: moment(dt_cierre ? dt_cierre.toDate() : new Date()).format("DD/MM/YYYY")},
        ]

        const result={
            es: resultsHelper[service]?.[lab.result_lab],
            en: resultsHelper[service]?.[lab.result_lab]
        } as any
        const patientData = {
            es: patientDataEs,
            en: patientDataEn
        } as any
        const logo = logoHelper[service] || [] as any
        const lang = langHelper[service] as any
        const studieType = service as any

        const method = {
            es: methodHelper.es[service],
            en: methodHelper.en[service]
        } as any
        const validator = validatorHelper[service] as any

        return (
            <div className="constancyContainer">
                {logo && logo?.length > 0 && (
                    <div className="logosContainer">
                        {logo && logo?.map((el:any, i:number) => (
                            <img id='logo' key={i} className="logo-uma" src={el} alt="logo" />
                        ))}
                    </div>                
                )}
                {lang && lang.map((lng:any) => (
                    <div className='dataContainer'>
                        <div className="patient-data">
                        {patientData[lng].map((row: any) => (
                            <p>
                                <b>
                                    {row.label}: {row.value}
                                </b>
                            </p>
                        ))}
                        </div>
                        <br/>
                        <div className="constancy-div">
                            <b>
                                {texts[studieType]?.description?.title[lng]}
                            </b>
                        </div>
                        <br/>
                        <div className="constancy-div">
                            <b className="constancy-subtitle">
                                {texts[studieType]?.description?.method[lng]}
                                {method[lng]}
                            </b> <b></b>
                        </div>
                        <br/>
                        {texts[studieType]?.description?.task?.[lng] && (
                            <div className="constancy-div">
                                <b>{texts[studieType]?.description?.task[lng]}</b>
                            </div>
                        )}
                        <br/>
                        <div className="constancy-div">
                            <b className="constancy-subtitle">
                                {texts[studieType]?.resultData?.result?.[lng]}
                            </b>
                            <b>{result[lng]}</b>
                        </div>
                        <br/>
                        <div className="validation-div">
                            <p>{texts[studieType]?.resultData?.validation[lng]}</p>
                            <span>{validator}</span>
                        </div>
                    </div>                
                ))}
                <div className="verify-attention">
                    <section>
                        <div>
                            <div>
                                <p>Verifique la atención en: </p>
                                <a href="http://umasalud.com/service">http://umasalud.com/service</a>
                            </div>
                            <p>Código:</p>
                            <p>{btoa(id)}</p>
                        </div>
                    </section>
                    <div className="qr-code">
                        <QRCode value={`http://umasalud.com/service/${patient.uid}/${btoa(id)}`} />
                    </div>
                </div>
            </div>
        )
}

export default Constancy;
