import React from 'react'

export default function StatCard({text, number, forceClass } : {text: string, number: string, forceClass:string}) {
    return (
        <div className="statistics__littlebox">
            <div className={`littlebox__header ${forceClass}`}>
            <div className="littlebox__statEvolution">
                <div className="littlebox__number">{number}</div>
                <div className="littlebox__progress"></div>
            </div>
            <div className="littlebox__stat">{text}</div>
            </div>
        </div>
    )
}
