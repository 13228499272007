import React, { useState, useEffect, useCallback } from 'react';
import { FaChevronDown, FaChevronUp, FaTrashAlt } from 'react-icons/fa';
import moment from "moment"
import db from '../../../../config/db';
import SearchBox from '../../../GeneralComponents/SearchBox'
import GoogleMapReact from 'google-map-react';
import { currentPositionHandler, mapConfig } from '../../../../utils/mapsApiHandler';
import { useDispatch, useSelector } from 'react-redux'
import Select from 'react-select'
import { payment_references } from '../../../../constants/CECO'
import { checkNum } from '../../../../utils/Validations';
import swal from 'sweetalert';

const HisopadoCartItem = ({ patient, index, onRemove, onChange, serviceType }:any) => {
    const dispatch = useDispatch()
    const firestore = db.firestore();
    const [openUser, setOpenUser] = useState(patient.isOpen);
    const { currentUser, user } = useSelector((state:any) => state.user)
    const [openModal, setOpenModal] = useState(false);
    const [showBtn, setShowBtn] = useState(true);
    const [hisopadoStatus, setHisopadoStatus] = useState('')
    const [patientUID, setPatientUID] = useState('') 
    const [mapInstance, setMapInstance] = useState(undefined)
    const [mapApi, setMapApi] = useState(undefined)
    const [geocoder, setGeocoder] = useState<any>();
    const [userGeoguessedAddress, setUserGeoguessedAddress] = useState()
    const [data, setData] = useState({
        assigned_status: serviceType ==='delivery' ? 'PREASSIGN': 'PAYMENT',
        csvUploadedBy: currentUser.uid,
        cuit_delivery: '',
        uid_delivery: '',
        cuit_nurse: '',
        uid_nurse: '',
        client: user.corporate === 'DOSUBA' ? '3054666656': '',
        destination: {
            user_address: '',
            user_floor: '',
            user_number: '',
            user_lat: 0,
            user_lon: 0,
            user_cp: '',
            user_region: '',
            user_locality: '',
            user_country: '',
            user_obs: ''
        },
        fullname_nurse: '',
        incidente_id: '',
        patient: {
            dni: '',
            address: '',
            country: 'AR',
            dob: '',
            sex: '',
            user: '',
            ws: '',
            uid: '',
            user_email: '',
            os_email: '',
            corporate: user.corporate === 'DOSUBA' ? 'DOSUBA': ''
        },
        payment_reference: user.corporate === 'DOSUBA' ? 'SALUD Y ESTADO': '',
        promise: '',
        result_lab: '',
        service: '',
        title: '',
    });

    const getUserUID = useCallback((dni='') => {
        firestore.collection('user')
            .where('dni', '==', dni)
            .get()
            .then((snap:any)=>{
                snap.forEach((data:any) => {
                    setPatientUID(data.data().core_id)
                    // setData({ ...data, patient: {...data.patient, uid: data.data().core_id} });
                })
            })
    }, [data])

    const getNurse_uid = (cuit: any) => {
        let uid_nurse = ''
        firestore.collection('providers').where('cuit', '==', cuit)
          .get().then((doc: any) => {
            if(doc.docs.length === 0) return swal('no existe este enfermero')
            uid_nurse = doc.docs[0].id
            setData({...data, 'cuit_nurse': cuit, 'uid_nurse': uid_nurse })
            });
          return uid_nurse
    }
    
    const getDelivery_uid = (cuit: any) => {
        let uid_delivery = ''
        firestore.collection('providers').where('cuit', '==', cuit)
          .get().then((doc: any) => {
            if(doc.docs.length === 0) return swal('no existe este chofer')
            uid_delivery = doc.docs[0].id
            setData({...data, 'cuit_delivery': cuit, 'uid_delivery': uid_delivery })
            });
          return uid_delivery
    }
    
    useEffect(() => {
        if (data) {onChange(index, data)}
    }, [data])

    useEffect(() => {
        if(data.patient.dni){
            getUserUID(data.patient.dni)
        }
    }, [data.patient.dni])

    useEffect(() => {
        setData({ ...data, patient: {...data.patient, uid:patientUID} });
    }, [patientUID])

    useEffect(()=>{
        if(hisopadoStatus === "DONE:RESULT" && data.result_lab === ''){
            setData({...data, result_lab:"NEGATIVE"})
        } else if(hisopadoStatus === "PREASSIGN") {
            setData({...data, result_lab:""})
        }
    }, [hisopadoStatus])

    const handleChangePlace = async (place: any) => {
        let address_details = await getAddressObject(place.address_components)
        const pos = {
            lat: place?.lat || place?.geometry?.location?.lat() || "",
            lng: place?.lng || place?.geometry?.location?.lng() || "",
            address: place?.formatted_address,
            ...address_details
        };
        dispatch({ type: 'SET_ADDRESS_LAT_LONG', payload: { lat: pos.lat, lng: pos.lng } })
        dispatch({ type: 'SET_DELIVERY_PATIENT_ADDRESS', payload: place.formatted_address })
        return handleForm(pos);
    };

    async function getAddressObject(address_components: any) {
        let ShouldBeComponent: any = {
            number: ["street_number"],
            cp: ["postal_code"],
            street: ["street_address", "route"],
            region: [
                "administrative_area_level_1",
            ],
            locality: [
                "locality",
                "sublocality",
                "sublocality_level_1",
                "administrative_area_level_2",
                "sublocality_level_2",
                "sublocality_level_3",
                "sublocality_level_4",
                "administrative_area_level_3",
                "administrative_area_level_4",
                "administrative_area_level_5"
            ],
            country: ["country"]
        };

        let address: any = {
            number: "",
            cp: "",
            street: "",
            region: "",
            locality: "",
            country: "",
        };
        address_components.forEach((component: any) => {
            for (let shouldBe in ShouldBeComponent) {
                if (ShouldBeComponent[shouldBe].indexOf(component.types[0]) !== -1) {
                    if (address[shouldBe] === "") {
                        address[shouldBe] = component.short_name;
                    }
                }
            }
        });
        return address;
    }

    const handleForm = (event: any) => {
        return setData({ ...data, destination: { ...data.destination, user_address: event.address, user_cp: event.cp, user_region: event.region, user_locality: event.locality, user_country: event.country, user_lat: event.lat, user_lon: event.lng}});
    };

    const handleApiLoaded = (map: any, maps: any) => {
        setMapInstance(map);
        setMapApi(maps);
        setGeocoder(new maps.Geocoder());
        return null
    };

    function geocodeLatLng({ lat, lng }: { lat: any, lng: any }) {
        if (!geocoder) return null
        let result;
        const latlng = {
            lat: parseFloat(lat) || -31.374420,
            lng: parseFloat(lng) || -64.132140,
        };
        dispatch({ type: 'SET_ADDRESS_LAT_LONG', payload: latlng })
        geocoder.geocode({ location: latlng }, (results: any, status: any) => {
            if (status === 'OK') {
                if (results[0]) {
                    result = results[0];
                    handleChangePlace(result);
                }
            }
        });
    }

    return (
        <article className="HisopadoCart__user">
            <div className="HisopadoCart__userTitle" onClick={() => {
                setOpenUser(!openUser);
            }}>
                <p className="HisopadoCart__userName">{data.patient.user || data.title}</p>
                {
                    !openUser ?
                        <FaChevronDown /> :
                        <FaChevronUp />
                }
            </div>
            <div className={`HisopadoCart__userData ${openUser ? 'open' : ''}`}>
                <div>
                    <label>Nombre y apellido</label>
                    <input
                        type="text"
                        required
                        inputMode="text"
                        value={data.patient.user || ''}
                        onChange={(e) => {
                            setData({ ...data, patient: {...data.patient, user:e.target.value} });
                        }}
                    />
                </div>
                <div className="columns">
                    <div>
                        <label>Identificación, cédula o DNI</label>
                        <input
                            type="text"
                            required
                            inputMode="numeric"
                            value={data.patient.dni || ''}
                            onChange={(e) => {
                                setData({ ...data, patient: {...data.patient, dni:e.target.value}, incidente_id: `${e.target.value}_${moment().format("YYYYMMDDHHmmss")}` });
                            }}
                        />
                    </div>
                    <div>
                        <label>N° de celular</label>
                        <input
                            type="text"
                            required
                            inputMode="tel"
                            // value={data.patient.ws || ''}
                            placeholder="54911..."
                            onChange={(e) => {
                                setData({ ...data, patient: {...data.patient, ws: checkNum(e.target.value)} });
                            }}
                        />
                    </div>
                </div>
                <div className="columns">
                    <div>
                        <label>Cliente</label>
                        <input
                            type="text"
                            inputMode="text"
                            value={data.client || ''}
                            onChange={(e) => {
                                setData({ ...data, client: e.target.value} );
                            }}
                        />
                    </div>
                    <div>
                        <label>Obra Social</label>
                        <input
                            type="text"
                            inputMode="text"
                            value={data.patient.corporate || ''}
                            onChange={(e) => {
                                setData({ ...data, patient: {...data.patient, corporate: e.target.value}} );
                            }}
                        />
                    </div>
                </div>
                <div className="columns">
                    <div>
                        <label>Email del paciente</label>
                        <input
                            type="text"
                            required
                            inputMode="text"
                            value={data.patient.user_email || ''}
                            onChange={(e) => {
                                setData({ ...data, patient: {...data.patient, user_email: e.target.value} });
                            }}
                        />
                    </div>
                    <div>
                        <label>Email de la OS</label>
                        <input
                            type="text"
                            inputMode="text"
                            value={data.patient.os_email || ''}
                            onChange={(e) => {
                                setData({ ...data, patient: {...data.patient, os_email: e.target.value} });
                            }}
                        />
                    </div>
                </div>

                <div className="columns">
                    <div>
                        <label>Fecha de nacimiento</label>
                        <input
                            type="date"
                            required
                            value={data.patient.dob || ''}
                            onChange={(e) => {
                                setData({ ...data, patient: {...data.patient, dob:e.target.value} });

                            }}
                        />
                    </div>
                    <div>
                        <label>Sexo</label>
                        <select
                            value={data.patient.sex || 'none'}
                            name="sexo"
                            onChange={(e) => {
                                setData({ ...data, patient: {...data.patient, sex:e.target.value} });
                            }}
                        >
                            <option value="">- Seleccionar -</option>
                            <option value="M">Masculino</option>
                            <option value="F">Femenino</option>
                        </select>
                    </div>
                </div>

                <div>
                    <label>Domicilio {serviceType === 'delivery' ? 'del servicio' : ''}</label>
                    <div className='container_map'>
                        <GoogleMapReact
                            {...mapConfig(
                                { lat: data.destination.user_lat, lng: data.destination.user_lon },
                                geocodeLatLng
                            )}
                            onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
                        >
                        </GoogleMapReact>
                    </div>
                    {mapInstance && mapApi && <><SearchBox map={mapInstance} mapApi={mapApi} handleChangePlace={handleChangePlace} value={data.destination?.user_address} /></>}
                </div>

                <div className="columns">
                    <div>
                        <label>Piso</label>
                        <input
                            type="text"
                            value={data.destination.user_floor || ''}
                            onChange={(e) => {
                                setData({ ...data, destination: { ...data.destination, user_floor: e.target.value } });
                            }}
                        />
                    </div>
                    <div>
                        <label>Departamento</label>
                        <input
                            type="text"
                            value={data.destination.user_number || ''}
                            onChange={(e) => {
                                setData({ ...data, destination: { ...data.destination, user_number: e.target.value } });
                            }}
                        />
                    </div>
                </div>

                {
                    serviceType === 'delivery' &&
                    <div>
                        <label>Domicilio del paciente</label>
                        <div className='container_map'>
                        </div>
                        <input
                            value={data.patient.address}
                            onChange={(e) => {setData({...data, patient: {...data.patient, address: e.target.value}})}}
                            type='text'
                            placeholder="Dirección"
                            autoComplete="nooo"
                        />
                    </div>
                }

                <div>
                    <label>Observaciones</label>
                    <input
                        type="text"
                        inputMode="text"
                        placeholder="Aclaración para el personal médico"
                        value={data.destination.user_obs || ''}
                        onChange={(e) => {
                            setData({ ...data, destination: { ...data.destination, user_obs: e.target.value }});
                        }}
                    />
                </div>
                {
                    ['ADMINISTRATIVE', 'Comercial', 'Medicina Laboral', 'Operaciones Supervisor', 'Call Center Supervisor', 'Jefe Operaciones'].includes(user?.rol) &&
                    <div className="columns">
                        <div>
                            <p className="label">Código de referencia</p>
                            <Select
                                onChange={(value: any) => setData({ ...data, payment_reference: value.value})}
                                defaultInputValue={data.payment_reference}
                                options={payment_references}
                            />
                        </div>
                        { serviceType === 'delivery' &&
                        <div>
                            <label>Programar servicio</label>
                            <input
                                type="date"
                                value={data.promise || ''}
                                onChange={(e) => {
                                    setData({ ...data, promise: moment(e.target.value).format('YYYY-MM-DD') });

                                }}
                            />
                        </div>
                        }
                    </div>
                }
                {
                    serviceType === 'delivery' ?
                <div>
                    <label>Servicio</label>
                    <select
                        defaultValue={data.service || ''}
                        onChange={(e) => {
                            setData({ ...data, service: e.target.value });
                        }}
                    >
                        <option value="">- Seleccionar -</option>
                        <option value="HISOPADO">Antígenos</option>
                        <option value="PCR">PCR</option>
                        <option value="VACUNA ANTIGRIPAL">Vacuna antigripal</option>
                    </select>
                </div>
                :
                <div>
                    <label>Servicio</label>
                    <select
                        defaultValue={data.service || ''}
                        onChange={(e) => {
                            setData({ ...data, service: e.target.value });
                        }}
                    >
                        <option value="">- Seleccionar -</option>
                        <option value="PCR Express">PCR Express</option>
                        <option value="PCR LABORATORIO">PCR Laboratorio</option>
                        {
                            user.corporate !== 'DOSUBA' &&
                            <>
                                <option value="Antígenos">Antígenos</option>
                            </>
                        }
                    </select>
                </div>
                
                }

                {data.service && serviceType === 'delivery' && (
                    <>
                        <div>
                            <label>Estado</label>
                            <select
                                defaultValue={data.assigned_status || ''}
                                onChange={(e) => {
                                    setData({ ...data, assigned_status: e.target.value });
                                    setHisopadoStatus(e.target.value);
                                }}
                            >
                                <option value="">- Seleccionar -</option>
                                <option value="PREASSIGN">PRE-ASIGNADO</option>
                                {
                                    data.service === 'HISOPADO' ?
                                        <option value="DONE:RESULT">HECHO (CON RESULTADO)</option> :
                                        <option value="DONE">HECHO</option>
                                }
                            </select>
                            {data.service === 'HISOPADO' && hisopadoStatus === "DONE:RESULT" &&
                                <>
                                    <select
                                        onChange={(e) => {
                                            setData({ ...data, result_lab: e.target.value });
                                        }}
                                    >
                                        <option value="">- Seleccionar -</option>
                                        <option value="NEGATIVE">NEGATIVO</option>
                                        <option value="POSITIVE">POSITIVO</option>
                                    </select>
                                </>
                            }
                        </div>

                        <div className="columns">
                            <div>
                                <input
                                    placeholder="Nombre Enfermera/o"
                                    onChange={(e) => {
                                        setData({ ...data, fullname_nurse: e.target.value });
                                    }} 
                                />
                            </div>
                            <div>
                                <input
                                    placeholder="Cuit Enfermera/o"
                                    onChange={(e) => {
                                        if(e.target.value.length >= 11) {
                                            getNurse_uid(e.target.value)
                                        }
                                    }}
                                />
                            </div>
                        </div>
                        <div>
                            <input
                                placeholder="Cuit Chofer"
                                onChange={(e) => {
                                    // setData({ ...data, cuit_delivery: e.target.value });
                                    if(e.target.value.length >= 11) {
                                        getDelivery_uid(e.target.value)
                                    }
                                }} 
                            />
                        </div>
                    </>
                )}

                {/* {
                    !patient.id && showBtn ?
                    <>
                        <button className="HisopadoCart__btnConfirm" onClick={handleConfirm}>Guardar</button>
                    </> :
                    null
                } */}

                {index ? <button className="HisopadoCart__btnDelete" onClick={onRemove}><FaTrashAlt /></button> : ""}
            </div>
            <br />
        </article>
    )
}

export default HisopadoCartItem;