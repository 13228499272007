import React from 'react';
import './styles/index.scss';
import './styles/Reset.scss';
import { Route, Switch } from 'react-router-dom';
import Hisopados from './components/Hisopados/presencial';
import PrivateRoute from './PrivateRoute';
import CargarCSV from './components/CargaCSV';
import { NotFound } from './components/404-unauth';
import HisopadosDomicilio from './components/Hisopados/domicilio';
import HisopadosPreassignMap from './components/Hisopados/domicilio/map';
import Stats from './components/Stats';

const App = () => {

  return (
    <>
      <Switch>
        <PrivateRoute exact path='/' component={Hisopados} />
        <PrivateRoute exact path='/presenciales' component={Hisopados} />
        <PrivateRoute exact path='/carga-masiva' component={CargarCSV} />
        <PrivateRoute exact path='/domicilio' component={HisopadosDomicilio} />
        <PrivateRoute exact path='/domicilio-map' component={HisopadosPreassignMap} />
        <PrivateRoute exact path='/stats/:service?' component={Stats} />
        {/* <PrivateRoute exact path='/hisopados-presenciales' component={Hisopados} /> */}
        <Route component={NotFound} />
      </Switch>
    </>
  );
}

export default App;
