const isLocal = window.location.origin.includes('localhost');
const NODE_DEV = `http://localhost:8080`;
const NODE_SERVER = isLocal ? NODE_DEV : process.env.REACT_APP_NODE_URL;

// Analysis
export const analysis = `${NODE_SERVER}/analysis`;

export const corporate = `${NODE_SERVER}/corporate`;
export const providers = `${NODE_SERVER}/providers`;
//CSV
export const validate_csv = `${NODE_SERVER}/csv/validate`;
export const upload_csv = `${NODE_SERVER}/csv/upload`;
// laboratory
export const update_stock = `${NODE_SERVER}/stock/delivery_laboratory`;

export const mobility_batch_hisopados = `${NODE_SERVER}/delivery/mobility_batch_hisopados`;
export const mobility_update_document = `${NODE_SERVER}/delivery/mobility_update_document`;
export const pdf = `${NODE_SERVER}/pdf`;