import React, { useEffect, useState, useContext } from "react";
import { Route, withRouter,  } from "react-router-dom";
import { AuthContext } from "./AuthProvider";
// ----- Login
import Login from "./components/Login";
import Loading from './components/GeneralComponents/Loader'

const LoginComponent = () => {
    const [delay, setDelay] = useState(false)

    useEffect(() => {
        let timeout = setTimeout(() => setDelay(true), 2000)
        return () => clearTimeout(timeout)
    }, [])

    if (delay) {
        return <Login />
    } else {
        return <Loading />
    }
}

const PrivateRoute = ({ component: RouteComponent, authed, ...rest }) => {
    const { currentUser } = useContext(AuthContext)
    
    return (
        <Route
            {...rest}
            render={routeProps =>
                !!currentUser ? <RouteComponent {...routeProps} /> : <LoginComponent />
            }
        />
    )
}


export default withRouter(PrivateRoute)