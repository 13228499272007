import React from 'react'
import { FaTimes } from 'react-icons/fa'
import './Modal.scss'

interface Props {
    children: React.ReactElement;
    close(): void
}

const Modal: React.FC<Props> = ({ children, close}) => {
    return (
        <div className="overlay_modal">
            <div className="modal">
                <div className="modal_header">
                    <button onClick={close}>
                        <FaTimes />
                    </button>
                </div>

                <div className="modal_body">
                    {children}
                </div>
            </div>
        </div>
    )
}

export default Modal