import React, { useState, useEffect } from 'react'
import { analysis, pdf } from '../../../config/endpoints'
import { useSelector, useDispatch } from 'react-redux'
import axios from 'axios'
import moment from 'moment'
import db from '../../../config/db'
import { payment_references } from '../../../constants/CECO'
import { methodHelper, resultsHelper, validatorHelper } from '../../GeneralComponents/Pdf/pdfHelpers'

const ModalContent:React.FC = () => {
    const dispatch = useDispatch()
    const firestore = db.firestore()
    const auth = db.auth()
    
    const { modalData, consultingRooms } = useSelector((state: any) => state.utils)
    const { user } = useSelector((state: any) => state.user)
    const [formData, setFormData] = useState({
        fullname: modalData.data.patient.fullname || modalData.data.patient.user,
        dni: modalData.data.patient.dni,
        dob: modalData.data.patient.dob,
        ws: modalData.data.patient.ws,
        address: modalData.data.patient.address || modalData.data.destination.user_address,
        location: modalData.data.patient.location || modalData.data.destination.user_locality,
        cm: `${modalData.data.patient.cm}`,
        testType: modalData.data.service,
        testComplete: (
            modalData.data.status === 'FREE' || modalData.data.status === 'PAYMENT' ? 'No' : 
            modalData.data.status === 'DONE' || modalData.data.status === 'PENDING:RESULT' || modalData.data.status === 'DONE:RESULT' ? 'Sí' : ''),
        testResult: modalData.data.lab.result_lab,
        payment_reference: modalData.data.payment?.reference || modalData.data.payment_data?.reference,
        testStatus: modalData.data.status
    })

    useEffect(() => {
        if (!consultingRooms || !consultingRooms.length) {
            firestore.collection('parametros').doc('userapp').collection('analysis').doc('antigenos').get()
                .then((doc: any) => {
                    if (doc.exists) {
                        dispatch({ type: 'SET_CONSULTING_ROOMS', payload: doc.data().consulting_rooms })
                    }
                })
                .catch(e => console.error(e))
        }
    }, [consultingRooms])

    const handleChangeData = (e: any) => {       
        if(e.target.name === 'testResult'){
            if(['POSITIVE', 'NEGATIVE'].includes(e.target.value)){
                setFormData({...formData, [e.target.name]: e.target.value, testStatus: 'DONE:RESULT'})
            } else {
                setFormData({...formData, [e.target.name]: e.target.value, testStatus: ''})
            }
        } else {
            setFormData({...formData, [e.target.name]: e.target.value})
        }
    }

    const handleUpdateData = async (e: any) => {
        e.preventDefault()

        let status = 'PAYMENT'

        if (['Personal Médico', 'ADMINISTRATIVE'].includes(user?.rol)) {
            if (formData.testType !== 'PCR LABORATORIO' && formData.testComplete === 'Sí' && !formData.testResult || formData.testResult === 'off') {
                alert('Debe seleccionar el resultado del test.');
                return
            } 

            if(formData.testStatus === 'DONE:RESULT' && (!formData.testResult || formData.testResult === 'off')) {
                alert('Debe seleccionar el resultado del test.');
                return
            }
    
            if (formData.testComplete === 'Sí' && formData.testResult && formData.testResult !== 'off' && !formData.cm || formData.cm === 'off' || formData.cm === 'undefined') {
                alert('Debe seleccionar el punto de testeo');
                return
            } 
    
            if (formData.testComplete === 'No' && formData.testResult && formData.testResult !== 'off') {
                alert('No puede guardar un resultado si el hisopado no fue realizado. Por favor verifique las opciones e intente de nuevo');
                return
            } 
    
            if (formData.testComplete === 'Sí' && formData.testResult && formData.testResult !== 'off' && !formData.address || formData.address === '' || formData.address === 'undefined') {
                alert('Debe completar la dirección');
                return
            } 
    
            if (formData.testComplete === 'Sí' && formData.testResult === 'PENDING' || formData.testResult === 'POSITIVE' || formData.testResult === 'NEGATIVE') {
                status = 'DONE:RESULT'
            }
        }

        
        try {
            const data = {
                currentUser: {
                    ...user,
                    uid: auth.currentUser?.uid
                },
                ws: formData.ws,
                incidente_id: modalData.id,
                result: formData.testResult || modalData.data.lab.result_lab,
                status,
                dni_path: '',
                fullname: formData.fullname,
                dni: formData.dni,
                dob: formData.dob,
                address: formData.address,
                cm: formData.cm,
                payment_reference: formData.payment_reference || '',
                location: formData.location || ''
            }

            if(formData.testType === 'PCR LABORATORIO') {
                data.status = formData.testStatus
            }
            await axios.patch(`${analysis}/result/`, data)
            if(modalData.data.lab.result_lab !== formData.testResult){
              const patientData = {  
                    es: [
                      {label: 'Paciente', value: formData.fullname},
                      {label: 'DNI', value: formData.dni},
                      {label: 'Fecha', value: moment(modalData?.dt_cierre?.toDate ? modalData.dt_cierre.toDate() : new Date()).format("DD/MM/YYYY")},
                    ],
                    en: [
                      {label: 'Patient', value: formData.fullname},
                      {label: 'DNI', value: formData.dni},
                      {label: 'Date', value: moment(modalData?.dt_cierre?.toDate ? modalData.dt_cierre.toDate() : new Date()).format("YYYY-MM-DD")},
                    ]
              }
              const service = formData.testType
              const dataToPDF = {
                ws: formData.ws,
                service: 'analysis',
                product: service,
                requestId: modalData.id,
                recipients: [modalData.data.patient?.user_email || ''],
                data: {
                  dni: modalData.data.patient?.dni,
                  logos: ['UMA', 'EMERGENCIAS'],
                  patientData, 
                  lang: ['es'], 
                  result: {es: resultsHelper?.[service]?.[formData.testResult], en: resultsHelper?.[service]?.[formData.testResult]}, 
                  studieType: service,
                  validator: validatorHelper[service],
                  method: {es: methodHelper.es[service], en: methodHelper.en[service]}
                }
              }
              if(service === 'PCR Express') dataToPDF.data.lang.push('en')
              if (modalData.data.patient?.os_email) dataToPDF.recipients.push(modalData.data.patient?.os_email)
              await axios.post(pdf, dataToPDF)
            }
            dispatch({ type: 'RESET_MODAL' })
        } catch (e) {
            alert('ocurrió un error al actualizar los datos')
            console.error(e)
        }
    }

    return (
        <form className="hisopados_modalContent" onSubmit={handleUpdateData}>
            <div>
                <label htmlFor="fullname">Nombre completo</label>
                <input 
                    type="text" 
                    name="fullname" 
                    id="fullname" 
                    value={formData.fullname}
                    onChange={handleChangeData}
                />
            </div>
            <div>
                <label htmlFor="dni">DNI</label>
                <input 
                    type="text" 
                    name="dni" 
                    id="dni" 
                    value={formData.dni} 
                    onChange={handleChangeData}
                />
            </div>
            <div>
                <label htmlFor="dob">Fecha de nacimiento</label>
                <input 
                    type="date" 
                    name="dob" 
                    id="dob" 
                    value={moment(formData.dob).format('YYYY-MM-DD')}
                    onChange={handleChangeData}
                />
            </div>
            <div>
                <label htmlFor="ws">Teléfono</label>
                <input 
                    type="text" 
                    name="ws" 
                    id="ws" 
                    value={formData.ws}
                    onChange={handleChangeData}
                />
            </div>
            <div>
                <label htmlFor="address">Dirección</label>
                <input
                    type="text"
                    name="address"
                    id="address"
                    value={formData.address}
                    onChange={handleChangeData}
                />
            </div>

            <div>
                <label htmlFor="location">Localidad</label>
                <input
                    type="text"
                    name="location"
                    id="location"
                    value={formData.location}
                    onChange={handleChangeData}
                />
            </div>
            {['ADMINISTRATIVE'].includes(user?.rol) && (
                <div>
                    <label htmlFor="payment_reference">Código de referencia de pago</label>
                    <select 
                        name='payment_reference'
                        id='payment_reference'     
                        value={formData.payment_reference}
                        onChange={handleChangeData}               
                    >
                        {payment_references.map(({label, value}) => <option value={value} label={label}/>)}
                    </select>
                </div>
            )}

            {
                ['Personal Médico', 'ADMINISTRATIVE', 'Laboratorio'].includes(user?.rol) &&
                <>
                    <div>
                        <label htmlFor="cm">Punto de testeo</label>
                        <select value={formData.cm} name="cm" id="cm" onChange={handleChangeData}>
                            <option value="off">Seleccionar punto</option>
                            <option value='Coghlan'>Coghlan</option>
                            <option value='Gallardo'>Gallardo</option>
                            <option value='Ezeiza'>Ezeiza</option>
                            <option value='Aeroparque'>Aeroparque</option>
                            <option value='San Nicolás'>San Nicolás</option>
                        </select>
                    </div>

                    <div>
                        <label htmlFor="testType">Tipo de test</label>
                        <select value={formData.testType} name="testType" id="testType" disabled>
                            <option value="PCR Express">PCR Express</option>
                            <option value="Antígenos">Antígenos</option>
                            <option value="Vacuna Antigripal">Vacuna antigripal</option>
                            <option value="PCR LABORATORIO">PCR LABORATORIO</option>
                        </select>
                    </div>

                    {formData.testType !== 'PCR LABORATORIO' &&(
                        <div>
                            <label htmlFor="testComplete">Hisopado hecho</label>
                            <select value={formData.testComplete} name="testComplete" id="testComplete" onChange={handleChangeData}>
                                <option value="off">Seleccionar opción</option>
                                <option value="Sí">Sí</option>
                                <option value="No">No</option>
                            </select>
                        </div>
                    )}
                    <div>
                        <label htmlFor="testStatus">Status</label>
                        <select value={formData.testStatus} name="testStatus" id="testStatus" onChange={handleChangeData}>
                            <option value="off">Seleccionar opción</option>
                            <option value="DONE">Hecho</option>
                            <option value="DONE:LAB">En laboratorio</option>
                            <option value="DONE:RESULT">Terminado con resultado</option>
                        </select>
                    </div>
                    <div>
                        <label htmlFor="testResult">Resultado</label>
                        <select value={formData.testResult} name="testResult" id="testResult" onChange={handleChangeData} disabled={user.corporate === 'DOSUBA'}>
                            <option value="off">Seleccionar resultado</option>
                            <option value="POSITIVE">Detectable</option>
                            <option value="NEGATIVE">No detectable</option>
                        </select>
                    </div>
                </>
            }
            <button>Confirmar</button>
        </form>
    )
}

export default ModalContent