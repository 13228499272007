import React, { useState, useEffect } from 'react'
import { mobility_update_document, pdf } from '../../../config/endpoints'
import { useSelector, useDispatch } from 'react-redux'
import axios from 'axios'
import moment from 'moment'
import { useLocation } from 'react-router'
import db from '../../../config/db'
import SearchBox from '../../GeneralComponents/SearchBox'
import GoogleMapReact from 'google-map-react';
import { currentPositionHandler, mapConfig } from '../../../utils/mapsApiHandler'
import { methodHelper, resultsHelper, validatorHelper } from '../../GeneralComponents/Pdf/pdfHelpers'
import swal from 'sweetalert'

const EditHisopadoDomicilio = () => {
    const auth = db.auth()
    const dispatch = useDispatch()
    const firestore = db.firestore()    
    const { modalData, consultingRooms } = useSelector((state: any) => state.utils)
    const { user } = useSelector((state: any) => state.user)
    const [mapInstance, setMapInstance] = useState(undefined)
    const [mapApi, setMapApi] = useState(undefined)
    const [geocoder, setGeocoder] = useState<any>();
    const [userGeoguessedAddress, setUserGeoguessedAddress] = useState()
    const [formData, setFormData] = useState({
        user: modalData.data.patient.user,
        dni: modalData.data.patient.dni,
        dob: modalData.data.patient.dob,
        sex: modalData.data.patient.sex,
        corporate: modalData.data.patient.corporate,
        address: modalData.data.destination?.user_address,
        location: modalData.data.destination.user_location,
        floor: modalData.data.destination.user_floor,
        dep: modalData.data.destination.user_dep,
        user_lat: 0,
        user_lon: 0,
        user_cp: '',
        user_region: '',
        user_locality: '',
        user_country: '',
        user_obs: modalData.data.destination.user_obs,
        ws: modalData.data.patient.ws,
        uid: modalData.data.patient.uid,
        testType: modalData.data.service,
        status: modalData.data.status,
        promise: modalData.data.promise,
        testComplete: (
            modalData.data.status === 'FREE' ? 'No' : 
            modalData.data.status === 'DONE' || modalData.data.status === 'PENDING:RESULT' || modalData.data.status === 'DONE:RESULT' ? 'Sí' : ''),
        testResult: modalData.data.lab.result_lab,
        nurse_fullname: modalData.data.delivery.fullname_nurse || '',
        nurse_cuit: modalData.data.delivery.cuit_nurse || '',
        nurse_uid: '',
        delivery_cuit: modalData.data.delivery.cuit_delivery || '',
        delivery_uid: ''
    })

    useEffect(() => {
        if (!consultingRooms || !consultingRooms.length) {
            firestore.collection('parametros').doc('userapp').collection('analysis').doc('abbott').get()
                .then((doc: any) => {
                    if (doc.exists) {
                        dispatch({ type: 'SET_CONSULTING_ROOMS', payload: doc.data().consulting_rooms })
                    }
                })
                .catch(e => console.error(e))
        }
    }, [consultingRooms])

    const getNurse_uid = (cuit: any) => {
      let uid_nurse = ''
      firestore.collection('providers').where('cuit', '==', cuit)
        .get().then((doc: any) => {
          if(doc.docs.length === 0) return swal('no existe este enfermero')
          uid_nurse = doc.docs[0].id
          setFormData({...formData, 'nurse_cuit': cuit, 'nurse_uid': uid_nurse})
          });
        return uid_nurse
    }

    const getDelivery_uid = (cuit: any) => {
      let uid_delivery = ''
      firestore.collection('providers').where('cuit', '==', cuit)
        .get().then((doc: any) => {
          if(doc.docs.length === 0) return swal('no existe este chofer')
          uid_delivery = doc.docs[0].id
          setFormData({...formData, 'delivery_cuit': cuit, 'delivery_uid': uid_delivery})
          });
        return uid_delivery
    }

    const handleChangeData = (e: any) => {
      if(e.target.name === 'nurse_cuit' && e.target.value.length >= 11) {
        getNurse_uid(e.target.value)
      } 
      if(e.target.name === 'delivery_cuit' && e.target.value.length >= 11) {
        getDelivery_uid(e.target.value)
      } 
        setFormData({...formData, [e.target.name]: e.target.value})
    }



    const handleUpdateData = async (e: any) => {
        e.preventDefault()

        if(formData.status === 'ASSIGN:DELIVERY') {
          const cuit_delivery = formData.delivery_cuit
          const data = (firestore.collection('events/requests/delivery').where('status', '==', 'ASSIGN:DELIVERY').where('delivery.cuit_delivery', '==', cuit_delivery).get())

          let current_services = []
          ;(await data).forEach((doc: any) => {
            current_services.push(doc.data())
          })

          if(current_services.length >= 1) {
            return swal('El chofer tiene un servicio en curso. Debe esperar a que llegue al domicilio para poder asignarle un servicio nuevo.')
          }
        }

        // if(formData.nurse_cuit.length < 11 && (formData.status === 'ASSIGN:DELIVERY' || formData.status === 'ASSIGN:ARRIVED' || formData.status === 'DONE' || formData.status === 'DONE:LAB' || formData.status === 'DONE:RESULT')) {
        //   return swal('faltan números en el cuit del enfermero')
        // }

        // if(formData.delivery_cuit.length < 11 && (formData.status === 'ASSIGN:DELIVERY' || formData.status === 'ASSIGN:ARRIVED' || formData.status === 'DONE' || formData.status === 'DONE:LAB' || formData.status === 'DONE:RESULT')) {
        //   return swal('faltan números en el cuit del chofer')
        // }

        if(formData.status === 'ASSIGN:DELIVERY' || formData.status === 'ASSIGN:ARRIVED') {
          try {
            if(!formData.nurse_cuit || !formData.delivery_cuit) {
              return alert('Debe ingresar el cuit del enfermero y del chofer.')
            }
            const query = firestore
                .collection('events/requests/delivery')
			          .where('status', 'in', ['ASSIGN:DELIVERY', 'ASSIGN:ARRIVED'])
			          .where('delivery.cuit_nurse', '==', formData.nurse_cuit)
		        const documents = (await query.get()).docs;
            const arrOfDocs = documents.map(doc => ({...doc.data(), incidente_id: doc.id}))
            if(arrOfDocs.length >= 2) {
              return alert(`Este enfermero (cuit: ${formData.nurse_cuit}) tiene 2 servicios en curso. Por el momento no puede asignarle otro.`)
            }
          } catch (err) {
            console.log(err)
          }
        }

        let status = 'PREASSIGN'

        if (formData.testComplete === 'Sí' && formData.testType !== 'VACUNA ANTIGRIPAL' && formData.testType !== 'PCR LABORATORIO' && formData.testType !== 'PCR EXPRESS' && !formData.testResult || formData.testResult === 'off') {
            alert('Debe seleccionar el resultado del test.');
            return
        }         

        if (formData.testComplete === 'No'  && formData.testResult && formData.testResult !== 'off' && formData.testType !== 'PCR LABORATORIO' && formData.testType === 'PCR EXPRESS') {
            alert('No puede guardar un resultado si el hisopado no fue realizado. Por favor verifique las opciones e intente de nuevo');
            return
        } 

        // if (formData.testComplete === 'Sí' && formData.testResult && formData.testResult !== 'off' && !formData.address || formData.address === '' || formData.address === 'undefined') {
        //     alert('Debe completar la dirección');
        //     return
        // } 

        if (formData.testComplete === 'Sí' && formData.testResult === 'POSITIVE' || formData.testResult === 'NEGATIVE') {
            status = 'DONE:RESULT'
        }

        if (formData.testType === 'PCR LABORATORIO' && formData.status !== 'DONE:RESULT' && formData.testResult && formData.testResult !== 'off') {
            alert('No puede guardar un resultado si el hisopado no fue realizado. Por favor verifique las opciones e intente de nuevo');
            return
        }

        if (formData.testType === 'PCR LABORATORIO' && formData.testResult === 'POSITIVE' || formData.testResult === 'NEGATIVE' || formData.testResult === 'NOT_PROCESSED') {
            status = 'DONE:RESULT'
        }

        if(['DONE', 'DONE:LAB', 'DONE:RESULT'].includes(formData.status) && formData.testType === 'PCR') {
          alert('Debe especificar el tipo de PCR que se ha realizado.')
          return;
        }

        // if(['ABSENT', 'CANCEL:REJECTED', 'CANCEL:ADDRESS', 'DONE:RESULT', 'DONE', 'DONE:LAB', 'ASSIGN:DELIVERY', 'ASSIGN:ARRIVED' ].includes(formData.status)) {
        //   if(!formData.nurse_cuit || !formData.delivery_cuit) {
        //     return alert('Debe ingresar el cuit del enfermero y del chofer.')
        //   }
        // }

        try {          
            const newValues = {
                lab: {
                    ...modalData.data.lab,
                    result_lab: formData?.testResult || '',
                },
                patient: {
                    ...modalData.data.patient,
                    dni: formData?.dni || '',
                    dob: formData?.dob || '',
                    sex: formData?.sex || '',
                    user: formData?.user || '',
                    ws: formData?.ws || '',
                    uid: formData?.uid || '',
                    corporate: formData?.corporate || ''
                },
                destination: {
                    ...modalData.data.destination,
                    user_address: formData?.address || modalData?.data?.destination?.user_address || '',
                    user_obs: formData?.user_obs || '',
                    user_floor: formData?.floor || modalData.data.destination.user_floor || '',
                    user_dep: formData?.dep || modalData.data.destination.user_dep || '',
                    user_lat: formData.user_lat || modalData.data.destination.user_lat || '',
                    user_lon: formData.user_lon || modalData.data.destination.user_lon || '',
                    user_cp: formData.user_cp || modalData.data.destination.user_cp || '',
                    user_region: formData.user_region || modalData.data.destination.user_region || '',
                    user_locality: formData.user_locality || modalData.data.destination.user_locality || '',
                    user_country: formData.user_country || modalData.data.destination.user_country || '',
                },
                delivery:{
                    cuit_delivery: formData.delivery_cuit,
                    uid_delivery: formData.delivery_uid,
                    cuit_nurse: formData.nurse_cuit,
                    uid_nurse: formData.nurse_uid,
                    fullname_nurse: formData.nurse_fullname,
                    
                },
                promise: formData.promise,
                service: formData.testType,
                assigned_status: formData.status,
            } as any

            if(modalData.data.lab.result_lab !== formData.testResult){
              newValues.dt_cierre = Date.now()
            }
            const dataToPost = {
                newValues: newValues,
                doc_id: modalData.id,
                portalUser: {
                  ...user,
                  uid: auth.currentUser?.uid
                }
            }
            await axios.post(mobility_update_document, dataToPost)
            if(modalData.data.lab.result_lab !== formData.testResult){
              const patientData = {  
                    es: [
                      {label: 'Paciente', value: formData.user},
                      {label: 'DNI', value: formData.dni},
                      {label: 'Fecha', value: moment(modalData?.dt_cierre?.toDate ? modalData.dt_cierre.toDate() : new Date()).format("DD/MM/YYYY")},
                    ],
                    en: [
                      {label: 'Patient', value: formData.user},
                      {label: 'DNI', value: formData.dni},
                      {label: 'Date', value: moment(modalData?.dt_cierre?.toDate ? modalData.dt_cierre.toDate() : new Date()).format("YYYY-MM-DD")},
                    ]
              }
              const service = formData.testType
              const dataToPDF = {
                ws: formData.ws,
                product: service,
                requestId: modalData.id,
                recipients: [modalData.data.patient?.user_email || ''],
                data: {
                  dni: modalData.data.patient?.dni,
                  logos: ['UMA', 'EMERGENCIAS'],
                  patientData, 
                  lang: ['es'], 
                  result: {es: resultsHelper?.[service]?.[formData.testResult], en: resultsHelper?.[service]?.[formData.testResult]}, 
                  studieType: service,
                  validator: validatorHelper[service],
                  method: {es: methodHelper.es[service], en: methodHelper.en[service]}
                }
              }
              if(service === 'PCR LABORATORIO') dataToPDF.data.logos.push('UNSAM')
              if(service === 'PCR LABORATORIO' || service === 'PCR EXPRESS') dataToPDF.data.lang.push('en')
              if (modalData.data.patient?.os_email) dataToPDF.recipients.push(modalData.data.patient?.os_email)
              if(formData?.testResult !== 'NOT_PROCESSED') {
                await axios.post(pdf, dataToPDF)
              }
            }
            dispatch({ type: 'RESET_MODAL' })
        } catch (e) {
            alert('Ocurrio un error al actualizar los datos, por favor intente nuevamente')
            console.error(e)
        }
    }

    const handleChangePlace = async (place: any) => {
        let address_details = await getAddressObject(place.address_components)
        const pos = {
            lat: place?.lat || place?.geometry?.location?.lat() || "",
            lng: place?.lng || place?.geometry?.location?.lng() || "",
            address: place?.formatted_address,
            ...address_details
        };
        dispatch({ type: 'SET_ADDRESS_LAT_LONG', payload: { lat: pos.lat, lng: pos.lng } })
        dispatch({ type: 'SET_DELIVERY_PATIENT_ADDRESS', payload: place.formatted_address })
        return handleForm(pos);
    };

    async function getAddressObject(address_components: any) {
        let ShouldBeComponent: any = {
            number: ["street_number"],
            cp: ["postal_code"],
            street: ["street_address", "route"],
            region: [
                "administrative_area_level_1",
            ],
            locality: [
                "locality",
                "sublocality",
                "sublocality_level_1",
                "administrative_area_level_2",
                "sublocality_level_2",
                "sublocality_level_3",
                "sublocality_level_4",
                "administrative_area_level_3",
                "administrative_area_level_4",
                "administrative_area_level_5"
            ],
            country: ["country"]
        };

        let address: any = {
            number: "",
            cp: "",
            street: "",
            region: "",
            locality: "",
            country: "",
        };
        address_components.forEach((component: any) => {
            for (let shouldBe in ShouldBeComponent) {
                if (ShouldBeComponent[shouldBe].indexOf(component.types[0]) !== -1) {
                    if (address[shouldBe] === "") {
                        address[shouldBe] = component.short_name;
                    }
                }
            }
        });
        return address;
    }

    const handleForm = (event: any) => {
        return setFormData({ ...formData, address: event.address, user_cp: event.cp, user_region: event.region, user_locality: event.locality, user_country: event.country, user_lat: event.lat, user_lon: event.lng });
    };

    const handleApiLoaded = (map: any, maps: any) => {
        setMapInstance(map);
        setMapApi(maps);
        setGeocoder(new maps.Geocoder());
        return null
    };

    function geocodeLatLng({ lat, lng }: { lat: any, lng: any }) {
        if (!geocoder) return null
        let result;
        const latlng = {
            lat: parseFloat(lat) || -31.374420,
            lng: parseFloat(lng) || -64.132140,
        };
        dispatch({ type: 'SET_ADDRESS_LAT_LONG', payload: latlng })
        geocoder.geocode({ location: latlng }, (results: any, status: any) => {
            if (status === 'OK') {
                if (results[0]) {
                    result = results[0];
                    handleChangePlace(result);
                }
            }
        });
    }
    return (
      <form className="hisopados_modalContent" onSubmit={handleUpdateData}>
        <div>
          <label htmlFor="fullname">Nombre completo</label>
          <input
            type="text"
            name="user"
            id="fullname"
            value={formData.user}
            onChange={handleChangeData}
          />
        </div>

        <div>
          <label htmlFor="dni">DNI</label>
          <input
            type="text"
            name="dni"
            id="dni"
            value={formData.dni}
            onChange={handleChangeData}
          />
        </div>

        <div>
          <label htmlFor="dob">Fecha de nacimiento</label>
          <input
            type="date"
            name="dob"
            id="dob"
            value={moment(formData.dob).format("YYYY-MM-DD")}
            onChange={handleChangeData}
          />
        </div>

        {
          !['Laboratorio'].includes(user?.rol) &&
          <>
            <div>
              <label htmlFor="ws">N° de celular</label>
              <input
                type="text"
                name="ws"
                id="ws"
                value={formData.ws}
                onChange={handleChangeData}
              />
            </div>
            <div>
              <label htmlFor="sex">Sexo</label>
              <select
                name="sex"
                id="sex"
                value={formData.sex}
                onChange={handleChangeData}
              >
                <option value="F">Femenino</option>
                <option value="M">Masculino</option>
              </select>
            </div>
            <div>
              <label htmlFor='corporate'>Obra social</label>
              <input
                type="text"
                name="corporate"
                id="corporate"
                value={formData.corporate}
                onChange={handleChangeData}
              />
            </div>
            <div>
              <label htmlFor="address">Dirección</label>
              <div className="container_map">
                <GoogleMapReact
                  {...mapConfig(
                    { lat: formData.user_lat, lng: formData.user_lon },
                    geocodeLatLng
                  )}
                  onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
                ></GoogleMapReact>
              </div>
              {mapInstance && mapApi && (
                <>
                  <SearchBox
                    map={mapInstance}
                    mapApi={mapApi}
                    handleChangePlace={handleChangePlace}
                    value={formData.address}
                  />
                </>
              )}
              {/* <input
                        type="text"
                        name="address"
                        id="address"
                        value={formData.address}
                        onChange={handleChangeData}
                    /> */}
            </div>
            <div>
              <label htmlFor="floor">Piso</label>
              <input
                type="text"
                name="floor"
                id="floor"
                value={formData.floor}
                onChange={handleChangeData}
              />
            </div>
            <div>
              <label htmlFor="dep">Departamento</label>
              <input
                type="text"
                name="dep"
                id="dep"
                value={formData.dep}
                onChange={handleChangeData}
              />
            </div>
          </>
        }
        {[
          "ADMINISTRATIVE",
          "Operaciones Analista",
          "Operaciones Supervisor",
          "Laboratorio",
          "Personal Médico",
          "Jefe Operaciones",
        ].includes(user?.rol) && (
          <div>
            <label htmlFor="status">Estado</label>
            <select
              value={formData.status}
              onChange={handleChangeData}
              name="status"
              id="status"
            >
              {["HISOPADO", "PCR", "PCR EXPRESS", "PCR LABORATORIO"].includes(formData.testType) && (
                <option value="PREASSIGN:PENDING">PENDIENTE DE APROBACIÓN POR OS</option>
              )}
              {["HISOPADO", "PCR", "PCR EXPRESS", "PCR LABORATORIO"].includes(formData.testType) && (
                <option value="PREASSIGN:NOTFOUND">NO ENCONTRADO EN EL PADRÓN</option>
              )}
              {["HISOPADO", "PCR", "PCR EXPRESS", "PCR LABORATORIO"].includes(formData.testType) && (
                <option value="PREASSIGN:OS">ESPERANDO APROBACIÓN DE OS (YA SOLICITADA)</option>
              )}
              {["HISOPADO", "PCR", "PCR EXPRESS", "PCR LABORATORIO"].includes(formData.testType) && (
                <option value="DONE:REJECTED">RECHAZADO POR OBRA SOCIAL</option>
              )}
              <option value="PREASSIGN">PRE-ASIGNADO</option>
              <option value="ASSIGN:DELIVERY">EN CAMINO</option>
              <option value="ASSIGN:ARRIVED">EN DESTINO</option>
              {["VACUNA ANTIGRIPAL", "PCR", "PCR EXPRESS", "PCR LABORATORIO"].includes(formData.testType)  && (
                <option value="DONE">TERMINADO</option>
              )}
              {["PCR", "PCR EXPRESS", "PCR LABORATORIO"].includes(formData.testType) && (
                <option value="DONE:LAB">EN LABORATORIO</option>
              )}
              {["PCR", "PCR EXPRESS", "PCR LABORATORIO", "HISOPADO"].includes(formData.testType) && (
                <option value="DONE:RESULT">TERMINADO (CON RESULTADO)</option>
              )}
              <option value="ABSENT">AUSENTE</option>
              <option value="CANCEL">CANCELADO</option>
              <option value="CANCEL:REJECTED">SERVICIO RECHAZADO POR EL PACIENTE</option>
              <option value="CANCEL:ADDRESS">DOMICILIO INEXISTENTE</option>
              <option value="DONE:OUT_OF_RANGE">FUERA DE ZONA DE COBERTURA</option>
            </select>
          </div>
        )}
        {(!["DONE", "PENDING:RESULT", "DONE:RESULT"].includes(formData.promise) && !['Laboratorio'].includes(user?.rol)) && (
          <div>
            <label htmlFor="promise">Fecha de la promesa</label>
            <input
              type="date"
              name="promise"
              id="promise"
              value={formData.promise}
              onChange={handleChangeData}
            />
          </div>
        )}

        {(
          formData.status &&
          (formData.status !== "CANCEL" || formData.status !== "PREASSIGN") && 
          !['Laboratorio'].includes(user?.rol)) && (
            <>
              <div>
                <label htmlFor="nurse_fullname">Nombre del enfermero</label>
                <input
                  type="text"
                  name="nurse_fullname"
                  id="nurse_fullname"
                  value={formData.nurse_fullname}
                  onChange={handleChangeData}
                />
              </div>
              <div>
                <label htmlFor="nurse_cuit">Cuit del enfermero</label>
                <input
                  type="text"
                  name="nurse_cuit"
                  id="nurse_cuit"
                  value={formData.nurse_cuit}
                  onChange={handleChangeData}
                />
              </div>
              <div>
                <label htmlFor="delivery_cuit">Cuit del chofer</label>
                <input
                  type="text"
                  name="delivery_cuit"
                  id="delivery_cuit"
                  value={formData.delivery_cuit}
                  onChange={handleChangeData}
                />
              </div>
            </>
          )}

        <div>
          <label htmlFor="testType">Servicio</label>
          <select
            disabled={!["ADMINISTRATIVE", "Jefe Operaciones"].includes(user?.rol)}
            value={formData.testType}
            name="testType"
            id="testType"
            onChange={(e) => {
              handleChangeData(e)
            }}
          >
            <option value="off">Seleccionar servicio</option>
            <option value="HISOPADO">Hisopado a domicilio</option>
            <option value="VACUNA ANTIGRIPAL">Vacuna Antigripal</option>
            <option value="PCR">PCR</option>
            <option value="PCR LABORATORIO">PCR Laboratorio</option>
            <option value="PCR EXPRESS">PCR Express</option>
          </select>
        </div>
        
        {["Personal Médico", "Laboratorio", "ADMINISTRATIVE", "Operaciones Analista", "Operaciones Supervisor", "Call Center Supervisor", "Jefe Operaciones"].includes(user?.rol) &&
          <div>
            <label htmlFor="user_obs">Observaciones</label>
            <input
              type="text"
              name="user_obs"
              id="user_obs"
              value={formData.user_obs}
              onChange={handleChangeData}
            />
          </div>
        }

        {["Personal Médico", "ADMINISTRATIVE", "Laboratorio"].includes(user?.rol) &&
        formData.testType !== 'VACUNA ANTIGRIPAL' &&
          (
            <div>
              <label htmlFor="testResult">Resultado</label>
              <select
                value={formData.testResult}
                name="testResult"
                id="testResult"
                onChange={(e) => {
                  let status = ''
                  if(['POSITIVE', 'NEGATIVE'].includes(e.target.value)){
                    status = 'DONE:RESULT'
                  } else if (e.target.value === 'NOT_PROCESSED'){
                    status = 'DONE:RESULT'
                  }
                  setFormData({
                    ...formData,
                    status,
                    testResult: e.target.value,
                  })
                }}
                disabled={user.corporate === 'DOSUBA'}
              >
                <option value="off">Seleccionar resultado</option>
                <option value="POSITIVE">Detectable</option>
                <option value="NEGATIVE">No detectable</option>
                {
                  formData.testType === 'PCR LABORATORIO' &&
                  <option value="NOT_PROCESSED">No se pudo procesar la muestra</option>
                }
              </select>
            </div>
          )}

        <button>Confirmar</button>
      </form>
    );
}
export default EditHisopadoDomicilio