import React, {useState} from 'react';
import '../../styles/cargaCSV/CsvModal.scss';
import UploadingCSV from '../../assets/cargaCSV/uploading_csv.png';
import FinishUploadingCSV from '../../assets/cargaCSV/finalizar_carga_csv.png';
import axios from 'axios';
import { validate_csv, upload_csv} from '../../config/endpoints';
import {useHistory, useLocation} from 'react-router-dom';
import Header from '../Header';
import { useSelector } from 'react-redux';
import { UnAuthorized } from '../404-unauth/unauth';
import Select from 'react-select'
import { validateHelper } from './validateHelper';
import db from '../../config/db';
import { payment_references } from '../../constants/CECO';
import { putFileFB } from '../../utils/postBlobFirebase'
import moment from 'moment'

function useQueryParams() {
    return new URLSearchParams(useLocation().search);
}

const CsvModal = () => {
    const rolWithAccess = ['ADMINISTRATIVE', 'Comercial', 'Medicina Laboral', 'Operaciones Supervisor', 'Call Center Supervisor', 'Jefe Operaciones']
    const saveOnlySuccessful = true
    const history = useHistory();
    const schema = useQueryParams().get("schema") as any
    const redirect = useQueryParams().get("redirect") as any
    const [isBtnDisabled, setIsBtnDisabled] = useState(true);
    const [file, setFile] = useState<any>(null);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<any>([])
    const [status, setStatus] = useState<any>({})
    const [code, setCode] = useState('');
    const {currentUser, user} = useSelector((state:any) => state.user)
    const [activeStep, setActiveStep] = useState(['ADMINISTRATIVE', 'Comercial', 'Medicina Laboral', 'Operaciones Supervisor', 'Jefe Operaciones'].includes(user.rol) ? 0 : 1);

    const handleChangeFile = (e:any) => {
        setFile(e.target.files[0]);
        setIsBtnDisabled(false);
    }

    const handleValidateCSV = () => {
        setLoading(true);
        setIsBtnDisabled(true);
        const formData = new FormData();
        formData.append("csv", file);
        const {allFields, requiredFields, validations} = validateHelper[schema]
        let url = `${validate_csv}?allFields=${allFields}&requiredFields=${requiredFields}&validations=${validations}`              
        const headers = {headers: {'Content-Type': 'multipart/form-data'}}
        axios.post(url,formData, headers)
        .then(async (response) => {
            if(response.data.status.success > 0 && response.data.status.err === 0) {
                const link = await putFileFB(file, `delivery/csv/${currentUser.uid}/${moment().format('YYYYMMDDHHmmss')}`);
                setIsBtnDisabled(false);
            }
            if(response.data.status.err > 0) {
                setIsBtnDisabled(true)
            }
            setData(response.data.batch);
            setStatus(response.data.status);
            setLoading(false);
            setActiveStep(activeStep + 1);
        })
        .catch(error =>{
            setLoading(false);
            if(error?.response?.data?.message){
                alert(error?.response?.data?.message);
            } else {
                alert('Ocurrió un error al cargar el archivo')
            }

        });
    }    
    
    const handleConfirmCSV = async () => {
        setIsBtnDisabled(true)
        setLoading(true)
        try {            
            let supplementFields = {payment_reference: code} as any
            if(schema === 'delivery' && !data[0].data?.promise || (data[0].data?.promise && !data.every((el:any) => el.promise))){
                const params = (await db.firestore().doc('parametros/userapp/delivery/hisopados').get()).data() as any
                supplementFields = {...supplementFields, promise_param: params.delay}
            }
            let queryData = {
                csvUploadedBy: currentUser.uid,
                batch: data,
                saveOnlySuccessful,
                supplementFields,
                schema,
            }
            
            if(saveOnlySuccessful && status.err > 1){
                if(window.confirm('Algunos registros tienen errores, estos registros no van a ser cargados, ¿desea continuar?')){
                    axios.post(upload_csv, queryData)
                    .then(async (response) => {
                        setLoading(false)
                        if(response.data.length > 0) {
                            const last = (await db.firestore().doc(response.data[response.data.length - 1]).get()).data()
                            if(last) {
                                if(typeof last?.patient !== 'string') {
                                    alert('Registros cargados con éxito')
                                    history.push(redirect)
                                } else {
                                    alert(`Hubo un error al cargar los registros. Por favor repórtelo con el código: ERROR:${response.data[0]}${response.data[response.data.length - 1]} e intente de nuevo más tarde`)
                                    history.push(redirect)
                                }
                            } else {
                                alert('Hubo un error al cargar los registros, intente más tarde')
                                history.push(redirect)
                            }
                        }
                    })
                    .catch(error => {
                        alert('Hubo un error al cargar los servicios, intente nuevamente')
                        setIsBtnDisabled(false)
                    });
                }
            } else {
                axios.post(upload_csv, queryData)
                    .then(async (response) => {
                        setLoading(false)
                        if(response.data.length > 0) {
                            const last = (await db.firestore().doc(response.data[response.data.length - 1]).get()).data()
                            if(last) {
                                if(typeof last?.patient !== 'string') {
                                    alert('Registros cargados con éxito')
                                    history.push(redirect)
                                } else {
                                    alert(`Hubo un error al cargar los registros. Por favor repórtelo con el siguiente código: ERROR:${response.data[0]}${response.data[response.data.length - 1]} e intente de nuevo más tarde`)
                                    history.push(redirect)
                                }
                            } else {
                                alert('Hubo un error al cargar los registros, intente más tarde')
                                history.push(redirect)
                            }
                        }
                    })
                    .catch(error => {
                        alert('Hubo un error al cargar los servicios, intente nuevamente')
                        setIsBtnDisabled(false)
                    });
            }
        } catch (err) {
            console.log(err);
            alert('ocurrió un error al subir los registros')
        }
    }    

    const handleInputChange = ({value}:any) =>{
        setCode(value)
        setIsBtnDisabled(false)
    }
    
    if(user && !rolWithAccess.includes(user.rol)){
        return <UnAuthorized />
    }
    
    return (
        <>
            <Header/>
            <div className="asignaciones_modalBody">
                <ol>
                    {rolWithAccess.includes(user.rol) && (
                        <li className={`${activeStep === 0 || activeStep === 1 || activeStep === 2 || activeStep === 3 ? 'active' : null}`}>Código</li>
                    )}
                    <li className={`${activeStep === 1 || activeStep === 2 || activeStep === 3 ? 'active' : null}`}>Archivo</li>
                    <li className={`${activeStep === 2 || activeStep === 3 ? 'active' : null}`}>Vista previa</li>
                    <li className={`${activeStep === 3 ? 'active' : null}`}>Carga</li>
                </ol>

                {
                    activeStep === 0 &&                     
                    <div className="codeSection">
                        <h2>
                            <br /> 
                                <span>Para comenzar el proceso es necesario seleccionar el codigo de referencia</span>
                            <br/>
                        </h2>
                        <div className="selector">
                            <Select
                                onChange={handleInputChange}
                                options={payment_references}
                            />
                        </div>
                    </div>
                    
                }

                {
                    activeStep === 1 && 
                    <>
                        <img src={UploadingCSV} width="300" className="uploading_csv" alt="Uploading CSV" />

                        {
                            !file ?
                            <>
                                <label htmlFor="file">
                                    <p>Arraste y suelte archivos aquí</p>
                                    <p>o</p>
                                    <span>Seleccione un archivo</span>
                                    <input type="file" name="file" id="file" accept=".csv" onChange={handleChangeFile} />
                                </label>

                                <p>El archivo debe ser en formato CSV</p>
                            </> :
                            <div className="selected_file">
                                <p>{file.name}</p>
                                <button onClick={() => {
                                    setFile(null);
                                    setIsBtnDisabled(true);
                                }}>x</button>

                                {
                                    loading && <p>Cargando...</p>
                                }
                            </div>
                        }
                    </>
                }

                {
                    activeStep === 2 &&
                    <div className="contacts_preview">
                        <div>
                            <h2>
                                {
                                    status.success > 0 &&
                                    <>
                                        <span className="correct">{status.success}</span>{' '}
                                        {status.success > 1 ? 'filas' : 'fila'} añadidas correctamente 
                                    </>
                                }
                                {
                                    status.err > 0 &&
                                    <>
                                        <br /> 
                                        <span className="error">{status.err}</span>{' '}
                                        {status.err > 1 ? 'filas' : 'fila'} con error
                                        <br />
                                        <span>No es posible seguir con el proceso, corrija las filas indicadas y vuelva a subir el archivo.</span>
                                        <br/>
                                    </>
                                }
                            </h2>
                        </div>

                        {
                            data.length > 0 &&
                            <div className="contacts_list">
                                <div className="row">
                                    <div className="col">Nombre completo</div>
                                    <div className="col">DNI</div>
                                    <div className="col">Fecha de nacimiento</div>
                                    <div className="col">Sexo</div>
                                    <div className="col">Obra social</div>
                                    <div className="col">Teléfono</div>
                                    <div className="col">Email</div>
                                    <div className="col">Email (OS)</div>
                                    <div className="col">Dirección</div>
                                    <div className="col">Localidad</div>
                                    <div className="col">Servicio</div>
                                    <div className="col">Cliente</div>
                                    <div className="col">Status</div>
                                </div>
                                {
                                    data.length > 0 &&
                                    data.map((contact:any, index:number) => {
                                        return (
                                            <div key={index} className={`row ${!contact.success ? 'error' : null}`}>
                                                <div className="col">{contact.data.fullname}</div>
                                                <div className="col">{contact.data.dni}</div>
                                                <div className="col">{contact.data.dob}</div>
                                                <div className="col">{contact.data.sex}</div>
                                                <div className="col">{contact.data.corporate}</div>
                                                <div className="col">{contact.data.ws}</div>
                                                <div className="col">{contact.data.user_email}</div>
                                                <div className="col">{contact.data.os_email}</div>
                                                <div className="col">{contact.data.address || contact.data.service_address}</div>
                                                <div className="col">{contact.data.location || contact.data.service_location}</div>
                                                <div className="col">{contact.data.service}</div>
                                                <div className="col">{contact.data.client}</div>
                                                <div className="col">{contact.message}</div>
                                            </div>
                                    )})
                                }
                            </div>
                        }
                    </div>
                }

                {
                    activeStep === 3 && 
                    <div className="finish_upload">
                        <img src={FinishUploadingCSV} width="300" alt=""/>
                        <h2>¡Ya casi terminamos!</h2>
                        <p>Al presionar "Continuar" se cargarán <span className="total">{saveOnlySuccessful ? status.success : (status.success + status.err)}</span> {(status.success + status.err) > 1 ? 'contactos' : 'contacto'}.</p>
                        <p>¿Desea continuar?</p>
                        {
                            loading && <p>Cargando...</p>
                        }
                    </div>
                }
                <div className="buttonsContainer">
                        <button disabled={activeStep === 0} onClick={() => {
                            setActiveStep(activeStep - 1)
                            if(activeStep === 2){
                                setStatus({})
                                setData([])
                            }
                        }}>
                            {'Anterior'}
                        </button>
                        <button disabled={isBtnDisabled || status.err > 0} onClick={() => {
                            if(activeStep === 0){
                                setActiveStep(activeStep + 1);
                            }
                            else if(activeStep === 1) {
                                handleValidateCSV();
                            } else if(activeStep === 2) {
                                setActiveStep(activeStep + 1);
                            } else if (activeStep === 3) {
                                handleConfirmCSV();
                            }
                        }}>{activeStep !== 3 ? 'Siguiente' : 'Continuar'}</button>
                </div>
            </div>
        </>
    )
}

export default CsvModal;