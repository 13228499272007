import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import db from '../../config/db';
import '../../styles/Login.scss';

interface User {
    email: string;
    password: string;
}

const Login: React.FC = (): React.ReactElement => {
    const auth = db.auth();
    const firestore = db.firestore();
    const history = useHistory();
    const dispatch = useDispatch()
    const [formData, setFormData] = useState<User>({
        email: '',
        password: '',
    })

    const handleChangeFormData = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormData({...formData, [e.target.name]: e.target.value});
    }

    const handleSubmitLogin = (e: React.ChangeEvent<HTMLFormElement>) => {
        e.preventDefault();
        
        try {
            auth.signInWithEmailAndPassword(formData.email, formData.password)
                .then(async (user: any) => {
                    try {
                        let userData: any;
                        await firestore.collection('corporate_users').doc(user.user.uid).get()
                            .then(doc => {
                                if(doc.exists) {
                                    dispatch({ type: 'SET_USER', payload: doc.data() })
                                    userData = doc.data()
                                }
                            })
                            .catch(e => console.error(e))
                        
                        await firestore.collection('corporate_users').doc(user.user.uid).collection('features').doc('hisopados').get()
                            .then(doc => {
                                if(doc.exists) {
                                    dispatch({ type: 'SET_USER_PERMISSIONS', payload: doc.data() })
                                }
                            })
                            .catch(e => console.error(e))

                        if(userData?.rol === 'Laboratorio' || userData?.rol === 'Corporate') {
                            history.push('/domicilio')
                        } else {
                            history.push('/presenciales')
                        }
                    } catch (e) {
                        console.error(e)
                    }
                })
                .catch(e => console.error(e));
        } catch (e) {
            console.error(e);
        }
    }

    return (
        <section className="login" onSubmit={handleSubmitLogin}>
            <form>
                <input type="text" name="email" placeholder="E-mail" onChange={handleChangeFormData} />
                <input type="password" name="password" placeholder="Password" onChange={handleChangeFormData} />
                <button type="submit">Ingresar</button>
            </form>
        </section>
    )
}

export default Login;