import React, { useState, useEffect, useRef, useMemo } from 'react'
import './Table.scss'
import { FaDownload, FaRegFileAlt, FaAddressCard, FaPencilAlt, FaTimesCircle, FaCheck } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import Pagination from '../Pagination'
import ReactToPrint from 'react-to-print';
import Constancy from '../Pdf/constancia'
import { Link, useLocation } from 'react-router-dom'
import ExportToExcel from '../exportToExcel/ExcelExport'
import ModalContent from '../../Hisopados/presencial/ModalContent'
import EditHisopadoDomicilio from '../../Hisopados/domicilio/EditHisopado'
import axios from 'axios'
import { analysis, mobility_update_document, pdf } from '../../../config/endpoints'
import { CompleteFilter, DateFilter, NurseFilter, ServiceFilter, StatusFilter, ResultFilter, OriginFilter } from './filters'
import { SET_PATIENTS_FILTERED } from '../../../store/types/patientsTypes';
import { methodHelper, resultsHelper, validatorHelper } from '../../GeneralComponents/Pdf/pdfHelpers'
import swal from 'sweetalert'
interface Patient {
    id: string;
    data: {
        csvUploadedBy: string;
        delivery: {
            fullname_nurse: string;
            cuit_nurse: string;
        },
        destination: {
            address: string;
            confirmed: string;
            number: string;
            user_address:string;
            user_obs: string;
        },
        dt_cierre: any;
        dt_assign: any;
        lab: {
            lab_date_result: any;
            result_lab: string;
            observaciones_lab: string;
        },
        origin: string;
        patient: {
            address: string;
            cm: string;
            dni: string;
            dob: string;
            location: string;
            user: string;
            fullname: string;
            testComplete: string;
            testResult: string;
            testType: string;
            uid: string;
            ws: string;
            dni_path: string;
            user_email: string;
            os_email: string;
            os: string;
            corporate: string;
        },
        payment: {
            status: string;
            reference: string;
        },
        payment_data:{
            reference: string;
        }
        promise: string;
        service: string;
        status: string;
    }
}

const Table:React.FC = () => {
    const dispatch = useDispatch()
    const location = useLocation()
    const { patients, searchedItem, patientsFiltered } = useSelector((state: any) => state.patients)
    const { user, currentUser } = useSelector((state: any) => state.user)
    const [currentPage, setCurrentPage] = useState<number>(1)
    const [currentPatients, setCurrentPatients] = useState<any>([])
    const [filterTypes, setFilterTypes] = useState<any>([])
    const usersPerPage = 20
    const [dropDownExcel, setDropDrow] = useState(false);
    const paginate = (pageNumber: number): any => setCurrentPage(pageNumber);
    const isDomicilio = location.pathname.includes('domicilio')
    const [filteredAndMerged, setFilteredAndMerged] = useState<any>([]);
    const indexOfLastUser = currentPage * usersPerPage;
    const indexOfFirstUser = indexOfLastUser - usersPerPage;
    
    useEffect(() => {
        dispatch({ type: 'SET_SEARCHED_ITEM', payload: '' })
    }, [])

    useEffect(() => {
        if (searchedItem) {
            const filteredPatientsFromSearchInput = filterPatientsFromSearchInput(searchedItem, patients)
            dispatch({type: SET_PATIENTS_FILTERED, payload: { patientFiltered: filteredPatientsFromSearchInput, key: 'bySearch' }})
        } else if (filterTypes.length === 0){
            dispatch({type: SET_PATIENTS_FILTERED, payload: { patientFiltered: patients, key: 'noFilters' }})
        }
    }, [patients, searchedItem, filterTypes])

    const filterPatientsFromSearchInput = (text: string, patients: any[]) => {
        return patients.filter(el => {
            return el?.data?.patient?.fullname?.toLowerCase()?.includes(text) || el?.data?.patient?.user?.toLowerCase()?.includes(text) || el?.data?.patient?.dni?.includes(text) || el?.data?.payment?.status === text || el?.data?.patient?.cm === text || el?.id?.toLowerCase()?.includes(text)
        })
    }

    useEffect(() => {
        setCurrentPage(1)
    }, [patientsFiltered])

    useEffect(() => {
        if (searchedItem) {
          const filteredPatientsFromSearchInput = filterPatientsFromSearchInput(searchedItem, patients)
          setFilteredAndMerged(filteredPatientsFromSearchInput)
        } else if(filterTypes.length === 0) {
          setFilteredAndMerged(patients)
        } else {
          let arrOfPatients: any = []
          //mergeo todos los resultados
          for(let i = 0; i < filterTypes.length; i++) {
            if(patientsFiltered[filterTypes[i]]){
              arrOfPatients = [...arrOfPatients, ...patientsFiltered[filterTypes[i]]]
            }
          }          
          //acomodo por id para saber la cantidad de matchs
          const temp = {} as any
          arrOfPatients.forEach((row:any) => {
            if(temp[row.id]){
              temp[row.id] = [...temp[row.id], row]
            } else {
              temp[row.id] = [row]
            }
          })
          //si el resultado matchea la misma cantidad de veces que los filtros que hay, entra en results
          const results = []
          for (const id in temp) {
            const el = temp[id]
            if(el.length >= filterTypes.length){
              results.push(el[0])
            }
          }
          setFilteredAndMerged(results)
        }
    }, [patientsFiltered, currentPage, filterTypes, searchedItem])

    useEffect(() => {
      setCurrentPatients(filteredAndMerged.slice(indexOfFirstUser, indexOfLastUser))
    }, [filteredAndMerged])

    const handlePatientProfile = (patient: Patient) => {
        dispatch({ type: 'SET_MODAL', payload: true })
        dispatch({ type: 'SET_MODAL_DATA', payload: patient })
        dispatch({ type: 'SET_MODAL_COMPONENT', payload: isDomicilio ? <EditHisopadoDomicilio/> : <ModalContent/> })
    }

    const ConstancyTrigger = ({rowData}:any) => {
        const ref = useRef() as any
        return (
            <>
              {
                rowData.data.service !== 'PCR' &&
                <>
                  <ReactToPrint 
                      trigger={() => <FaRegFileAlt style={{color: '#0a6dd7', cursor: 'pointer',width: "1.3rem", height: "1.3rem"}} className='buttonDownload'/>}
                      content={() => ref.current}
                  />   
                  <div className="pdfWrapper" ref={ref}>
                      <Constancy
                          id={rowData.id}
                          data={rowData.data}
                      />
                  </div>
                </>
              }
            </>
        )
    }
    
    const handleCancelService = async (patient:any) => {
        if(window.confirm('¿Seguro desea cancelar este servicio?')){
            if(isDomicilio){
                try {
                  const dataToPost = {
                      newValues: {
                        assigned_status: 'CANCEL',
                        lab: {
                            lab_date_result: '',
                            result_lab: ''
                        },
                      },
                      doc_id: patient.id
                  }
                  await axios.post(`${mobility_update_document}`, dataToPost)            
                } catch (err) {
                    console.log(err);
                    alert('error al cancelar servicio')
                }
            } else {
                try {           
                    if(['DONE', 'PENDING:RESULT', 'DONE:LAB', 'DONE:RESULT'].includes(patient.data.status)){
                        return alert('No puede cancelar este servicio, ya fue completado')
                    } 
                    const data = {
                        currentUser: {
                            ...user,
                            uid: currentUser?.uid
                        },
                        ws: patient.data.patient.ws,
                        incidente_id: patient.id,
                        result: patient.data.lab.result_lab,
                        status: 'CANCEL',
                        fullname: patient.data.patient.fullname,
                        dni: patient.data.patient.dni,
                        dob: patient.data.patient.dob,
                        address: patient.data.patient.address,
                        location: patient.data.patient.location,
                        cm: patient.data.patient.cm,
                        payment_reference: patient.data.payment?.reference || '',
                    }                   
                    await axios.patch(`${analysis}/result/`, data)                    
                } catch (e) {
                    console.log(e);
                    alert('error al cancelar servicio')
                }
            }
        }
    }

    
    const mainSchema = [
        {title: 'ID', field:'id', renderToExcel: ((p:any) => p.id )},
        {title: 'Nombre', field:'data.patient.fullname', renderToExcel: ((p:any) => p.data?.patient.fullname && p.data?.patient.fullname !== "undefined"  ? p.data?.patient.fullname : p.data?.patient.user )},
        {title: 'DNI', field:'data.patient.dni'},
        {title: 'Edad', field:'data.patient.dob', renderToExcel: (patient:any) => moment().diff(moment(patient.data?.patient.dob), 'years')},
        {title: 'Telefono', field:'data.patient.ws'},
        {title: 'Dirección del paciente', field:'data.patient.address', renderToExcel: (patient:any) => patient.data?.patient.address},
        { title: 'Obra social', field: 'data.patient.os', renderToExcel: (patient: any) => patient.data?.patient.os ? patient.data?.patient.os : patient.data?.patient.corporate },
        {title: 'Fecha de cierre', field: 'data.dt_cierre', renderToExcel: (patient: any) => patient.data?.dt_cierre ? moment(patient.data?.dt_cierre.toDate()).format("DD/MM/YYYY") : '-'},
        {title: 'Tipo de servicio', field:'data.service'},
        {title: 'Cliente', field: 'data.client', renderToExcel: (patient: any) => patient.data?.client ? patient.data?.client : '-'},
        {title: 'Servicio realizado', field:'', renderToExcel: (patient:any) => ['DONE', 'PENDING:RESULT', 'DONE:RESULT'].includes(patient.data?.status) ? 'Si' : 'No'},
        {title: 'Resultado', field:'data.lab.result_lab', renderToExcel: (patient:any) => patient.data?.lab?.result_lab === 'POSITIVE' ? 'Detectable' : patient.data?.lab?.result_lab === 'NEGATIVE' ? 'No detectable' : '-'},
        {title: 'Email', field:'data.patient.user_email'},
        {title: 'Email (OS)', field:'data.patient.os_email'}
    ]
    if(user.rol === 'Laboratorio'){
      mainSchema.push({title: 'Fecha de carga resultado', field: 'data.lab.lab_date_result', renderToExcel: (patient: any) => patient.data?.lab.lab_date_result ? moment(patient.data?.lab.lab_date_result.toDate()).format("DD/MM/YYYY") : '-'})
    }

    if (!isDomicilio) {
        mainSchema.push({ title: 'Código', field: 'data.payment', renderToExcel: (patient: any) => patient?.data?.payment?.reference || patient?.data?.payment_data?.reference || '-' })
    }
    
    if (isDomicilio) {
        mainSchema.push({ title: 'Código', field: 'data.payment_data', renderToExcel: (patient: any) => patient?.data?.payment?.reference || patient?.data?.payment_data?.reference || '-' })
    }

    if(isDomicilio) {       
        mainSchema.push({title: 'Status', field:'data.status'})  
        mainSchema.push({title: 'Origen del servicio', field: 'data.origin', renderToExcel: (patient: any) => patient.data?.origin})
        mainSchema.push({title: 'Observaciones', field:'data.destination.user_obs'})  
        mainSchema.push({title: 'Fecha de compra', field: 'data.dt_payment_success', renderToExcel: (patient: any) => patient.data?.dt_payment_success?.toDate ? moment(patient.data?.dt_payment_success?.toDate?.()).format("DD/MM/YYYY") : '-'})
        mainSchema.push({title: 'Fecha de promesa', field: 'data.promise', renderToExcel: (patient: any) => patient.data?.promise ? moment(patient.data?.promise).format("DD/MM/YYYY") : '-'})
        mainSchema.push({title: 'Enfermero', field:'data.delivery.cuit_nurse'})
        mainSchema.push({title: 'Chofer', field:'data.delivery.cuit_delivery'})
        mainSchema.push({ title: 'Dirección del servicio', field: 'data.destination.user_address', renderToExcel: (patient: any) => patient.data?.destination?.user_address },)
        mainSchema.push({ title: 'Hora de inicio del servicio', field: 'data.dt_create', renderToExcel: (patient: any) => patient.data?.dt_start?.toDate ? moment(patient.data?.dt_start.toDate?.()).format("DD/MM/YYYY HH:mm") : '-' })
        mainSchema.push({ title: 'Hora de cierre del servicio', field: 'data.dt_cierre', renderToExcel: (patient: any) => patient.data?.dt_cierre?.toDate ? moment(patient.data?.dt_cierre.toDate?.()).format("DD/MM/YYYY HH:mm") : 'Información no disponible' })
    }

    if(!isDomicilio){
        mainSchema.push(
            {title: 'Punto de testeo', field:'data.patient.cm'},
            {title: 'Estado del pago', field:'data.payment.status', renderToExcel: (patient:any) => patient.data?.payment?.status === 'PAYMENT:SUCCESS' ? 'Completo' : patient.data?.payment?.status === 'PAYMENT:REJECTED' ? 'Rechazado' : patient.data?.payment?.status === 'PAYMENT:PENDING' ? 'Pendiente' : ''},
        )
    }

    const excelButtons = useMemo(() => (
		<>
			<ExportToExcel
				element={"Exportar página"}
				arrayToExport={currentPatients || "50"}
				tableName='Consultas Medicas Online Hoy'
				customSchema={mainSchema}
			/>
      {filterTypes !== 'default' && (
        <ExportToExcel
          // element={`Exportar filtrados (${patientsFiltered.length})`}
          element={`Exportar filtrados (${filteredAndMerged.length})`}
          // arrayToExport={patientsFiltered || "50"}
          arrayToExport={filteredAndMerged || "50"}
          tableName='Consultas Medicas Online Hoy'
          customSchema={mainSchema}
        />
      )}
			<ExportToExcel
				element={
					<>
						{`Exportar todo (${patients.length})`}
						{patients.length > 3000 && <p>Puede demorar unos instantes</p>}
					</>
				}
				arrayToExport={patients}
				tableName='Consultas Medicas Online Hoy'
				customSchema={mainSchema}
			/>
		</>
	), [patients, currentPatients])
    
    const setDropDrowBlur = () => {
      setTimeout(() => {
        setDropDrow(false)
      }, 300)
    }	

    const filters:any = {
      status: <StatusFilter/>,
      service: <ServiceFilter/>,
      nurse: <NurseFilter />,      
      complete: <CompleteFilter />,
      result: <ResultFilter />,
      date: <DateFilter />,
      origin: <OriginFilter />
    }
    

    const optionsFilters = [
      {label: 'Filtros:', value: ''},
      {label: 'Status', value: 'status'},
      {label: 'Servicio', value: 'service'},
      {label: 'Enfermero', value: 'nurse'},
      {label: 'Completado', value: 'complete'},
      {label: 'Resultado', value: 'result'},
      {label: 'Origen', value: 'origin'}
    ]

    if(!isDomicilio) optionsFilters.push({label: 'Fecha', value: 'date'}) 

    const resendResults =  async(patient: Patient) => {
      const patientData = {  
        es: [
          {label: 'Paciente', value: patient.data?.patient.user},
          {label: 'DNI', value: patient.data?.patient.dni},
          {label: 'Fecha', value: moment(patient.data?.dt_cierre?.toDate ? patient.data?.dt_cierre.toDate() : new Date()).format("DD/MM/YYYY")},
        ],
        en: [
          {label: 'Patient', value: patient.data?.patient.fullname},
          {label: 'DNI', value: patient.data?.patient.dni},
          {label: 'Date', value: moment(patient.data?.dt_cierre?.toDate ? patient.data?.dt_cierre.toDate() : new Date()).format("YYYY-MM-DD")},
        ]
      }
      const dataToPDF = {
        ws: patient.data.patient.ws,
        product: patient.data.service,
        requestId: patient.id,
        recipients: [patient.data.patient?.user_email || ''],
        service: patient.data.service === 'PCR Express' || patient.data.service === 'Antígenos' ? 'analysis' : 'delivery',
        data: {
          dni: patient.data.patient.dni,
          logos: ['UMA', 'EMERGENCIAS'],
          patientData, 
          lang: ['es'], 
          result: {es: resultsHelper?.[patient.data.service]?.[patient.data.dt_cierre], en: resultsHelper?.[patient.data.service]?.[patient.data.dt_cierre]}, 
          studieType: patient.data.service,
          validator: validatorHelper[patient.data.service],
          method: {es: methodHelper.es[patient.data.service], en: methodHelper.es[patient.data.service]}
        }
      }
      if(patient.data.service === 'PCR LABORATORIO') dataToPDF.data.logos.push('UNSAM')
      if(patient.data.service === 'PCR LABORATORIO' || patient.data.service === 'PCR EXPRESS' || patient.data.service === 'PCR Express') dataToPDF.data.lang.push('en')
      if (patient.data.patient?.os_email) dataToPDF.recipients.push(patient.data.patient?.os_email)
      try {
        let res = await axios.post(pdf, dataToPDF)
        if(res) {
          swal('Reenviado con éxito!')
        }
      } catch (err) {
        swal('Hubo un problema')
        console.log(err)
      }
    }

    const isVaccineResend = (patient: any) => {
      if (patient.data.service === 'VACUNA ANTIGRIPAL') return true
      if (patient.data.lab.result_lab !== '') return true
      return false
    }


    const Item = React.memo(({patient}:{patient:Patient}) => (
      <div
        className={`row ${isDomicilio && "domicilio"} ${
          patient.data.status === "CANCEL" && "cancelRow"
        } ${user.rol === "ADMINISTRATIVE" && "cancel"} tc ${
          [
            "Call Center Operador",
            "Comercial",
            "Medicina Laboral",
          ].includes(user?.rol)
            ? "no_edit"
            : ""
        } ${
          [
            "ADMINISTRATIVE",
            "Comercial",
            "Medicina Laboral",
            "Operaciones Supervisor",
            "Jefe Operaciones",
          ].includes(user?.rol)
            ? "ceco"
            : ""
        }`}
        key={patient.id}
      >
        <div className="col">
          <p>
            {patient.id}
          </p>
        </div>
        <div className="col">
          <p>
            {patient.data.patient.fullname && patient.data.patient.fullname !== 'undefined'
              ? patient.data.patient.fullname
              : patient.data.patient.user}
          </p>
        </div>
        <div className="col">
          <p>{patient.data.patient.dni}</p>
        </div>
        <div className="col">
          <p>
            {moment().diff(moment(patient.data.patient.dob), "years") ||
              "-"}
          </p>
        </div>
        {
          !['Laboratorio'].includes(user?.rol) &&
          <>
            <div className="col">
              <p>{patient.data.patient.ws}</p>
            </div>
            <div className="col">
              <p>{patient.data.patient.os ? patient.data.patient.os : patient.data.patient.corporate}</p>
            </div>
          </>
        }
        {
          !["Laboratorio"].includes(user?.rol) &&
          <>
            <div className="col">
              <p>{patient.data.patient?.user_email || ''}</p>
            </div>
            <div className="col">
              <p>{patient.data.patient?.os_email || ''}</p>
            </div>
          </>
        }
        {
          !['Laboratorio'].includes(user?.rol) &&
          <>
            <div className="col address">
              <p>
                {patient.data.destination?.user_address ||
                  patient.data.patient.address ||
                  ""}
              </p>
              {
                patient.data.destination?.confirmed &&
                <span className="check" title="Dirección validada por el paciente"><FaCheck /></span>
              }
            </div>
          </>
        }
        {(isDomicilio && !['Laboratorio'].includes(user?.rol)) && (
          <>
            <div className="col">
              <p>{patient.data.destination?.user_obs}</p>
            </div>
            <div className="col">
              <p>{patient.data.lab.observaciones_lab}</p>
            </div>
          </>
        )}
        {!isDomicilio && (
          <div className="col">
            <p>{patient.data.patient.location || ""}</p>
          </div>
        )}
        {(isDomicilio && !['Corporate', 'Laboratorio'].includes(user?.rol)) && (
          <div className="col">
            <p>
              {!!patient.data.promise
                ? moment(patient.data.promise).format("DD-MM-YYYY") || ""
                : ""}
            </p>
          </div>
        )}
        <div className="col">
          <p>
            {
              patient.data.service === 'PCR LABORATORIO' || patient.data.service === 'PCR EXPRESS'?
                patient.data.status === 'DONE' || 
                patient.data.status === 'DONE:LAB' || 
                patient.data.status === 'DONE:RESULT' ?
                  patient.data.dt_assign !== '' ?
                  moment(patient.data.dt_assign.toDate()).format(
                    "DD/MM/YYYY"
                  )
                  : "-"
                : "-"
              : patient.data.dt_cierre ?
                moment(patient.data.dt_cierre.toDate()).format(
                  "DD/MM/YYYY"
                )
                : "-"
            }
          </p>
        </div>
        {(['ADMINISTRATIVE', 'Laboratorio'].includes(user?.rol)) && (
          <div className="col">
            <p>
              {
                patient.data.status === 'DONE:RESULT' ?
                  patient.data.lab.lab_date_result ?
                    moment(patient.data.lab.lab_date_result.toDate()).format(
                      "DD/MM/YYYY"
                    )
                  : "-"
                : "-"
              }
            </p>
          </div>
        )}
        <div className="col">
          <p>{patient.data.service}</p>
        </div>
        {
          (isDomicilio && !['Corporate', 'Laboratorio'].includes(user?.rol)) &&
          <div className="col">
            <p>{patient.data.origin === 'doctor' ? 'Doctor App' : patient.data.origin === 'patient' ? 'Patient App' : patient.data.origin === '' || patient.data.origin === 'portal' ? 'Portal' : '-'}</p>
          </div>
        }
        {!isDomicilio && (
          <>
            <div className="col">
              <p>{patient.data.patient.cm}</p>
            </div>
            <div className="col">
              <p>
                {patient.data.payment?.status === "PAYMENT:SUCCESS"
                  ? "Completo"
                  : patient.data.payment?.status === "PAYMENT:REJECTED"
                  ? "Rechazado"
                  : patient.data.payment?.status === "PAYMENT:PENDING"
                  ? "Pendiente"
                  : ""}
              </p>
            </div>
          </>
        )}

        <div className="col">
          <p>
            {["DONE", "PENDING:RESULT", "DONE:LAB", "DONE:RESULT"].includes(
              patient.data.status
            )
              ? "Si"
              : "No"}
          </p>
        </div>
        <div className="col result">
          <p
            className={
              patient.data.lab?.result_lab === "POSITIVE"
                ? "detectable"
                : patient.data.lab?.result_lab === "NEGATIVE"
                ? "no_detectable"
                : patient.data.lab?.result_lab === 'NOT_PROCESSED'
                ? "not_processed"
                : ""
            }
          >
            {patient.data.lab?.result_lab === "POSITIVE"
              ? "Detectable"
              : patient.data.lab?.result_lab === "NEGATIVE"
              ? "No detectable"
              : patient.data.lab?.result_lab === 'NOT_PROCESSED'
              ? "No se pudo procesar"
              : patient.data.status === 'DONE:REJECTED'
              ? "Rechazado por obra social"
              : "-"
            }
          </p>
        </div>         
        {[
          "ADMINISTRATIVE",
          "Personal Médico",
          'Laboratorio',
          "Call Center Supervisor",
          "Operaciones Analista",
          "Operaciones Supervisor",
          "Jefe Operaciones",
        ].includes(user?.rol) && ( 
          <>
            <div className="col">
              <button onClick={() => handlePatientProfile(patient)}>
                <FaPencilAlt />
              </button>
            </div>
            {
              (!['Laboratorio'].includes(user?.rol) && (patient.data.patient.user_email || patient.data.patient.os_email) && isVaccineResend(patient) && patient.data.lab.result_lab !== 'NOT_PROCESSED') ?
              <div className="col">
                <button onClick={() => resendResults(patient)}>
                Reenviar {patient.data.service === 'VACUNA ANTIGRIPAL' ? 'constancia' : 'resultados'}
                </button>
              </div> :
              '-'
            }
          </>
        )} 
        {isDomicilio && ["ADMINISTRATIVE", "Operaciones Analista", "Operaciones Supervisor", "Call Center Supervisor", "Jefe Operaciones"].includes(user?.rol) && (
          <div className="col">
            {["DONE", "DONE:RESULT", "DONE:LAB"].includes(patient.data.status) &&
              patient.data.patient.dni_path && (
                <a href={patient.data.patient.dni_path} target="_blank">
                  <FaAddressCard
                    style={{
                      color: "#0a6dd7",
                      cursor: "pointer",
                      width: "1.4rem",
                      height: "1.4rem",
                    }}
                  />
                </a>
              )}
          </div>
        )}
        {(isDomicilio && !['Corporate', 'Laboratorio'].includes(user?.rol)) && (
          <div className="col">
            <p>
              {patient.data.delivery?.fullname_nurse}{" "}
              {patient.data.delivery?.cuit_nurse}
            </p>
          </div>
        )}
        {(!['Corporate'].includes(user?.rol)) && (
          <div className="col">
            <p>{patient.data.status}</p>
          </div>
        )}
        {
          !['Laboratorio', 'Lectura'].includes(user?.rol) &&
          <>
            <div className="col dowloadConstancy">
              {
                  patient.data.service !== 'VACUNA ANTIGRIPAL' ?
                  ["DONE", 'DONE:LAB', "DONE:RESULT"].includes(patient.data.status) &&
                  ['POSITIVE', 'NEGATIVE'].includes(patient.data.lab.result_lab)
                  && (
                  <ConstancyTrigger rowData={patient} />
                  )
                  : 
                  patient.data.service === 'VACUNA ANTIGRIPAL'? 
                  patient.data.status === 'DONE' &&
                    <ConstancyTrigger rowData={patient} />
                  : null
              }
            </div>
          </>
        }
        {
          !['Corporate', 'Laboratorio'].includes(user?.rol) &&
          <div className="col">
            <p className="payment_reference">
              {patient.data.payment?.reference ||
                patient.data.payment_data?.reference}
            </p>
          </div>
        }
        {[
          "ADMINISTRATIVE",
          "Operaciones Analista",
          "Operaciones Supervisor",
          "Comercial",
          "Call Center Supervisor",
          "Medicina Laboral",
          "Jefe Operaciones",
        ].includes(user?.rol) && (
          <div className="col">
            <button onClick={() => handleCancelService(patient)}>
              <FaTimesCircle />
            </button>
          </div>
        )}
      </div>
    ))
    
    return (
      <section>
        <div className="topTableInfoContainer">
          <div className="filtersContainer">
            <div className="filters">
                {filterTypes.map((filter:any, i:number)=> (
                    <div key={i} className="filter">
                        <select 
                          value={filter}
                          onChange={(e) => {
                            let temp = [...filterTypes]
                            temp[i] = e.target.value
                            setFilterTypes(temp)
                          }}
                        >
                          {optionsFilters
                            .map(({value, label}, index) => (
                              <option key={index} value={value}>{label}</option>
                            ))
                          }
                        </select>
                        {filters[filter]}
                    </div>
                  ))}
              </div>
              <div className="buttons">
                  <button
                    onClick={() => setFilterTypes([...filterTypes, ''])}
                    >
                    Agregar filtro
                  </button>
                  {filterTypes.length > 0 && (
                    <button onClick={() => {
                      setFilterTypes([])
                      dispatch({type: SET_PATIENTS_FILTERED, payload: patients})
                    }}>
                      Limpiar filtros
                    </button>
                  )}
              </div>
          </div>
          {
            !['Corporate', 'Lectura'].includes(user?.rol) &&
            <div className="exportToExcelContainer" title="Exportar a excel">
              <button
                className={`excelIcon ${
                  currentPatients.length === 0 ? "disabled" : ""
                }`}
                onClick={() => setDropDrow(!dropDownExcel)}
                onBlur={setDropDrowBlur}
              >
                Exportar <FaDownload />
              </button>
              <div
                className={`dropdown-content ${"dropToDown"} ${
                  dropDownExcel && "active"
                }`}
              >
                {excelButtons}
              </div>
            </div>
          }
        </div>
        <div className="table">
          <div
            className={`row ${isDomicilio && "domicilio"} ${
              user.rol === "ADMINISTRATIVE" && "cancel"
            } th ${
              [
                "Call Center Operador",
                "Comercial",
                "Medicina Laboral",
              ].includes(user?.rol)
                ? "no_edit"
                : ""
            } ${
              [
                "ADMINISTRATIVE",
                "Comercial",
                "Medicina Laboral",
                "Operaciones Supervisor",
                "Jefe Operaciones",
              ].includes(user?.rol)
                ? "ceco"
                : ""
            }`}
          >
            <div className="col">
              <p className="title">ID</p>
            </div>
            <div className="col">
              <p className="title">Nombre</p>
            </div>
            <div className="col">
              <p className="title">DNI</p>
            </div>
            <div className="col">
              <p className="title">Edad</p>
            </div>
            {
              !["Laboratorio"].includes(user?.rol) &&
              <>
                <div className="col">
                  <p className="title">Teléfono</p>
                </div>
                <div className="col">
                  <p className="title">Obra social</p>
                </div>
                {
                  // [
                  //   "ADMINISTRATIVE",
                  //   "Comercial",
                  //   "Medicina Laboral",
                  //   "Operaciones Supervisor",
                  //   "Jefe Operaciones",
                  // ].includes(user?.rol) &&
                  !["Laboratorio"].includes(user?.rol) &&
                  <>
                    <div className="col">
                      <p className="title">E-mail</p>
                    </div>
                    <div className="col">
                      <p className="title">E-mail (OS)</p>
                    </div>
                  </>
                }
                <div className="col">
                  <p className="title">Dirección</p>
                </div>
              </>
            }
            {(isDomicilio && !["Laboratorio"].includes(user?.rol)) && (
              <>
                <div className="col">
                  <p className="title">Observaciones paciente</p>
                </div>
                <div className="col">
                  <p className="title">Observaciones enfermero</p>
                </div>
              </>
            )}
            {!isDomicilio && (
              <div className="col">
                <p className="title">Localidad</p>
              </div>
            )}
            {(isDomicilio && !['Corporate', 'Laboratorio'].includes(user?.rol)) && (
              <div className="col">
                <p className="title">Promesa</p>
              </div>
            )}
            <div className="col">
              <p className="title">Fecha de cierre</p>
            </div>
            {(['ADMINISTRATIVE', 'Laboratorio'].includes(user?.rol)) && (
              <div className="col">
                <p className="title">Fecha de resultado</p>
              </div>
            )}
            <div className="col">
              <p className="title">Servicio</p>
            </div>
            {
              (isDomicilio && !['Corporate', 'Laboratorio'].includes(user?.rol)) &&
              <div className="col">
                <p className="title">Origen</p>
              </div>
            }
            {!isDomicilio && (
              <>
                <div className="col">
                  <p className="title">Punto de testeo</p>
                </div>
                <div className="col">
                  <p className="title">Estado del pago</p>
                </div>
              </>
            )}
            <div className="col">
              <p className="title">Completado</p>
            </div>
            <div className="col">
              <p className="title">Resultado</p>
            </div>
            {[
              "ADMINISTRATIVE",
              "Personal Médico",
              "Laboratorio",
              "Call Center Supervisor",
              "Operaciones Analista",
              "Operaciones Supervisor",
              "Jefe Operaciones",
            ].includes(user?.rol) && (
              <>
                <div className="col">
                  <p className="title">Editar</p>
                </div>
                {
                  !['Laboratorio'].includes(user?.rol) &&
                  <div className="col">
                    <p className="title">Reenviar</p>
                  </div>
                }
              </>
            )}
            {(
              isDomicilio && 
              ["ADMINISTRATIVE", "Operaciones Analista", "Operaciones Supervisor", "Call Center Supervisor", "Jefe Operaciones"].includes(user?.rol)) && (
              <div className="col">
                <p className="title">Ver Confirmación</p>
              </div>
            )}
            {(isDomicilio && !['Corporate', 'Laboratorio'].includes(user?.rol)) && (
              <div className="col">
                <p className="title">Enfermero</p>
              </div>
            )}
            {(!['Corporate'].includes(user?.rol)) && (
              <div className="col">
                <p className="title">Status</p>
              </div>
            )}
            {
              !['Corporate', 'Laboratorio', 'Lectura'].includes(user?.rol) &&
              <div className="col">
                <p className="title">Ver constancia</p>
              </div>
            }
            {
              !['Corporate', 'Laboratorio'].includes(user?.rol) &&
              <div className="col">
                <p className="title">Referencia de pago</p>
              </div>
            }
            {[
              "ADMINISTRATIVE",
              "Operaciones Analista",
              "Operaciones Supervisor",
              "Comercial",
              "Call Center Supervisor",
              "Medicina Laboral",
              "Jefe Operaciones",
            ].includes(user?.rol) && (
              <div className="col">
                <p className="title">Cancelar servicio</p>
              </div>
            )}
          </div>
          {currentPatients.map((patient: Patient, index: number) => (
            <Item
              key={patient.id}
              patient={patient}
            />
          ))}
        </div>
        <Pagination
          usersPerPage={usersPerPage}
          // totalUsers={patientsFiltered.length}
          totalUsers={filteredAndMerged.length}
          paginate={paginate}
          currentPage={currentPage}
        />
      </section>
    );
}

export default Table