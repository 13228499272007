import React from 'react';
import '../../styles/Pagination.scss';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

interface Paginate {
    (n: number): number;
}

const Pagination = (
    { usersPerPage, totalUsers, paginate, currentPage }:
    { usersPerPage: number, totalUsers: number, paginate: Paginate, currentPage: number }
): React.ReactElement => {
    const pageNumbers = [];

    for (let i = 1; i <= Math.ceil(totalUsers / usersPerPage); i++) {
        pageNumbers.push(i);
    }

    return (
        <nav className="paginationNav">
            <ul className="pagination">
                {
                    currentPage > 1 &&
                    <li className="pageItem arrowPrev">
                        <button onClick={() => paginate(currentPage - 1)}><FaChevronLeft /></button>
                    </li>
                }
                {
                    pageNumbers.map((number, index) => (
                        <li key={index} className={`pageItem ${currentPage === number ? 'active' : ''}`}>
                            <button onClick={() => paginate(number)}>{number}</button>
                        </li>
                    ))
                }
                {
                    currentPage < pageNumbers.length &&
                    <li className="pageItem arrowNext">
                        <button onClick={() => paginate(currentPage + 1)}><FaChevronRight /></button>
                    </li>
                }
            </ul>
        </nav>
    )
}

export default Pagination;