import { combineReducers } from 'redux';
import delivery from './deliveryReducers';
import patients from './patientsReducers';
import user from './userReducers';
import utils from './utilsReducers';

const appReducer = combineReducers({
    delivery,
    patients,
    user,
    utils,
})

const rootReducer = (state: any, action: any) => {
    if (action.type === 'USER_LOGOUT') {
        state = undefined
    }
    
    return appReducer(state, action);
}

export default rootReducer;