import React, { useState, useEffect } from 'react'
import '../../../styles/Hisopados.scss'
import { useSelector, useDispatch } from 'react-redux'
import Table from '../../GeneralComponents/Table'
import Modal from '../../GeneralComponents/Modal'
import Header from '../../Header'
import db from '../../../config/db'
import { useHistory } from 'react-router'
import CustomUmaLoader from '../../GeneralComponents/Loader'
import moment from 'moment'
import { SET_MODAL, SET_MODAL_COMPONENT } from '../../../store/types/utilsTypes'
import LoadService from './CargarService/ModalLoadService'
import CargarHisopado from '../domicilio/CargarHisopado'

const Hisopados:React.FC = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    const [loader, setLoader] = useState(true)
    const firestore = db.firestore()
    const { modal, consultingRooms, modalComponent } = useSelector((state: any) => state.utils)
    const { user, currentUser } = useSelector((state: any) => state.user)
    const { searchedItem, patients } = useSelector((state: any) => state.patients)
    const [timeoutEvent, setTimeoutEvent] = useState<(any)>()
    const [filteredPatients, setFilteredPatients] = useState(false)
    const [salesCount, setSalesCount] = useState({Caba:0,Coghlan:0,Lomas:0,Consti:0,Retiro:0,Olivos:0,Moron:0,Gallardo:0, Ezeiza: 0, Aeroparque: 0})

    useEffect(() => {
        if(user && ['Corporate'].includes(user.rol)) {
            history.push('/domicilio')
        }
    }, [user])

    useEffect(() => {
        firestore.collection('parametros').doc('userapp').collection('analysis').doc('antigenos').get()
            .then((doc: any) => {
                if (doc.exists) {
                    dispatch({ type: 'SET_CONSULTING_ROOMS', payload: doc.data().consulting_rooms })
                }
            })
            .catch(e => console.error(e))
    }, [])

    useEffect(() => {
        if (searchedItem !== 'PAYMENT:SUCCESS') {
            setFilteredPatients(false)
        }
    }, [searchedItem])

    const filterPatients = (text: string) => {
        doSearch(text)
    }

    const doSearch = (text: string) => {
        if (timeoutEvent) {
            clearTimeout(timeoutEvent);
        }
        setTimeoutEvent(setTimeout(() => {
            dispatch({ type: 'SET_SEARCHED_ITEM', payload: text.toLowerCase() })
        }, 500))
    }

    const handleCloseModal = () => {
        dispatch({ type: 'RESET_MODAL' })
    }

    const handleFilterByPayment = (value : boolean) => {
        if(value) {
            dispatch({ type: 'SET_SEARCHED_ITEM', payload: '' })
            setFilteredPatients(false);
        } else {
            dispatch({ type: 'SET_SEARCHED_ITEM', payload: 'PAYMENT:SUCCESS' })
            setFilteredPatients(true);
        }
    }

    const handleFilterByPoint = (value : string) => {
        if(value && value !== 'off') {
            dispatch({ type: 'SET_SEARCHED_ITEM', payload: value })
        } else {
            dispatch({ type: 'SET_SEARCHED_ITEM', payload: '' })
        }
    }

    useEffect(() => {
        if(user.rol){
            let subscription = () => {};
            try {
                let query = firestore.collection('events/requests/analysis') as any
                if (['Laboratorio'].includes(user?.rol)) {
                    query = query.where('service', '==', 'PCR LABORATORIO')
                } else if(user.rol === 'Comercial'){
                    query = query.where('csvUploadedBy', '==', currentUser.uid)
                } else if(user.corporate === 'DOSUBA') {
                    query = query.where('patient.corporate', '==', 'DOSUBA')
                } else {
                    query = query.where('status', '!=', 'FREE').orderBy('status', 'desc')
                }
                subscription = query.onSnapshot((snapshot:any) => {
                    const arr_patients: any[] = []
                    snapshot.forEach((doc:any) => {
                        arr_patients.push({id: doc.id, data: doc.data()})
                    })
                    dispatch({ type: 'SET_PATIENTS', payload: arr_patients })
                    setLoader(false)
                })
                return () => {
                    subscription()
                    dispatch({ type: 'SET_PATIENTS', payload: [] })
                } 
            } catch (e) {
                console.error(e)
                setLoader(false)
                return () => {
                    subscription()
                    dispatch({ type: 'SET_PATIENTS', payload: [] })
                } 
            }
        }
    }, [user])

    useEffect(() => {
        if(patients.length) {
            let caba = []
            let olivos = []
            let coghlan = []
            let lomas = []
            let constitucion = []
            let retiro = []
            let moron = []
            let gallardo = []
            let ezeiza = []
            let aeroparque = []
            if (currentUser.email !== 'mpastoriza@emergencias.com.ar') {
                caba = patients.filter((el: any) => {
                    return (el.data.patient.cm === 'CABA' || el.data.patient.cm === 'Palermo') && moment(el.data.dt_cierre ? el.data.dt_cierre.toDate() : new Date()).format("DD/MM/YYYY") === moment().format('DD/MM/YYYY')
                })
                
                coghlan = patients.filter((el: any) => {
                    return el.data.patient.cm === 'Coghlan' && moment(el.data.dt_cierre ? el.data.dt_cierre.toDate() : new Date()).format("DD/MM/YYYY") === moment().format('DD/MM/YYYY')
                })
                
                lomas = patients.filter((el: any) => {
                    return el.data.patient.cm === 'Lomas de Zamora' && moment(el.data.dt_cierre ? el.data.dt_cierre.toDate() : new Date()).format("DD/MM/YYYY") === moment().format('DD/MM/YYYY')
                })
                
                constitucion = patients.filter((el: any) => {
                    return el.data.patient.cm === 'Estación Constitución' && moment(el.data.dt_cierre ? el.data.dt_cierre.toDate() : new Date()).format("DD/MM/YYYY") === moment().format('DD/MM/YYYY')
                })
                
                retiro = patients.filter((el: any) => {
                    return el.data.patient.cm === 'Estación Retiro' && moment(el.data.dt_cierre ? el.data.dt_cierre.toDate() : new Date()).format("DD/MM/YYYY") === moment().format('DD/MM/YYYY')
                })
                moron = patients.filter((el: any) => {
                    return el.data.patient.cm === 'Morón' && moment(el.data.dt_cierre ? el.data.dt_cierre.toDate() : new Date()).format("DD/MM/YYYY") === moment().format('DD/MM/YYYY')
                })
                gallardo = patients.filter((el: any) => {
                    return el.data.patient.cm === 'Gallardo' && moment(el.data.dt_cierre ? el.data.dt_cierre.toDate() : new Date()).format("DD/MM/YYYY") === moment().format('DD/MM/YYYY')
                })
                olivos = patients.filter((el: any) => {
                    return el.data.patient.cm === 'Olivos' && moment(el.data.dt_cierre ? el.data.dt_cierre.toDate() : new Date()).format("DD/MM/YYYY") === moment().format('DD/MM/YYYY')
                })
                ezeiza = patients.filter((el: any) => {
                    return el.data.patient.cm === 'Ezeiza' && moment(el.data.dt_cierre ? el.data.dt_cierre.toDate() : new Date()).format("DD/MM/YYYY") === moment().format('DD/MM/YYYY')
                })
                aeroparque = patients.filter((el: any) => {
                    return el.data.patient.cm === 'Aeroparque' && moment(el.data.dt_cierre ? el.data.dt_cierre.toDate() : new Date()).format("DD/MM/YYYY") === moment().format('DD/MM/YYYY')
                })
            } else {
                caba = patients.filter((el: any) => {
                    return el.data.patient.cm === 'CABA' || el.data.patient.cm === 'Palermo'
                })

                coghlan = patients.filter((el: any) => {
                    return el.data.patient.cm === 'Coghlan'
                })

                lomas = patients.filter((el: any) => {
                    return el.data.patient.cm === 'Lomas de Zamora'
                })

                constitucion = patients.filter((el: any) => {
                    return el.data.patient.cm === 'Estación Constitución'
                })

                retiro = patients.filter((el: any) => {
                    return el.data.patient.cm === 'Estación Retiro'
                })
            }

            setSalesCount({
                Caba:caba.length,
                Coghlan:coghlan.length,
                Lomas:lomas.length,
                Consti:constitucion.length,
                Retiro:retiro.length,
                Olivos:olivos.length,
                Moron:moron.length,
                Gallardo:gallardo.length,
                Ezeiza:ezeiza.length,
                Aeroparque:aeroparque.length
            })

        }
    },
     [patients])

    const handlerOpenLoadModal = () => {
        dispatch({type: SET_MODAL,
             payload: true})
        dispatch({type: SET_MODAL_COMPONENT, payload: <CargarHisopado serviceType='analysis'/> })
    }

    if(loader) {
        return <CustomUmaLoader />
    }

    return (
        <>
            <Header />
            <section className="hisopados">
                <div className="container">
                    <div className="hisopados_header">
                        <h1>Servicios presenciales</h1>

                        <div>
                            <input
                                type="search" name="search" autoComplete="off"
                                placeholder="Buscar paciente por nombre o DNI"
                                className="search_input"
                                onChange={(e) => filterPatients(e.target.value)}
                            />
                            {
                                !['Corporate'].includes(user?.rol) &&
                                <button onClick={() => handleFilterByPayment(filteredPatients)}>
                                    {
                                        !filteredPatients ? 'Ver solo pagos completados' : 'Limpiar filtro'
                                    }
                                </button>
                            }
                            
                            {(['ADMINISTRATIVE', 'Comercial', 'Medicina Laboral', 'Operaciones Supervisor', 'Call Center Supervisor', 'Jefe Operaciones'].includes(user?.rol) && user.corporate !== 'DOSUBA') && (
                                <button onClick={() => history.push('/carga-masiva?schema=analysis&redirect=/presenciales')}>
                                    Cargar CSV
                                </button>
                            )}
                            <select onChange={(e) => handleFilterByPoint(e.target.value)}>
                                <option value="off">Filtrar por punto de testeo</option>
                                {/* {
                                    consultingRooms.map((room: any) => (
                                        <option key={room.address} value={room.name}>{room.name}</option>
                                    ))
                                } */}
                                <option value='Palermo'>Palermo</option>
                                <option value='Lomas de Zamora'>Lomas de Zamora</option>
                                <option value='Coghlan'>Coghlan</option>
                                <option value='Estación Constitución'>Constitución</option>
                                <option value='Estación Retiro'>Retiro</option>
                                <option value='Lavallol'>Lavallol</option>
                                <option value='Morón'>Morón</option>
                                <option value='El Talar'>El Talar</option>
                                <option value='CABA_Zabala'>Zabala (CABA)</option>
                                <option value='Olivos'>Olivos</option>
                                <option value='Gallardo'>Gallardo</option>
                                <option value='Ezeiza'>Ezeiza</option>
                                <option value='Aeroparque'>Aeroparque</option>
                            </select>
                        </div>
                    </div>
                    <div className="anotherData">
                        {user.rol !== 'Lectura' &&
                        (
                        <div className="salesOfTheDay">
                            {
                                currentUser.email !== 'mpastoriza@emergencias.com.ar' ?
                                <p>Realizados hoy {moment().format('DD/MM/YYYY')}</p> :
                                <p>Cantidad total de hisopados realizados por punto:</p>
                            }
                            <ul className="sales">
                                <li>Palermo: {salesCount.Caba}</li>
                                <li>Coghlan: {salesCount.Coghlan}</li>
                                <li>Lomas de Zamora: {salesCount.Lomas}</li>
                                <li>Constitución: {salesCount.Consti}</li>
                                <li>Retiro: {salesCount.Retiro}</li>
                                <li>Olivos: {salesCount.Olivos}</li>
                                <li>Morón: {salesCount.Moron}</li>
                                <li>Gallardo: {salesCount.Gallardo}</li>
                                <li>Ezeiza: {salesCount.Ezeiza}</li>
                                <li>Aeroparque: {salesCount.Aeroparque}</li>
                                {['ADMINISTRATIVE', 'Comercial', 'Medicina Laboral', 'Operaciones Supervisor', 'Call Center Supervisor', 'Jefe Operaciones'].includes(user?.rol) && (
                                    <button onClick={handlerOpenLoadModal}>
                                        Carga manual
                                    </button>
                                )}
                                {/* {['ADMINISTRATIVE'].includes(user?.rol) && (
                                    <button onClick={handlerOpenLoadModal}>
                                        Carga manual
                                    </button>
                                )} */}
                            </ul>
                        </div>
                        )
                        }
                    </div>
                    <Table />
                    {
                        modal &&
                        <Modal close={handleCloseModal}>
                            {modalComponent}
                        </Modal>
                    }
                </div>
            </section>
        </>
    )
}

export default Hisopados