export const payment_references = [
    { value: 'CORPORACIONES UMA', label: 'CORPORACIONES UMA'},
    { value: 'CONSUMIDOR FINAL', label: 'CONSUMIDOR FINAL'},
    { value: 'COMERCIOS Y PYMES', label: 'COMERCIOS Y PYMES'},
    { value: 'REFERIDOS NEGOCIOS', label: 'REFERIDOS NEGOCIOS'},
    { value: 'CORPORACIONES', label: 'CORPORACIONES'},
    { value: 'CORPORACIONES - AEROPUERTOS', label: 'CORPORACIONES - AEROPUERTOS'},
    { value: 'CORPORACIONES - PETROLEO Y MINERIA', label: 'CORPORACIONES - PETROLEO Y MINERIA'},
    { value: 'SALUD Y ESTADO', label: 'SALUD Y ESTADO'},
    { value: 'SALUD Y ESTADO - UP', label: 'SALUD Y ESTADO - UP'},
    { value: 'SALUD Y ESTADO - PAMI', label: 'SALUD Y ESTADO - PAMI'},
    { value: 'SALUD Y ESTADO - IOMA', label: 'SALUD Y ESTADO - IOMA'},
    { value: 'SALUD Y ESTADO - TRENES', label: 'SALUD Y ESTADO - TRENES'},
    { value: 'MEDICINA LABORAL', label: 'MEDICINA LABORAL'}
]
