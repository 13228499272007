import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import {FaUserAlt, FaUsers, FaWindowClose} from 'react-icons/fa';
import { faHouseUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import db from '../../config/db';
import logo from '../../assets/logo.png';
import '../../styles/Sidebar.scss';

const Sidebar = () : React.ReactElement => {
    const [showSidebar, setshowSidebar] = useState()
    const location = useLocation()    
    const auth = db.auth()
    const dispatch = useDispatch()
    const history = useHistory()
    const { user, permissions } = useSelector((state: any) => state.user)
    
    const handleSignOut = () => {
        try {
            auth.signOut()
                .then(() => {
                    dispatch({type: 'USER_LOGOUT'})
                    history.push('/');
                })
                .catch(e => console.error(e));
        } catch (e) {
            console.error(e);
        }
    }
    const isActive = (path:string) => location.pathname.includes(path)

    return (
    <div className="calendar-container">
        <img  src={logo} alt="logo Uma" className={`${showSidebar ? null : 'imgOnSideBarHide'}`}/>
        <div className="tabs-container">
            {
                !['Corporate'].includes(user?.rol) &&
                <div
                    className={`calendar-tab ${(isActive('/presenciales') || location.pathname === '/') && 'active'}`}
                >
                    <Link to="/presenciales">
                        <FaUsers /> Servicios presenciales
                    </Link>
                </div>
            }
            <div
                className={`calendar-tab ${isActive('/domicilio') && 'active'}`}
            >
                <Link to="/domicilio">
                    <FontAwesomeIcon icon={faHouseUser} />
                    Servicios a domicilio
                </Link>
            </div>
            {
                !['DOSUBA'].includes(user?.corporate) &&
                <div className={`calendar-tab ${isActive('/usuarios') && 'active'}`}>
                    <Link to="/stats">
                        <FaUserAlt /> Datos
                    </Link>
                </div>
            }
            <div 
                className="calendar-tab">
                <a onClick={handleSignOut}>
                    <FaWindowClose /> Cerrar sesión
                </a>
            </div>
        </div>
        <div 
            className={`arrowHideSidebar ${showSidebar ? null:'turnRound'}`}>
            <i className="fas fa-arrow-left"></i>
        </div>
    </div>
    )
}

export default Sidebar