import swal from 'sweetalert';

export const currentPositionHandler = (position:any) => {
	if (position.coords.latitude && position.coords.longitude)
		return {
			lat: position.coords.latitude,
			lng: position.coords.longitude,
		}
}

export const errorHandler = (err:any) => alert(err);

export const handleApiLoaded = (callback:Function) => {
	return new Promise((resolve, reject) => {
		try {
			if (!navigator.geolocation) reject('Geolocalización no soportada.');
			navigator.geolocation.getCurrentPosition((pos) => {
				const latLng = currentPositionHandler(pos) as any
				resolve(callback(latLng));
			}, errorHandler);
		} catch (error) {
			console.error(error);
			reject(error);
		}
	});
};

// export const routeDrawer = (map, mapsApi, directionsService, directionsDisplay) => (fullMap, origin, destiny) => {
// 	// waypoints = null
// 	// waypoints,
// 	// optimizeWaypoints: true,
// 	return new Promise((resolve, reject) => {
// 		if (!origin?.lat || !destiny?.lat) {
// 			return reject('No hay datos disponibles');
// 		}
// 		const request = {
// 			origin: new mapsApi.LatLng(origin.lat, origin.lng || origin.lon),
// 			destination: new mapsApi.LatLng(destiny.lat, destiny.lng || destiny.lon),
// 			travelMode: mapsApi.DirectionsTravelMode.DRIVING,
// 		};
// 		let latLng = new mapsApi.LatLng(origin.lat, origin.lng || origin.lon);
// 		directionsService.route(request, function (response, status) {
// 			if (status === mapsApi.DirectionsStatus.OK) {
// 				directionsDisplay.setDirections(response);
// 				if (!fullMap) {
// 					map.setCenter(latLng);
// 					map.panTo(latLng);
// 					map.setZoom(17);
// 					directionsDisplay.setOptions({ preserveViewport: true });
// 				} else {
// 					directionsDisplay.setOptions({
// 						suppressMarkers: true,
// 						preserveViewport: false
// 					});
// 				}
// 				resolve(response.routes[0].legs[0].duration.text);
// 			}
// 		});
// 	});
// };

export const routeDrawer = (mapsApi:any, directionsService:any, directionsDisplay:any) => (origin:any, destiny:any) => {
	return new Promise((resolve, reject) => {
		if (!origin?.lat || !destiny?.lat) reject('No hay datos disponibles');
		const request = {
			origin: new mapsApi.LatLng(origin.lat, origin.lng || origin.lon),
			destination: new mapsApi.LatLng(destiny.lat, destiny.lng || destiny.lon),
			travelMode: mapsApi.DirectionsTravelMode.DRIVING,
		};
		directionsService.route(
			request,
			function (response:any, status:any) {
				if (status == mapsApi.DirectionsStatus.OK) {
					directionsDisplay.setDirections(response);
					directionsDisplay.setOptions({
						preserveViewport: true,
						suppressMarkers: true
					});
					resolve(response.routes[0].legs[0].duration.text);
				}
			});
	});
};

export const mapBounds = (map:any, mapsApi:any) => (points:any) => {
	try {
		if (points?.length === 0 || !points?.[0].lat) return null;
		const bounds = new mapsApi.LatLngBounds();
		points.forEach((item:any) => {
			const position = new mapsApi.LatLng(item.lat, item.lon || item.lng);
			bounds.extend(position);
		});
		return map.fitBounds(bounds);
	} catch (error) {
		console.error(error);
		return error;
	}
};

export const mapConfig = (
	center = { lat: 0, lng: 0 },
	zoom = 13,
	yesIWantToUseGoogleMapApiInternals = true,
	options = {
		streetViewControl: false,
		disableDefaultUI: false,
		mapTypeControl: false,
		styles: [
			{
				featureType: 'poi',
				elementType: 'labels',
				stylers: [{ visibility: 'off' }],
			},
		],
	},
	bootstrapURLKeys = {
		key: 'AIzaSyDLnpXWJx1qKAfHtTeYWa30b9jGH2GeXfs',
		libraries: ['places', 'geometry', 'drawing', 'visualization'],
		language: 'es',
	}
) => ({
	center,
	bootstrapURLKeys,
	yesIWantToUseGoogleMapApiInternals,
	zoom,
	options,
});


export function calculatedestinyPoint(currentService:any) {
	switch (currentService?.status_tramo) {
		case 'GOING-ORIGIN':
			return {
				lat: currentService?.request?.geo_inicio?.lat,
				lon: currentService?.request?.geo_inicio?.lon,
				address: currentService?.request?.geo_inicio?.address
			};
		case 'ARRIVED-ORIGIN':
			return {
				lat: currentService?.request?.geo_inicio?.lat,
				lon: currentService?.request?.geo_inicio?.lon,
				address: currentService?.request?.geo_inicio?.address
			};
		case 'GOING-DESTINY':
			return {
				lat: currentService?.request?.geo_fin?.lat,
				lon: currentService?.request?.geo_fin?.lon,
				address: currentService?.request?.geo_fin?.address
			};
		case 'FINISHED':
			return {
				lat: currentService?.request?.geo_fin?.lat,
				lon: currentService?.request?.geo_fin?.lon,
				address: currentService?.request?.geo_fin?.address
			};
		default:
			return {
				lat: currentService?.request?.geo_inicio?.lat || 0,
				lon: currentService?.request?.geo_inicio?.lon || 0
			};
	}
}


