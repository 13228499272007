import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone'
import { Title, Spacer } from '@umahealth/occipital-ui'
import StatCard from './StatCard'
import db from '../../config/db';

const Delivery = () => {
    const firestore = db.firestore();
    const [providersActive, setProvidersActive] = useState<string>("-")
    const [providersActiveNow, setProvidersActiveNow] = useState<string>("-")
    const [servicesPerNurse, setServicesPerNurse] = useState<any>([])
    const [promiseToday, setPromiseToday] = useState<any>("-")
    const [finishedToday, setFinishedToday] = useState<any>('-')
    const [yesterdayDetails, setYesterdayDetails] = useState<any>({})
    const [todayDetails, setTodayDetails] = useState<any>({
        pending: '-',
        portalCancel: '-',
        absent: '-',
        rejectedOs: '-',
        address: '-',
        purchases: '',
        portal: '',
        doctor: ''
    })

    useEffect(() => {
        let date = moment().tz('America/Argentina/Buenos_Aires').subtract(2, 'hours').toDate()
        let day = moment().tz('America/Argentina/Buenos_Aires').set({hour:0,minute:0,second:0,millisecond:0}).toDate()
        let today = moment().format('YYYY-MM-DD')
        let yesterday = moment().subtract(1, 'days').format('YYYY-MM-DD')

        getProvidersOnTime(date).then(({unique, nurseCounts}: any)=> {
            setProvidersActiveNow(unique.length.toString() || '-')
        })
        getProvidersOnTime(day).then(({unique, nurseCounts}: any)=> {
            setProvidersActive(unique.length.toString() || '-')
            delete nurseCounts['NO IDENTIFICADO']
            let perNurse = Object.entries(nurseCounts)
            setServicesPerNurse(perNurse)
        })
        getTodayPromise(today).then((res: any[]) => {
            setPromiseToday(res.length.toString() || '-')
            setTodayDetails({
                portalCancel: res?.filter(el => el.status === "CANCEL").length,
                absent: res?.filter(el => el.status === "ABSENT").length,
                rejectedOs:  res?.filter(el => el.status === "DONE:REJECTED").length, 
                rejectedPatient:  res?.filter(el => el.status === "CANCEL:REJECTED").length, 
                address: res?.filter(el => el.status === "CANCEL:ADDRESS").length,
                pending: res?.filter(el => ['PREASSIGN', 'ASSIGN:DELIVERY', 'ASSIGN:ARRIVED'].includes(el.status)).length,
                pendingValidate: res?.filter(el => ['PREASSIGN:PENDING', 'PREASSIGN:NOTFOUND', 'PREASSIGN:OS'].includes(el.status)).length,
                done: res?.filter(el => ['DONE', 'DONE:LAB'].includes(el.status)).length,
                purchases: res?.filter(el => el.origin === "patient").length,
                portal: res?.filter(el => el.origin === "portal").length,
                doctor: res?.filter(el => el.origin === "doctor").length
            })
        })
        getTodayPromise(yesterday).then((res: any[]) => {
            setYesterdayDetails({
                pending: res?.filter(el => ['PREASSIGN', 'ASSIGN:DELIVERY', 'ASSIGN:ARRIVED'].includes(el.status)).length,
                pendingValidate: res?.filter(el => ['PREASSIGN:PENDING', 'PREASSIGN:NOTFOUND', 'PREASSIGN:OS'].includes(el.status)).length,
            })
        })
        getFinishedToday().then((res: string | any[]) => setFinishedToday(res.toString() || '-'))
    }, [])

    function getProvidersOnTime(timeElapsed: any):any {
        let deliveryQuery = firestore
            .collection("events/requests/delivery")
            .where("dt_cierre", ">=", timeElapsed)
        let analysisQuery = firestore
            .collection("events/requests/analysis")
            .where("dt_cierre", ">=", timeElapsed)
        return new Promise(async (resolve, reject) => {
            let unique: any[] = []
            let all:any[] = []
            await deliveryQuery.get()
                .then(snap => {
                    snap.forEach(res => {
                        let provider = {
                            fullname: res.data().delivery?.fullname_nurse ? res.data().delivery?.fullname_nurse?.toUpperCase() : 'NO IDENTIFICADO',
                            uid: res.data().delivery?.uid_nurse,
                            uid_remis: res.data().delivery.uid_delivery
                        }
                        if(provider.fullname === '') provider.fullname = 'NO IDENTIFICADO'
                        if (provider.fullname && !unique.find((d) => d.fullname === provider.fullname)) {
                            unique.push(provider)
                        }
                        all.push(provider)
                    });
                })
                .catch(err => reject(err))
                let nurseCounts:any = {}
                all.forEach(function(x:any):any { nurseCounts[x.fullname] = (nurseCounts[x.fullname] || 0)+1; })
            return resolve({unique, nurseCounts})
        });
    }

    function getTodayPromise(day:string):any {
        let deliveryQuery = firestore
            .collection("events/requests/delivery")
            .where("promise", "==", day)
        return new Promise(async (resolve, reject) => {
            let allServices: any[] = []
            await deliveryQuery.get()
                .then(snap => {
                    snap.forEach(res => {
                        let status = res.data().status
                        let origin = res.data().origin
                        if (!status.includes('FREE') && !status.includes('DONE:OUT_OF_RANGE')) {
                            allServices.push({status, origin})
                        }
                    });
                })
                .catch(err => reject(err));
            return resolve(allServices);
        });
    }

    function getFinishedToday():any {
        let deliveryQuery = firestore
            .collection("events/requests/delivery")
            .where("dt_cierre", ">=", moment().set({hour:0,minute:0,second:0,millisecond:0}).toDate())
        return new Promise(async (resolve, reject) => {
            const pending: string[] = []
            const query = await deliveryQuery.get()
            query.forEach((el) => {
                if(['DONE:RESULT', 'DONE', 'DONE:LAB'].includes(el.data().status)) {
                    pending.push(el.ref.id)
                }
            })
            return resolve(pending.length);
        });
    }

    return <div className="statistics__container">
            <h2>Hisopados a domicilio {moment().format('DD/MM/YYYY')}</h2>
            <div className="statistics__general">
                <StatCard text="Cargados para hoy" number={promiseToday} forceClass="sixth" />
                <StatCard text="Pendientes día anterior" number={yesterdayDetails.pending} forceClass="first" />
                <StatCard text="Hisopados finalizados" number={finishedToday} forceClass="fifth" />
                <StatCard text="% completado ayer + hoy" 
                    number={(Math.floor(100 - parseInt(todayDetails.pending + yesterdayDetails.pending) * 100 / (parseInt(promiseToday) + parseInt(yesterdayDetails.pending))  || 0).toString())} forceClass="second" />
                <StatCard text="% completado hoy"
                    number={(Math.floor(100 - parseInt(todayDetails.pending || 0) * 100 / parseInt(promiseToday) || 0).toString())} forceClass="second" />
                <StatCard text="Equipos trabajando" number={providersActive} forceClass="fourth" />
                <StatCard text="Equipos trabajando (2 hs)" number={providersActiveNow} forceClass="third" />
            </div>
            <Spacer direction='vertical' value="16px" />
            <Title hierarchy='2' text="Por origen" />
            <div className="statistics__general">
                <StatCard text="Comprados en la app" number={todayDetails.purchases} forceClass="sixth" />
                <StatCard text="Cargados por portal" number={todayDetails.portal} forceClass="second" />
                <StatCard text="Cargados por consulta médica" number={todayDetails.doctor} forceClass="third" />
            </div>
            <Spacer direction='vertical' value="16px" />
            <Title hierarchy='2' text="Por estado" />
            <div className="statistics__general">
                <StatCard text="Pendientes" number={todayDetails.pending} forceClass="first" />
                <StatCard text="Cancelados desde el portal" number={todayDetails.portalCancel} forceClass="third" />
                <StatCard text="Ausentes" number={todayDetails.absent} forceClass="fourth" />
                <StatCard text="Domicilio inexistente" number={todayDetails.address} forceClass="fifth" />
                <StatCard text="Rechazados por el paciente" number={todayDetails.rejectedPatient} forceClass="second" />
                <StatCard text="Pendientes de aprobación" number={todayDetails.pendingValidate} forceClass="firsth" />
                <StatCard text="Rechazados por la obra social" number={todayDetails.rejectedOs} forceClass="second" />
            </div>
        <div>
            <Spacer direction='vertical' value="16px" />
            <Title hierarchy='2' text="Servicios por enfermero" />
            <ul>
                {servicesPerNurse.map((res: (string | number | boolean | {} | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactNodeArray | React.ReactPortal | null | undefined)[]) =>{
                    return <li key={Math.random()}>{res[0]} - {res[1]}</li>
                })}
            </ul>
            <Spacer direction='vertical' value="16px" />
        </div>
    </div>
}

export default Delivery