import React, {useState} from 'react';
import {useParams} from 'react-router-dom'
import {Button, Row, Spacer} from '@umahealth/occipital-ui'
import Header from '../Header';
import Delivery from './Delivery'
import OnSite from './OnSite'
import '../Module/styles/index.scss';
import './styles/Stats.scss';

const Stats = () => {
    const { service } = useParams<any>()
    const [serv, setServ] = useState<string>(service)

    return (
        <div>
            <Header />
            <Spacer direction='vertical' value="20px" />
            <Row>
                <Spacer value="10px" />
                <Button 
                    action={() => setServ('onsite')}
                    label="Presenciales"  />
                <Spacer value="20px" />
                <Button  
                    action={() => setServ('delvery')} 
                    label="A domicilio" />
            </Row>
            {serv === 'onsite' ? <OnSite /> : <Delivery />}
        </div>
    )
}

export default Stats