import React, { useState, useEffect, useRef, useMemo } from 'react'
import './Table.scss'
import { FaDownload, FaPencilAlt } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import Pagination from '../../../GeneralComponents/Pagination'
// import ExportToExcel from '../../../GeneralComponents/exportToExcel/ExcelExport'
import db from '../../../../config/db'
import { SET_NURSES } from '../../../../store/types/patientsTypes'
import { Button } from '@umahealth/occipital-ui'
import axios from 'axios'
import { update_stock } from '../../../../config/endpoints'

const EnfermerosTable:React.FC = () => {
    const dispatch = useDispatch()
    const { patients, searchedItem, nurses } = useSelector((state: any) => state.patients)
    const { user } = useSelector((state: any) => state.user)
    const [patientsFiltered, setPatientsFiltered] = useState<any[]>([])
    const [currentPage, setCurrentPage] = useState<number>(1)
    const [CurrentNurses, setCurrentNurses] = useState<any>([])
    const [nursesState, setNursesState] = useState<any>([])
    const [stockToday, setStockToday] = useState<any>({})
    const paginate = (pageNumber: number): any => setCurrentPage(pageNumber);
    const usersPerPage = 25
    const usersFromPharmacy = ['hrasic@emergencias.com.ar', 'scobiella@emergencias.com.ar', 'maltamiranda@emergencias.com.ar', 'admin@uma-health.com']
    const isPharmacy = usersFromPharmacy.includes(user.email)

    useEffect(() => {        
        db.firestore()
            .doc(`services/stock/delivery_laboratory/${moment().format('YYYYMMDD')}`)
            .onSnapshot((snap:any) => {
                if(!snap.exists) return 
                const data = snap.data()
                setStockToday(data)
            })
        dispatch({ type: 'SET_SEARCHED_ITEM', payload: '' })
    }, [])

    useEffect(() => {
        if (searchedItem) {
            const filteredPatientsFromSearchInput = filterPatientsFromSearchInput(searchedItem, patients)
            setPatientsFiltered(filteredPatientsFromSearchInput)
        } else {
            setPatientsFiltered(patients)
        }
    }, [patients, searchedItem])

    const filterPatientsFromSearchInput = (text: string, patients: any[]) => {
        return patients.filter(el => {
            return el?.data?.patient?.fullname?.toLowerCase()?.includes(text) || el?.data?.patient?.dni?.includes(text) || el?.data?.payment?.status === text || el?.data?.patient?.cm === text
        })
    }

    const processData = (nursesData:any) => { 
        let anotherNurses = [] as any
        let withoutLastService = [] as any
        nursesData.forEach((nurse:any) => {  
            let n = {...nurse}
            let serviceshours = [] as any
            patients.forEach((el: any) => {
                let service = el.data
                if(service.delivery?.cuit_nurse === n.cuit){
                    if(service.dt_create !== ""){
                        if(service.dt_cierre){
                            const m = moment(service.dt_cierre?.toDate())
                            serviceshours.push({
                                time: m.format('HH:mm DD/MM/YYYY'),
                                time_formated: m.format('YYYYMMDDHHmm')
                            })
                        }
                        n['status'] = "Servicio en curso"
                        if(["DONE", "PENDING:RESULT", "DONE:RESULT", 'CANCEL', 'ABSENT'].includes(service.status)) {
                            n['status'] = "FREE"
                        }
                    }
                }                        
            })
            serviceshours.sort((a:any, b:any) => b?.time_formated - a?.time_formated)
            n['last_service'] = serviceshours[0]?.time
            n['last_service_formated'] = serviceshours[0]?.time_formated
            if (n.last_service) {
                anotherNurses.push(n)
            } else {
                withoutLastService.push(n)
            }
        })
        anotherNurses.sort((a:any, b:any) => parseInt(b.last_service_formated) - parseInt(a.last_service_formated))        
        setNursesState([...anotherNurses, ...withoutLastService])
    }

    useEffect(() => {
        setCurrentPage(1)
    }, [patientsFiltered])

    useEffect(() => {
        const indexOfLastUser = currentPage * usersPerPage;
        const indexOfFirstUser = indexOfLastUser - usersPerPage;
        setCurrentNurses(nursesState.slice(indexOfFirstUser, indexOfLastUser))
    }, [nursesState, currentPage])

    useEffect(() => {
        if(nurses.length === 0){
            db.firestore()
                .collection('providers')
                .where('rol', 'in', ['ENFERMERO', 'TECNICO', 'REMIS'])
                .get()
                .then((snap) => {
                    const temp = snap.docs.map(el => {
                        const data = el.data()
                        return {...data, cuit: data.cuit, fullname: data.fullname, status: data.state, last_service: '-'}
                    })
                    const nurses = temp.map((el:any) => {
                        return {...el, cuit_delivery: temp.find((prov:any) => prov.uid === el.linked_uid ||prov.cuit === el.linked_uid || prov.uid === el.linked_cuit)?.cuit || '-'}
                    })
                    dispatch({type: SET_NURSES, payload: nurses})
                    processData(nurses)
                })
        } else {
            processData(nurses)
        }
    }, [])

    const updateStock = async (cuit:string) => {
        let stock = prompt("Ingrese el stock:") as any;
        while (!/^[0-9]+$/.test(stock)) {
            if(!stock) return
            alert("Debe ingresar sólo numeros");
            stock = prompt("Ingrese el stock: ") as any;
        }
        stock = parseInt(stock)
        await axios.patch(update_stock, {cuit, stock})
    }

    const renderServicesDoneToday = (cuit:string) => {
        return patients.filter((el:any) => el?.data?.delivery?.cuit_nurse === cuit && (el?.data?.service === 'PCR LABORATORIO' ? ['DONE', 'DONE:LAB'].includes(el?.data?.status) : ['DONE', 'DONE:LAB', 'DONE:RESULT'].includes(el?.data?.status)) && moment(el?.data?.dt_cierre?.toDate()).format('YYYYMMDD') === moment().format('YYYYMMDD')).length
    }

    const renderRow = (n:any) => (
        <div className={`row tc ${isPharmacy && 'stock'}`} key={n.id}>
            <div className="col">
                <p>{n.cuit}</p>
            </div>
            <div className="col">
                <p>{n.fullname}</p>
            </div>
            <div className="col">
                <p>{n.cuit_delivery}</p>
            </div>
            <div className="col">
                <p>{n.status}</p>
            </div>
            <div className="col">
                <p>{n.last_service}</p>
            </div>
            {isPharmacy && (
                <>
                    <div className="col">
                        <p>{renderServicesDoneToday(n.cuit)}</p>
                    </div>
                    <div className="col">
                        {stockToday[n.cuit] ? 
                            <p>{stockToday[n.cuit]}</p> 
                            :
                            <Button
                                action={() => updateStock(n.cuit)}
                                type="text"
                            >
                                Cargar stock
                            </Button>
                        }
                    </div>
                    <div className="col">
                        <p>{stockToday[n.cuit] ? stockToday[n.cuit] - renderServicesDoneToday(n.cuit) : ' - ' }</p>
                    </div>
                </>
            )}
        </div>
    )


   
    return (
        <section className='enfermeros_table'>
            <div className="table">
                <div className={`row th ${['Call Center Operador', 'Comercial', 'Medicina Laboral',].includes(user?.rol) ? 'no_edit' : ''} ${['ADMINISTRATIVE', 'Comercial', 'Medicina Laboral', 'Operaciones Supervisor', 'Jefe Operaciones'].includes(user?.rol) ? 'ceco' : ''} ${isPharmacy && 'stock'}`}>
                    <div className="col">
                        <p className="title">Cuit</p>
                    </div>
                    <div className="col">
                        <p className="title">Nombre</p>
                    </div>
                    <div className="col">
                        <p className="title">Conductor asociado (cuit)</p>
                    </div>                                        
                    <div className="col">
                        <p className="title">Estado actual</p>
                    </div>
                    <div className="col">
                        <p className='title'>Ùltimo servicio</p>
                    </div>
                    {isPharmacy && (
                        <>
                            <div className="col">
                                <p className='title'>Realizados en el día</p>
                            </div>
                            <div className="col">
                                <p className='title'>Stock entregado</p>
                            </div>
                            <div className="col">
                                <p className='title'>Stock restante</p>
                            </div>
                        </>
                    )}
                </div>   
                {CurrentNurses
                    .map((n: any) => renderRow(n))
                }                
            </div>
            <Pagination usersPerPage={usersPerPage} totalUsers={CurrentNurses.length} paginate={paginate} currentPage={currentPage} />
        </section>
    )
}

export default EnfermerosTable