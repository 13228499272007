import _ from 'lodash';

export default function ValidateDates(clonedTime: any) {
    let _isDaytimeValid = '';
    try {
        /* Validate Day is not greater than 2 for each day of the week */
        const tempObjValidate: any = {};
        clonedTime.forEach(function (item: any) {
            const getDay: any = item.day || item.date;
            tempObjValidate[getDay] = (tempObjValidate[getDay] || 0) + 1;
        })
        const valuesToArray: any = Object.values(tempObjValidate)

        for (let i = 0; i < valuesToArray.length; i++) {
            if (valuesToArray[i] > 2) {
                _isDaytimeValid = 'No se pueden seleccionar más de 2 días iguales';
                break;
            }
        }
        /* Validate Date is greater than today and the hours greater than now */
        if (!_isDaytimeValid && clonedTime[0].date) {
            clonedTime.map(function (item: any) {

                const fechaIngresada = new Date(item.date);
                const fechaDeHoy = new Date();
                //seteamos los minutos y horas de a fecha ingresada a la hroa actual para compararla
                fechaIngresada.setMinutes(fechaDeHoy.getMinutes() + fechaIngresada.getTimezoneOffset() + 1);
                fechaIngresada.setHours(fechaDeHoy.getHours());

                const comparacionDeFechas = fechaIngresada.getTime() >= fechaDeHoy.getTime();

                const compAño = fechaIngresada.getFullYear() === fechaDeHoy.getFullYear();
                const compMes = fechaIngresada.getMonth() === fechaDeHoy.getMonth();
                const compFecha = fechaIngresada.getDate() === fechaDeHoy.getDate();

                let hourSet = item.hourFrom.replace(/"/g, '');
                hourSet = hourSet.replace(/:\d\d/g, '');
                hourSet = parseInt(hourSet)


                if (!comparacionDeFechas) {
                    _isDaytimeValid = 'Debe ingresar una fecha igual o posterior a hoy';
                }

                if ((compAño && compMes && compFecha) && (hourSet <= fechaDeHoy.getHours())) {
                    _isDaytimeValid = 'Elija un horario con al menos una hora de antelación';
                }
                return
            }
            )
        }
        /* Validate if HourUntil is greater than HourFrom -- there are problems with this validation***  */
        if (!_isDaytimeValid) {
            clonedTime.map(function (item: any) {
                let getFrom
                let getUntil
                if (item.hourFrom) {
                    getFrom = item.hourFrom.split(':')
                }
                if (getUntil && getUntil[0] !== '00') {
                    if (getFrom[0] === getUntil[0]) {
                        _isDaytimeValid = 'Debe haber por lo menos una hora de diferencia entre horarios';
                    } else if (getFrom[0] > getUntil[0]) {
                        _isDaytimeValid = 'El valor "desde" no puede ser superior al valor "hasta"';
                    }
                }
                return
            })
        }

        if (!_isDaytimeValid) {
            clonedTime.map(function (item: any) {
                let getFrom = "", getUntil = ""
                if (item.hourFrom !== undefined) {
                    getFrom = item.hourFrom.split(':')
                }
                if (item.hourUntil !== undefined) {
                    getUntil = item.hourUntil.split(':')
                }
                if (getUntil && getUntil[0] === '00') {
                    _isDaytimeValid = 'El valor hasta no puede ser las 00 del día siguiente'
                }
                else if (
                    getFrom[0].length === 1 ||
                    getFrom[1].length === 1
                ) {
                    _isDaytimeValid = 'Complete la hora.'
                }
                return
            })
        }

        /* Validate Múltiple Hours in one Day */
        if (!_isDaytimeValid) {
            const daysArray = ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'];
            daysArray.forEach(function (eachDay) {
                const tempDayGather: any[] = [];
                clonedTime.forEach(function (item: any) {
                    if (item.day === eachDay) {
                        tempDayGather.push(item);
                        if (tempDayGather.length > 1) {
                            const hourFromArray: any[] = []
                            const hourUntilArray: any[] = []
                            let untilIndex = 0
                            let fromIndex = 1

                            const orderedArray = _.orderBy(tempDayGather, 'hourFrom', 'asc');
                            orderedArray.forEach(function (item) {
                                hourFromArray.push(item.hourFrom);
                                hourUntilArray.push(item.hourUntil);
                            })

                            for (let i = 0; i < hourFromArray.length; i++) {
                                if (hourUntilArray[untilIndex] >= hourFromArray[fromIndex]) {
                                    _isDaytimeValid = 'Los horarios no pueden cruzarse en un mismo día';
                                }
                                untilIndex = untilIndex + 1;
                                fromIndex = fromIndex + 1;
                            }
                        }
                    }
                })
            })
        }
        if (!_isDaytimeValid) {
            for (let i = 0; i < clonedTime.length; i++) {
                const day = clonedTime[i];
                if ((day.hourFrom === "") || (day.hourUntil === "")) {
                    _isDaytimeValid = "No pueden haber horarios vacíos"
                }
            }
        }
    } catch (error) {
        console.error(error)
    }
    return _isDaytimeValid;
}

export function validateCountry(country: any, recipeCountries: any) {
    if (!country) {
        return true;
    } else if (country[0] === '' || country.length === 0) {
        return true;
    } else if (recipeCountries?.some((i: any) => country.includes(i))) {
        return true;
    } else {
        return false;
    }
}

export const validateName = (firstname: any) => {
    if (firstname === '' || !isNaN(firstname) || /^[a-zA-Z ]{2,30}$/.test(firstname) === false) {
        return false;
    } {
        return true;
    }
}

export const validateLastName = (lastname: any) => {
    if (lastname === '' || !isNaN(lastname) || /^[a-zA-Z ]{2,30}$/.test(lastname) === false) {
        return false;
    } {
        return true;
    }
}

export const validateEmail = (email: any) => {
    if (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email) === false) {
        return false;
    } {
        return true;
    }
};

export const validateDni = (dni: any) => {
    if ((/\d{8}/gm.test(dni) === false) || isNaN(dni) || dni === '') {
        return false;
    } {
        return true;
    }
};


export const checkNum = (phone: any) => {
    let completeNumber = validateTel(phone);
    if(!completeNumber) return '';
    if(phone === '') return '';
    let validPhone = `${parseInt(completeNumber)}`
    if (validPhone.slice(0, 2) === '54') {
        if (validPhone.slice(2, 4) === '15' || validPhone.slice(2, 4) === '11' && validPhone.length < 13) {
            validPhone = `${validPhone.slice(0, 2)}911${validPhone.slice(4, validPhone.length)}`
        } else if (validPhone.slice(2, 4) === '11' && validPhone.length < 13) {
            validPhone = `${validPhone.slice(0, 2)}9${validPhone.slice(2, validPhone.length)}`
        } else if (validPhone.slice(2, 4) !== '11' && validPhone.slice(2, 4) !== '15' && validPhone.length >= 10 && validPhone.length <= 12) {
            validPhone = `${validPhone.slice(0, 2)}9${validPhone.slice(2, validPhone.length)}`
        }

    }
    return validPhone;

}

const validateTel = (value: any) => {
    if(/^(\(54\))?[\d -]{10,20}$/.test(value)) {
        let newValue = value;
        if(value.slice(0, 3) === '011') {
            newValue = value.slice(1, value.length);
        }
        if (newValue.slice(0, 2) !== '54') {
            newValue = `549${newValue} `
        }
        return newValue;
    } else {
        return false;
    }
}

export const validateDob = (dob: any) => {
    if (dob === '') {
        return false;
    } {
        return true;
    }
}


export const validateSex = (sex: any) => {
    if (sex?.includes('Elija') || sex === '') {
        return false;
    } {
        return true;
    }
}

export const validateRol = (rol: any) => {
    if (rol?.includes('Elija') || rol === '') {
        return false;
    } {
        return true;
    }
}

export const validateCuit = (cuit: any) => {
    if ((/\d{11}/gm.test(cuit) === false) || isNaN(cuit) || cuit === '') {
        return false;
    } {
        return true;
    }
};

export const validateMatricula = (matricula: any) => {
    if (matricula === '' || (/\d{6}/gm.test(matricula) === false) || isNaN(matricula)) {
        return false;
    }
    return true;
}

export const validateService = (service: any) => {
    if (service?.includes('Elija') || service === '') {
        return false;
    } {
        return true;
    }
}

export const validateSpecialty = (specialty: any) => {
    if (specialty?.includes('Elija') || specialty === '') {
        return false;
    } {
        return true;
    }
}

