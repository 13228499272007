import * as patients from '../types/patientsTypes'
import { Action } from '../../types/interfaces'

const initialState = {
    patients: [],
    patientsFiltered: [],
    nurses: [],
    searchedItem: '',
}

export default function consultorioReducers(state = initialState, action: Action) {
    switch (action.type) {
        case patients.SET_PATIENTS:
            return { ...state, patients: action.payload }
        case patients.SET_PATIENTS_FILTERED:
            if( action.payload.key === 'noFilters' ) {
                return { ...state, patientsFiltered: { [action.payload.key]: action.payload.patientFiltered} }
            } else {
                return { ...state, patientsFiltered: {...state.patientsFiltered, ['noFilters']: [], [action.payload.key]: action.payload.patientFiltered} }
            }
        case patients.SET_NURSES:
            return { ...state, nurses: action.payload }
        case patients.SET_SEARCHED_ITEM:
            return { ...state, searchedItem: action.payload }
        default:
            return state
    }
}