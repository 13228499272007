import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

function SearchBox({ map, mapApi, handleChangePlace, value }: { map: any, mapApi: any, handleChangePlace: any, value: any }) {
    const dispatch = useDispatch();
    let searchInput = useRef() as React.MutableRefObject<HTMLInputElement>;
    const selector = useSelector((state: any) => state?.delivery?.deliveryPatientAddress);
    const [counter, setCounter] = useState(true)

    useEffect(() => {
        if (counter && value) {
            dispatch({ type: 'SET_DELIVERY_PATIENT_ADDRESS', payload: value })
            setCounter(false)
        }
    }, [value])

    const handleAddressChange = (e: any) => {
        dispatch({ type: 'SET_DELIVERY_PATIENT_ADDRESS', payload: e.target.value })
        let searchBox = new mapApi.places.Autocomplete(searchInput);
        searchBox.setFields(['place_id', 'name', 'geometry.location', 'formatted_address', 'address_components']);
        searchBox.bindTo('bounds', map);
        searchBox.addListener('place_changed', function () {
            let place = searchBox.getPlace();
            let latlng = {
                lat: parseFloat(place.geometry?.location.lat()),
                lng: parseFloat(place.geometry?.location.lng()),
            }
            handleChangePlace(place);
            dispatch({ type: 'SET_MARKER_INPUT_ADDRESS', payload: latlng })
        });
    }

    return (
        <input
            value={selector}
            ref={(ref: any) => { searchInput = ref; }}
            onChange={(e) => handleAddressChange(e)}
            onClick={() => dispatch({ type: 'SET_DELIVERY_PATIENT_ADDRESS', payload: "" })}
            type='text'
            placeholder="Dirección"
            autoComplete="nooo"
        />
    )
};

export default SearchBox;