import UMAlogo from '../../../assets/logo.png'
import EMERlogo from '../../../assets/logoemer.png'
import UNSAMlogo from '../../../assets/logounsam.png'

export const resultsHelper = {
        'PCR Express': {
            POSITIVE: 'DETECTABLE',
            NEGATIVE: 'NO DETECTABLE'
        },
        'PCR EXPRESS': {
            POSITIVE: 'DETECTABLE',
            NEGATIVE: 'NO DETECTABLE'
        },
        'PCR LABORATORIO': {
            POSITIVE: 'DETECTABLE',
            NEGATIVE: 'NO DETECTABLE'
        },
        'Antígenos': {
            POSITIVE: 'POSITIVO',
            NEGATIVE: 'NEGATIVO'
        },
        'HISOPADO': {
            POSITIVE: 'POSITIVO',
            NEGATIVE: 'NEGATIVO'
        }
    } as any

export const methodHelper = {
        es: {
            'VACUNA ANTIGRIPAL': 'VACUNA TETRAVALENTE',
            'HISOPADO': 'Inmuno-cronomatográco (PANIBO™ COVID-19 AG RAPID TEST DEVICE)',
            'Antígenos': 'Inmuno-cronomatográco (PANIBO™ COVID-19 AG RAPID TEST DEVICE)',
            'PCR Express': 'RT - PCR',
            'PCR EXPRESS': 'RT - PCR',
            'PCR LABORATORIO': 'RT - PCR'
        },
        en : {
            'Antígenos': 'Immune-chronatographic (PANIBO™ COVID-19 AG RAPID TEST DEVICE)',
            'PCR Express': 'RT - PCR',
            'PCR EXPRESS': 'RT - PCR',
            'PCR LABORATORIO': 'RT - PCR'
        }
    } as any

export const langHelper = {
        'PCR Express': ['es', 'en'],
        'PCR EXPRESS': ['es', 'en'],
        'PCR LABORATORIO': ['es', 'en'],
        'Antígenos': ['es'],
        'HISOPADO': ['es'],
        'VACUNA ANTIGRIPAL': ['es']
    } as any   

export const validatorHelper = {
        'HISOPADO': 'Dr. Victor D. Monopoli Bioquímico  (MN: 12568 - MP: 5612)',
        'VACUNA ANTIGRIPAL': 'Lic. Claudio Ferreyra (MN: 63874)',
        'PCR Express': 'Dr. Victor D. Monopoli Bioquímico  (MN: 12568 - MP: 5612)',
        'PCR EXPRESS': 'Dr. Victor D. Monopoli Bioquímico  (MN: 12568 - MP: 5612)',
        'PCR LABORATORIO': 'Dr. Victor D. Monopoli Bioquímico  (MN: 12568 - MP: 5612)',
        'Antígenos': 'Dr. Victor D. Monopoli Bioquímico  (MN: 12568 - MP: 5612)'            
    } as any

export const logoHelper = {
        'HISOPADO': [UMAlogo, EMERlogo],
        'VACUNA ANTIGRIPAL': [UMAlogo, EMERlogo],
        'PCR Express': [UMAlogo, EMERlogo],
        'PCR EXPRESS': [UMAlogo, EMERlogo],
        'PCR LABORATORIO': [UMAlogo, EMERlogo, UNSAMlogo],
        'Antígenos': [UMAlogo, EMERlogo],
    } as any
