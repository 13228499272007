import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import db from './config/db'
export const AuthContext = React.createContext()

const AuthProvider = ({ children }) => {
    const dispatch = useDispatch()
    const auth = db.auth()
    const firestore = db.firestore()
    const [currentUser, setCurrentUser] = useState(() => db.currentUser)
    const { user, permissions } = useSelector(state => state.user)

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(setCurrentUser)
        if(currentUser) {
            dispatch({ type: 'SET_CURRENT_USER', payload: currentUser })

            if(!user?.rol && !permissions?.admin?.module) {
                firestore.collection('corporate_users').doc(currentUser.uid).get()
                    .then(doc => {
                        if (doc.exists) {
                            dispatch({ type: 'SET_USER', payload: doc.data() })
                        }
                    })
                    .catch(e => console.error(e))

                firestore.collection('corporate_users').doc(currentUser.uid).collection('features').doc('hisopados').get()
                    .then(doc => {
                        if (doc.exists) {
                            dispatch({ type: 'SET_USER_PERMISSIONS', payload: doc.data() })
                        }
                    })
                    .catch(e => console.error(e))
            }
        }
        return () => unsubscribe()
    }, [currentUser, auth, dispatch, firestore, permissions?.admin?.module, user?.rol])

    return (
        <AuthContext.Provider value={{ currentUser }}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthProvider