import { Link } from 'react-router-dom';
import Header from '../Header';
import errorImage from './assets/Error.png';
import './styles/index.scss';

export const NotFound = () => {
    return (
        <>            
            <Header />
            <div className="div">
                <img src={errorImage} alt='imagen de error' />
                <h1 className="title">Algo salió mal</h1>
                <p className="text">No pudimos encontrar la página que estabas buscando.</p>
                <Link to='/' className="inicio">Ir a inicio</Link>
            </div>
        </>
    )
}