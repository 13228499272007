import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import CasesTable from '../../GeneralComponents/Table'
import Modal from '../../GeneralComponents/Modal'
import Header from '../../Header'
import db from '../../../config/db'
import { useHistory, useLocation } from 'react-router'
import CustomUmaLoader from '../../GeneralComponents/Loader'
import HisopadosStatsByStatus from './Stats/ByStatus'
import moment from 'moment'
import EnfermerosTable from './enfermerosTable'
import CargarHisopado from './CargarHisopado'
import { SET_MODAL, SET_MODAL_COMPONENT } from '../../../store/types/utilsTypes'
import '../../../styles/Hisopados.scss'

let obs = () => {}
const HisopadosDomicilio:React.FC = () => {
    const history = useHistory()
    const dispatch = useDispatch()
    const [loader, setLoader] = useState(true)
    const firestore = db.firestore()
    const { modal, modalComponent } = useSelector((state: any) => state.utils)
    const { user, currentUser } = useSelector((state: any) => state.user)
    const { searchedItem, patients, patientsFiltered} = useSelector((state: any) => state.patients)
    const [table, setTable] = useState<string>('casos')
    const [filteredPatients, setFilteredPatients] = useState(false)
    const [statsFilter, setStatsFilter] = useState<any>('');
    const [dateToQuery, setDateToQuery] = useState<any>({type: 'promise',from: moment().subtract(1, 'day').format('YYYY-MM-DD'), to: moment().format('YYYY-MM-DD')});
    const [filter, setFilter] = useState<any>({type: 'DNI', value:''});
    const usersFromPharmacy = ['hrasic@emergencias.com.ar', 'scobiella@emergencias.com.ar', 'maltamiranda@emergencias.com.ar']
    const isPharmacy = usersFromPharmacy.includes(user.email)

    useEffect(() => {
        firestore.collection('parametros').doc('userapp').collection('analysis').doc('abbott').get()
            .then((doc: any) => {
                if (doc.exists) {
                    dispatch({ type: 'SET_CONSULTING_ROOMS', payload: doc.data().consulting_rooms })
                }
            })
            .catch(e => console.error(e))
    }, [])

    useEffect(() => {
        if (searchedItem !== 'PAYMENT:SUCCESS') {
            setFilteredPatients(false)
        }
    }, [searchedItem])

    const handleCloseModal = () => {
        dispatch({ type: 'RESET_MODAL' })
    }

    const handleFilterByPayment = (value : boolean) => {
        if(value) {
            dispatch({ type: 'SET_SEARCHED_ITEM', payload: '' })
            setFilteredPatients(false);
        } else {
            dispatch({ type: 'SET_SEARCHED_ITEM', payload: 'PAYMENT:SUCCESS' })
            setFilteredPatients(true);
        }
    }

    const snapFunction = (data:any) => {
      const arr_patients: any[] = []      
      if (user?.rol === 'Laboratorio') {
        data = data.filter((el:any) => el.service === 'PCR LABORATORIO')
      }
      if (user?.rol === 'Personal Médico') {
        data = data.filter((el:any) => el.service === 'PCR EXPRESS')
      }
      if (user?.corporate === 'DOSUBA') {
        data = data.filter((el:any) => el?.patient?.corporate === 'DOSUBA')
      }
      data.forEach((doc:any) => {
          if (['DONE', 'DONE:RESULT', 'DONE:LAB','DONE:REJECTED', 'PREASSIGN', 'CANCEL', 'ABSENT', 'ASSIGN:DELIVERY', 'ASSIGN:ARRIVED', 'PREASSIGN:PENDING', 'PREASSIGN:NOTFOUND', 'PREASSIGN:OS', 'CANCEL:REJECTED', 'CANCEL:ADDRESS'].includes(doc.status)) {
            arr_patients.push({
                id: doc.id,
                data: doc,
            });
          }
          if(doc.status === 'DONE:OUT_OF_RANGE' && doc.origin !== 'patient') {
            arr_patients.push({
              id: doc.id,
              data: doc,
            });
          }
      })

      let final_arr = arr_patients;
      if (
        ['Corporate'].includes(user?.rol)
      ) {
          final_arr = arr_patients.filter(
            (item) => item.data.patient.corporate === user.corporate || item.data.patient.os === user.corporate
          );
      } else {
          final_arr = arr_patients
      }
      dispatch({ type: "SET_PATIENTS", payload: final_arr });
      setLoader(false)
    }

    const listenEventsDelivery = (date:{to:string, from:string, type: string, lab: any}) => {
      try {      
        obs()  
        let query = firestore.collection('events/requests/delivery') as any
        if(user.rol === 'Comercial'){
          query = query.where('csvUploadedBy', '==', currentUser.uid)
        } else if (date.type === 'promise') {
          query = query.orderBy(date.type)
          .startAt(date.from)
          .endAt(date.to)
        } else if (date.type === 'lab_date_result') {
          query = query
            .where('lab.lab_date_result', '>=', new Date(moment(date.from, 'YYYY-MM-DD').toString()))
            .where('lab.lab_date_result', '<=', new Date(moment(date.to, 'YYYY-MM-DD').toString()))
        } else {
          query = query
            .where(date.type, '>=', new Date(moment(date.from, 'YYYY-MM-DD').toString()))
            .where(date.type, '<=', new Date(moment(date.to, 'YYYY-MM-DD').toString()))
        }
        obs = query.onSnapshot(
          (snap:any) => (
            snapFunction(snap.docs.map((el:any) => ({...el.data(), id: el.id})))     
        ), (err:any) => console.log(err))
        setLoader(false)
      } catch (error) {
        console.error(error)
      }
    }

    useEffect(() => {
        if(user.rol){
            try {
              listenEventsDelivery(dateToQuery)   
            } catch (e) {
              console.error(e)
              setLoader(false)
            }
        }
    }, [user.rol, dateToQuery.from, dateToQuery.to, dateToQuery.type])

    const handleLoadHisopado = () => {
        dispatch({type: SET_MODAL, payload: true})
        dispatch({type: SET_MODAL_COMPONENT, payload: <CargarHisopado serviceType='delivery'/>})
    }    

    useEffect(() => {
      if(filter.value === ''){
        dispatch({ type: 'SET_SEARCHED_ITEM', payload: '' })
        listenEventsDelivery(dateToQuery)
      }
    }, [filter.value])

    const handleSearch = async () => {
      if(!filter.type || !filter.value) return 
      let data = null    
      if(filter.type === 'Cualquier dato'){
        obs()
        return dispatch({ type: 'SET_SEARCHED_ITEM', payload: filter.value.toLowerCase() })
      }   
      if(filter.type === 'DNI'){        
        data = (await firestore.collection('events/requests/delivery').where('patient.dni', '==', filter.value).get()).docs.map((el) => ({...el.data(), id:el.id}))
      } 
      if(filter.type === 'ID') {
        const document = await firestore.doc(`events/requests/delivery/${filter.value}`).get()
        if(document.exists){
          data = [{...document.data(), id: document.id}]
        }
      }
      if(!data || data.length === 0) return alert('No se encontraron servicios')
      obs()
      snapFunction(data)
    }

    const handleInputChange = ({target: {value, name}}:any) => {
      if(name === 'value' && (value.includes('_') || value.includes('-'))){
        setFilter({value, type: 'ID'})
      } else {
        setFilter({...filter, [name]:value})
      }
    }

    if(loader) {
      return <CustomUmaLoader />
    }
    
    return (
      <>
        <Header />
        <section className="hisopados">
          <div className="container">
            <div className="hisopados_header">
              <h1>Servicios a domicilio</h1>
              <div> 
                <span>Filtrar por: </span>
                <select name='type' value={filter.type} onChange={handleInputChange} id='filterType'>
                  <option value='DNI' >DNI (Más rápido, en todos los registros)</option>
                  <option value='ID' >ID (Más rápido, en todos los registros)</option>
                  <option value='Cualquier dato'>Nombre, DNI o ID (solo entre las fechas seleccionadas)</option>
                </select>
                <input
                  type="search"
                  name="value"
                  autoComplete="off"
                  placeholder={`Buscar servicio por ${filter.type}`}
                  className="search_input"
                  onChange={handleInputChange}
                />
                <button 
                  onClick={handleSearch}
                  disabled={!filter.value}
                >
                  Buscar
                </button>
                {
                  !['Corporate'].includes(user?.rol) &&
                  <button onClick={() => handleFilterByPayment(filteredPatients)}>
                    {!filteredPatients
                      ? "Ver solo pagos completados"
                      : "Limpiar filtro"}
                  </button>
                }
                {(['ADMINISTRATIVE', 'Comercial', 'Medicina Laboral', 'Operaciones Supervisor', 'Call Center Supervisor', 'Corporate', 'Jefe Operaciones'].includes(user?.rol) && user.corporate !== 'DOSUBA') && (
                    <button onClick={() => history.push('/carga-masiva?schema=delivery&redirect=/domicilio')}>
                        Cargar CSV
                    </button>
                )}
              </div>
            </div>
            <div className="anotherData">
              <div className="salesOfTheDay">
              {["ADMINISTRATIVE", "Operaciones Supervisor", "Operaciones Analista", "Call Center Supervisor", "Jefe Operaciones"].includes(user?.rol) && (
                  <>
                    <select onChange={e => setStatsFilter(e.target.value)}>
                      <option value={''}>Todos los servicios</option>
                      <option value={'HISOPADO'}>Antígenos</option>
                      <option value={'VACUNA ANTIGRIPAL'}>Vacuna Antigripal</option>
                      <option value={'PCR'}>PCR</option>
                      <option value={'PCR EXPRESS'}>PCR Express</option>
                      <option value={'PCR LABORATORIO'}>PCR Laboratorio</option>
                    </select>
                    <HisopadosStatsByStatus value={patients} filter={statsFilter}/>
                  </>
                )}
                  <ul>
                    {
                      !['DOSUBA'].includes(user?.corporate) &&
                      <>
                      {
                        !['Corporate', 'Lectura'].includes(user?.rol) &&
                        <>
                          <button onClick={() => setTable("casos")}
                          >
                            Ver tabla de casos
                          </button>
                          <a
                            href={process.env.PUBLIC_URL + "/domicilio-map"}
                            target="_blank"
                          >
                            <button>Ver mapa</button>
                          </a>
                        </>
                      }
                      {([
                        "ADMINISTRATIVE",
                        "Operaciones Analista",
                        "Operaciones Supervisor",
                        "Jefe Operaciones",                    
                      ].includes(user?.rol) || isPharmacy) && (
                        <button onClick={() => setTable("enfermeros")}>
                          Ver enfermeros
                        </button>
                      )}
                      </>
                    }
                      {[
                        "ADMINISTRATIVE",
                        "Comercial",
                        "Medicina Laboral",
                        "Operaciones Supervisor",
                        "Call Center Supervisor",
                        "Corporate",
                        "Jefe Operaciones",
                      ].includes(user?.rol) && (
                        <button onClick={handleLoadHisopado}>
                          Cargar servicio
                        </button>
                      )} 
                </ul>
              </div>
              <div className='filtrosDeQuery'>
                <span>Mostrando por fecha de </span>     
                <select 
                  value={dateToQuery.type}
                  onChange={(e) => {
                    if (e.target.value === 'lab_date_result') {
                      setDateToQuery({...dateToQuery, type: e.target.value, lab: 'lab'})
                    } else {
                      setDateToQuery({...dateToQuery, type: e.target.value })
                    }
                  }} 
                >
                  <option value='dt_cierre'>Cierre</option>
                  <option value='dt_create'>Creación</option>
                  <option value='dt_assign'>Asignación</option>
                  <option value='dt_payment_success'>Pago</option>
                  <option value='dt_cancel'>Cancelación</option>
                  <option value='promise'>Promesa</option>
                  <option value='lab_date_result'>Resultado</option>
                </select>           
                <span>{dateToQuery.type !== 'promise' ? 'desde: ' : 'de: '}</span>
                <input onChange={(e) => setDateToQuery({...dateToQuery, from: e.target.value})} type='date' value={dateToQuery.from}></input>
                <span>hasta: </span>                
                <input onChange={(e) => setDateToQuery({...dateToQuery, to: e.target.value})} min={dateToQuery.from} type='date' value={dateToQuery.to}></input>
              </div>
            </div>
            {table === "casos" && <CasesTable />}
            {table === "enfermeros" && <EnfermerosTable />}
            {modal && <Modal close={handleCloseModal}>{modalComponent}</Modal>}
          </div>
        </section>
      </>
    );
}

export default HisopadosDomicilio