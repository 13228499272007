import React from 'react';
import ReactExport from "react-export-excel";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileCsv } from '@fortawesome/free-solid-svg-icons';
import { isEmpty } from 'lodash';
const ExportToExcel = ({ btnStyle = '', element, arrayToExport = [], customSchema = [], tableName = '' }) => {
	const ExcelFile = ReactExport.ExcelFile;
	const ExcelSheet = ReactExport.ExcelFile.ExcelSbuttonheet;
	const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
	let keys;

	if (customSchema.length > 0) {
		// let normalize = false
		keys = [...customSchema];
		// let notNormalized = []
		let newArray = [...arrayToExport]
		//for para checkear las columnas que requieren data de objetos niveles adentro
		//y ejecuta la funcion renderToExcel que retorna lo que deberia mostrar en excel
		for (let i = 0; i < newArray.length; i++) {
			const el = {...newArray[i]};
			for (let j = 0; j < keys.length; j++) {
				const key = keys[j];				
				if(key.renderToExcel && typeof key.renderToExcel === 'function'){
					el[key.field] = key.renderToExcel(el)				
				} else if (key.render && typeof key.render(el) === 'string'){
					el[key.field] = key.render(el)
				} else if(key.field && key.field.includes('.')){
					let fields = key.field.split('.')
					let data = el
					fields.forEach((field) =>{
						if(data[field] && !isEmpty(data[field]))
							data = data[field] 
						else {
							data = '-'
						}
					})
					el[key.field] = data;
				} else if(!el[key.field]){
					el[key.field] = '-'
				}
			}	
			newArray[i] = el		
		}
		// dejo esto comentado POR LAS DUDAS
		//
		// //for que chequea que ninguna columna tenga campo undefined, sino exporta el excel vacio
		// for (let i = 0; i < keys.length; i++) {
		// 	normalize = newArray?.every?.((el) => el[keys[i].field] !== undefined)
		// 	if (!normalize) {
		// 		notNormalized.push(keys[i].field)
		// 		keys.splice(i, 1)
		// 	}
		// }
		// //for que completa esos campos undefined con un guion.
		// //estos dos ultimos ciclos podrian ser uno pero no tengo ganas de pensar ahora
		// for (let i = 0; i < newArray.length; i++) {
		// 	const el =  newArray[i];
		// 	for (let j = 0; j < notNormalized.length; j++) {
		// 		const key = notNormalized[j];
		// 		el[key] = '-'							
		// 	}			
		// }
		return (
			<ExcelFile element={element || <button className={btnStyle}><FontAwesomeIcon icon={faFileCsv} /></button>}>
				<ExcelSheet data={newArray} name={tableName}>
					{keys.map((key) => <ExcelColumn label={`${key.title.toUpperCase()}`} value={`${key.field}`} />)}
				</ExcelSheet>
			</ExcelFile>
		)
	} else {
		keys = Object.keys(arrayToExport[0]);
		return (
			<ExcelFile element={element || <button className={btnStyle}><FontAwesomeIcon icon={faFileCsv} /></button>}>
				<ExcelSheet data={arrayToExport || []} name={tableName || "default"}>
					{keys.map((key) => <ExcelColumn label={`${key.toUpperCase()}`} value={`${key}`} />)}
				</ExcelSheet>
			</ExcelFile>
		)
	}
}

export default ExportToExcel;