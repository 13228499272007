import moment from 'moment';
import React, {useState, useEffect, useMemo} from 'react';

const HisopadosStatsByStatus = ({value, filter}: any) => {
    const [counter, setCounter] = useState({done: 0, assign: 0, free: 0, preassign: 0})

    let filtered = value; 
    if(filter) {
        filtered = value.filter((services: any) => services.data.service === filter)
    }
 
    useEffect(() => {
        filtered.length > 0 && getTotals(filtered)
    }, [value, filter])

    
    async function getTotals(services:any) {
		let i = 0, j = 0, k = 0, l = 0
		services.forEach((val:any) => {
			val['data']['status'] === 'PREASSIGN' 
            && i++;
			val['data']['status'] === 'DONE:RESULT'
            && j++;
            ['ASSIGN:DELIVERY', 'ASSIGN:ARRIVED'].includes(val['data']['status'])
            && k++;
            val['data']['origin'] === 'patient'
			&& l++;
		});
        let counted = {
            preassign: i,
            done: j,
            assign: k,
            free: l
        }
            // let createdToday = snapshot.docs.map((doc: any) => ({...doc.data(), id: doc.id}))
            // let filteredByOrigin = createdToday.filter((s: any) => s.origin === 'patient')
            // counted.free = filteredByOrigin.length;
        setCounter(counted);
    }
    const preassing = useMemo(() => <li>Preasignados: {counter.preassign || 0}</li>, [counter.preassign])
    const inCourse = useMemo(() =>  <li>En curso: {counter.assign || 0}</li>, [counter.assign])
    const done = useMemo(() => <li>Finalizados: {counter.done || 0}</li>, [counter.done])
    const buyClicks = useMemo(() => <li>Clicks en Comprar: {counter.free || 0}</li>, [counter.free])

    return (
        <ul className="sales">
            {preassing}
            {inCourse}
            {done}
        </ul>
    )
}

export default HisopadosStatsByStatus;