import * as utils from '../types/utilsTypes'
import { Action } from '../../types/interfaces'

const initialState = {
    modal: false,
    modalData: {},
    consultingRooms: []
}

export default function consultorioReducers(state = initialState, action: Action) {
    switch (action.type) {
        case utils.SET_MODAL:
            return { ...state, modal: action.payload }
        case utils.SET_MODAL_DATA:
            return { ...state, modalData: action.payload }
        case utils.SET_MODAL_COMPONENT:
            return { ...state, modalComponent: action.payload}
        case utils.SET_CONSULTING_ROOMS:
            return { ...state, consultingRooms: action.payload }
        case utils.RESET_MODAL:
            return initialState
        default:
            return state
    }
}