export const mapConfig = (
    center: any = { lat: 0, lng: 0 },
    onClick: any = () => { },
    zoom: any = 15,
    yesIWantToUseGoogleMapApiInternals: any = true,
    options: any = {
        streetViewControl: false,
        disableDefaultUI: false,
        mapTypeControl: false,
        styles: [
            {
                featureType: 'poi',
                elementType: 'labels',
                stylers: [{ visibility: 'off' }],
            },
        ],
    },
    bootstrapURLKeys: any = {
        key: 'AIzaSyDLnpXWJx1qKAfHtTeYWa30b9jGH2GeXfs',
        libraries: ['places', 'geometry', 'drawing', 'visualization'],
        language: 'es',
    }
) => ({
    center,
    bootstrapURLKeys,
    yesIWantToUseGoogleMapApiInternals,
    zoom,
    options,
    onClick
});

export const currentPositionHandler = (position: any) => ({
    lat: position.coords.latitude,
    lng: position.coords.longitude,
});