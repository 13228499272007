import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import AuthProvider from './AuthProvider';
import { Provider } from 'react-redux';
import { store } from './store';
import { createBrowserHistory } from 'history';
import { Router } from 'react-router-dom';

ReactDOM.render(
  <React.StrictMode>
  <Provider store={store}>
    <Router history={createBrowserHistory({ basename: '/' })}>
      <AuthProvider>
        <App />
      </AuthProvider>
    </Router>
  </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
