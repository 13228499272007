import firebase from 'firebase';

const firestore = firebase.firestore();
const ref = firebase.storage().ref();

export async function putFileFB(file: any, fileName: any) {
    try {
        const res = await ref.child(fileName).put(file);
        const url = await res.ref.getDownloadURL();
        return url;
    } catch (error) {
        console.error(error);
    }
}