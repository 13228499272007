import * as delivery from '../types/deliveryTypes'
import { Action } from '../../types/interfaces'

const initialState = {
    addressLatLong: "",
    deliveryPatientAddress: "",
}

export default function deliveryReducers (state = initialState, action: Action) {
    switch (action.type) {
        case delivery.SET_ADDRESS_LAT_LONG:
            return { ...state, addressLatLong: action.payload };
        case delivery.SET_DELIVERY_PATIENT_ADDRESS:
            return { ...state, deliveryPatientAddress: action.payload };
        default:
            return state
    }
}