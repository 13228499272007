import axios from 'axios';
import React, { useCallback, useState } from 'react'
import { FaPaperPlane } from 'react-icons/fa';
import {mobility_batch_hisopados} from '../../../../config/endpoints'
import HisopadoCartItem from './hisopadoItem';
import '../../../../styles/delivery.scss';
import { useDispatch } from 'react-redux'
import swal from 'sweetalert';

const CargarHisopado: any = (props: any) => {
    const dispatch = useDispatch()
    const [validData, setValidData] = useState(true);
    const [hisopadosAdded, setHisopadosAdded] = useState([{
      assigned_status: 'PREASSIGN',
      destination: {
        user_address: '',
        user_floor: '',
        user_number: '',
        user_lat: '',
        user_lon: '',
        user_cp: '',
        user_region: '',
        user_locality: '',
        user_country: '',
        user_obs: '',
      },
      isOpen: true,
      patient: {
        title: 'Completar información',
        user: '',
        dni: '',
        ws: '',
        dob: '',
        sex: '',
        address: '',
        piso: '',
        depto: '',
        corporate: '',
      },
      promise: ''
    }])

    const checkValidData = useCallback(()=>{
      let isValidData;
      hisopadosAdded.map((data:any)=>{
          let lastLetters = data.patient.user_email.substr(-4)
          if(lastLetters[0] === '.' && lastLetters[1] === 'c' && lastLetters[3] === 'n') {
            isValidData = false
            return swal('Hay un error  de ortografía en la dirección de mail')
          }
          let entreArrobaYPunto = data.patient.user_email.split('@')[1]?.split('.')
          if(!entreArrobaYPunto) {
            isValidData = false
            return swal('Falta completar la dirección de email')
          }
          if(entreArrobaYPunto && entreArrobaYPunto.length < 2) {
            isValidData = false
            return swal('Falta completar la dirección de email')
          }
          if(['gmial', 'gmali', 'hotmial', 'hotmali', 'yaho'].includes(entreArrobaYPunto[0])) {
            isValidData = false
            return swal('Hay un error de ortografía en la dirección de mail')
          }
          if(!data.patient.user || !data.patient.dni || !data.destination?.user_address || !data.destination.user_lat || !data.destination.user_lon || !data.assigned_status || !data.patient.ws || !data.payment_reference) {
            isValidData = false
            swal("Omitió campos que son obligatorios o hay datos mal cargados. Reviselos por favor.")
          }
          else if(data.assigned_status === "DONE:RESULT" && (!data.result_lab || !data.cuit_nurse || !data.cuit_delivery)) {
            isValidData = false
            swal("Omitió campos que son obligatorios o hay datos mal cargados. Reviselos por favor.")
          }
          else {
            isValidData = true
          }
      })
      return isValidData
    }, [hisopadosAdded])

    const handleSendHisopados = async () => {
        if(checkValidData()){
            let headers = { 'Content-Type': 'application/json' };
            try {
                let res = await axios.post(mobility_batch_hisopados, {batch: hisopadosAdded, serviceType: props.serviceType}, {headers})
                if(res.data) {
                  dispatch({ type: 'SET_MODAL', payload: false })
                  alert(`${hisopadosAdded.length === 1 ? 'Cargado' : "Cargados"} con éxito`)
                }
              } catch(err: any) {
                if(err.response.data.status.err.includes('lat')) {
                  return alert('error al cargar la dirección')
                }
                alert(err)
            }            
        } else {
          console.log('error')
          return;
        }
    }

    const handleAddHisopado = () => {
        setHisopadosAdded([...hisopadosAdded, {
          assigned_status: 'PREASSIGN',
          destination: {
            user_address: '',
            user_floor: '',
            user_number: '',
            user_lat: '',
            user_lon: '',
            user_cp: '',
            user_region: '',
            user_locality: '',
            user_country: '',
            user_obs: '',
          },
          isOpen: true,
          patient: {
            title: 'Completar información',
            user: '',
            dni: '',
            ws: '',
            dob: '',
            sex: '',
            address: '',
            piso: '',
            depto: '',
            corporate: '',
          },
          promise: ''
        }])
      }


    const removeItem = (index:number) => {
        setHisopadosAdded(hisopadosAdded.filter((el, filter_index) => {return index !== filter_index}))
    }

    const handleChangeOfHisopado = (index:number, newHisopado:any) => {
        const previous = hisopadosAdded.filter((el, filter_index) => {return index !== filter_index})
        setHisopadosAdded([...previous, newHisopado])
    }

    return (
        <div className="loadHisopado__form HisopadoCart">
        <div className="HisopadoCart__container">
          <div className="HisopadoCart__header">
            <h1 className="HisopadoCart__title">Cargar servicio</h1>
          </div>
          <section className="HisopadoCart__userSection">
            <div className="HisopadoCart__users">
              {hisopadosAdded?.map((item, index) => (
                <HisopadoCartItem key={index} index={index} patient={item} onRemove={() => removeItem(index)} onChange={handleChangeOfHisopado} serviceType={props.serviceType}/>
              ))}
            </div>

            <div className="HisopadoCart__addContainer">
              {/* <span 
                onClick={handleAddHisopado}
                className="HisopadoCart__btnContainer">
                <button className="HisopadoCart__addBTn">+</button>
                <span className="HisopadoCart__addMsg">Agregar servicio</span>
              </span> */}

              <span 
                onClick={handleSendHisopados}
                className="HisopadoCart__btnContainer">
                <button className="HisopadoCart__btnSend"> <FaPaperPlane className="icon" /> </button>
                <span className="HisopadoCart__addMsg">Enviar</span>
              </span>
            </div>
          </section>
        </div>

        </div>
    )
}

export default CargarHisopado