import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { IoMdMenu } from 'react-icons/io';
import Sidebar from '../GeneralComponents/Sidebar';
import '../../styles/Header.scss';

const Header = (): React.ReactElement => {
    const [openMenu, setOpenMenu] = useState(false);

    return (
        <header className="header">
            <div className="container">
                <span className="menu" onClick={() => setOpenMenu(true)}>
                    <IoMdMenu />
                </span>
            </div>
            <div className={`menu_container ${openMenu ? 'open' : 'close'}`} onClick={() => setOpenMenu(false)}>
                <div className="menu_content">
                    <Sidebar />
                </div>
            </div>
        </header>
    )
}

export default Header;