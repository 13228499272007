import * as user from '../types/usersTypes'
import { Action } from '../../types/interfaces'

const initialState = {
    user: {},
    permissions: {},
    currentUser: {}
}

export default function consultorioReducers(state = initialState, action: Action) {
    switch (action.type) {
        case user.SET_USER:
            return { ...state, user: action.payload }
        case user.SET_USER_PERMISSIONS:
            return { ...state, permissions: action.payload }
        case user.SET_CURRENT_USER:
            return { ...state, currentUser: action.payload }
        default:
            return state
    }
}