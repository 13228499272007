import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useLocation } from "react-router"
import { SET_PATIENTS_FILTERED, SET_NURSES } from "../../../store/types/patientsTypes"
import moment from 'moment'
import db from "../../../config/db"
import Select from "react-select"

const filterPatients = (text: string, patients: any[]) => {
    return patients.filter(el => {        
        return JSON.stringify(el?.data)?.toLowerCase()?.includes(text.toLocaleLowerCase())
    })
}

export const OriginFilter = () => {
    const dispatch = useDispatch();
    const { patients } = useSelector((state: any) => state.patients)
    const handlerChange = ({target: {value}}:any) => {
        const patientFiltered = patients.filter((el:any) => el.data.origin === value)
        dispatch({type: SET_PATIENTS_FILTERED, payload: { patientFiltered, key: 'origin'} })
    }
    return (
        <select onChange={handlerChange}>
            <option value={''}>Select</option>
            <option value={'portal'}>PORTAL</option>
            <option value={'doctor'}>DOCTOR</option>
            <option value={'patient'}>PACIENTE</option>
        </select>
    )
}

export const ServiceFilter = () => {
    const dispatch = useDispatch()
    const { patients } = useSelector((state: any) => state.patients)
    const isDomicilio = useLocation().pathname.includes('domicilio')
    const screen = isDomicilio ? 'domicilio' : 'presenciales'
    const services:any = {
        presenciales: ['PCR Express', 'Antígenos', 'PCR LABORATORIO'],
        domicilio: ['Select', 'HISOPADO', 'VACUNA ANTIGRIPAL', 'PCR', 'PCR EXPRESS', 'PCR LABORATORIO']
    }
    const handlerChange = ({target: {value}}:any) => {
        const patientFiltered = patients.filter((el:any) => el.data.service === value)
        dispatch({type: SET_PATIENTS_FILTERED, payload: { patientFiltered, key: 'service'} })
    }
    return (
        <select defaultValue={'-Seleccione el servicio-'} onChange={handlerChange}>
            <option disabled>-Seleccione el servicio-</option>
            {services[screen].map((op:any) => <option value={op}>{op}</option>)}            
        </select>
    )
}

export const StatusFilter = ({ onFilter}: any) => {
    const dispatch = useDispatch()
    const { patients } = useSelector((state: any) => state.patients)
    const isDomicilio = useLocation().pathname.includes('domicilio')
    const screen = isDomicilio ? 'domicilio' : 'presenciales'
    const services:any = {
        presenciales: [
            {value: '', label: 'Select'},
            {value: 'FREE', label: 'Libre'},
            {value: 'PAYMENT', label: 'Pago'},
            {value: 'DONE', label: 'Realizado'},
            {value: 'DONE:RESULT', label: 'Realizado (con resultado)'},
            {value: 'CANCEL', label: 'Cancelado'}
        ],
        domicilio: [
            {value: '', label: 'Select'},
            {value: 'PREASSIGN:PENDING', label: 'Pendiente de aprobación por OS'},
            {value: 'PREASSIGN:NOTFOUND', label: 'No encontrado en el padrón'},
            {value: 'PREASSIGN:OS', label: 'Esperando aprobación de OS (ya solicitada)'},
            {value: 'PREASSIGN', label: 'Pre-asignado'},
            {value: 'DONE:REJECTED', label: 'Rechazado por OS'},
            {value: 'ASSIGN:DELIVERY', label: 'Asignado (en camino)'},
            {value: 'ASSIGN:ARRIVED', label: 'Asignado (en domicilio)'},
            {value: 'DONE', label: 'Realizado'},
            {value: 'DONE:LAB', label: 'En Laboratorio'},
            {value: 'DONE:RESULT', label: 'Realizado (con resultado)'},
            {value: 'CANCEL', label: 'Cancelado'},
            {value: 'CANCEL:REJECTED', label: 'Servicio rechazado por el paciente'},
            {value: 'CANCEL:ADDRESS', label: 'Dirección inexistente'},
            {value: 'ABSENT', label: 'Ausente'},
        ]
    }
    const handlerChange = ({target: {value}}:any) => {
        const patientFiltered = patients.filter((el:any) => el.data.status === value)
        dispatch({type: SET_PATIENTS_FILTERED, payload: { patientFiltered, key: 'status'}})
    }
    return (
        <select onChange={handlerChange}>
            {services[screen].map((op:any) => <option value={op.value}>{op.label}</option>)}            
        </select>
    )
}

const getNurses = async () => {
    const nurses = await db.firestore().collection('providers').where('rol', 'in', ['ENFERMERO', 'TECNICO', 'REMIS']).get()
    return nurses.docs.map(el => el.data())
}

export const NurseFilter = () => {
    const { nurses, patients } = useSelector((state: any) => state.patients);
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch();

    useEffect(() => {
        if(nurses.length === 0){
            setLoading(true)  
            getNurses().then((res) => {
              setLoading(false)  
              dispatch({type: SET_NURSES, payload: res})
            })
        }
    }, [])

    const handlerChange = ({value}:any) => {
        const patientFiltered = filterPatients(value, patients)
        dispatch({type: SET_PATIENTS_FILTERED, payload: { patientFiltered, key: 'nurse'}})
    }

    return (
        loading ? 
            <span>cargando...</span>
        :
            nurses.length === 0 ? 
                <span>No se encontraron enfermeros</span>
            :
            <div style={{width: 300}}>
                <Select
                    placeholder='Nombre del enfermero'
                    onChange={handlerChange}
                    options={nurses.map((el:any) => ({label: el.fullname, value: el.fullname}))}
                />
            </div>
    )
    
}

export const DateFilter = () => {
    const dispatch = useDispatch()
    const { patients } = useSelector((state: any) => state.patients)
    const [date, setDate] = useState<any>({from: '', to: ''})
    const [label, setLabel] = useState<string>('')

    const handlerFilter = () => {
        const patientFiltered = patients.filter((el:any) => {
            if (label === 'lab_date_result') {
                if(!el.data?.lab?.[label]?.toDate) return false
                console.log('aaaaaaaaaaaa')
                const elementDate = moment(el.data?.lab?.[label]?.toDate?.())
                return elementDate.isSameOrAfter(moment(date.from, 'YYYY-MM-DD')) && elementDate.isSameOrBefore(moment(date.to, 'YYYY-MM-DD'))
            } else {
                if(!el.data?.[label]?.toDate) return false
                const elementDate = moment(el.data?.[label]?.toDate?.())
                return elementDate.isSameOrAfter(moment(date.from, 'YYYY-MM-DD')) && elementDate.isSameOrBefore(moment(date.to, 'YYYY-MM-DD'))
            }
        })
        dispatch({type: SET_PATIENTS_FILTERED, payload: { patientFiltered, key: 'date'}})
    }
    return(
        <>
            <label htmlFor='fechas'>de: </label>
            <select id='fechas' onChange={e => setLabel(e.target.value)}>
                <option value=''>Seleccionar</option>
                <option value='dt_cierre'>Cierre</option>
                <option value='dt_create'>Creación</option>
                <option value='dt_assign'>Asignación</option>
                <option value='dt_cancel'>Cancelación</option>
                <option value='promise'>Promesa</option>
                <option value='lab_date_result'>Resultado</option>
            </select>
            <label htmlFor="from"> Desde: </label>
            <input type='date' id='from' onChange={e => setDate({...date, from: e.target.value})} />
            <label htmlFor="to"> Hasta: </label>
            <input type='date' id='to' onChange={e => setDate({...date, to: e.target.value})} />
            <button onClick={handlerFilter}>Filtrar</button>
        </>
    )
}

export const CompleteFilter = () => {
    const { patients } = useSelector((state: any) => state.patients)
    const dispatch = useDispatch();
    const handlerChange = ({target: {value}}:any) => {
        const patientFiltered = patients.filter((el:any) => ["DONE", "PENDING:RESULT", "DONE:RESULT"].includes(el.data.status) == value)
        dispatch({type: SET_PATIENTS_FILTERED, payload: { patientFiltered, key: 'complete'}})
    }
    return (
        <select onChange={handlerChange}>
            {/* hermosa jorgeada */}
            <option value={''}>Select</option>
            <option value={1}>Si</option>
            <option value={0}>No</option>
        </select>
    )
}

export const ResultFilter = () => {
    const { patients } = useSelector((state: any) => state.patients)
    const dispatch = useDispatch();
    const handlerChange = ({target: {value}}:any) => {
        console.log(value)
        const patientFiltered = patients.filter((el:any) => el.data.lab.result_lab == value)
        dispatch({type: SET_PATIENTS_FILTERED, payload: { patientFiltered, key: 'result'}})
    }
    return (
        <select onChange={handlerChange}>
            {/* hermosa jorgeada */}
            <option value=''>Select</option>
            <option value="POSITIVE">Positivo</option>
            <option value="NEGATIVE">Negativo</option>
            <option value="NOT_PROCESSED">No se pudo procesar</option>
        </select>
    )
}