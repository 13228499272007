import React, {useState} from 'react';
import { FaCarSide } from 'react-icons/fa'
import marker from '../../../../assets/markers/no_users.svg';
import assigned_services from '../../../../assets/markers/assigned_services.svg'
import vacunas_assigned from '../../../../assets/markers/vacunas_assigned.png'
import vacunas_preassigned from '../../../../assets/markers/vacunas_preassigned.png'
import myLocation from '../../../../assets/markers/myLocation.svg'
import './Marker.scss';
import { IconContext } from 'react-icons/lib';

function Marker({ id, type, address, name, service }:any) {
	const showMarker = (t: string) => {
		switch (t) {
			case 'assigned':
				if (service === 'VACUNA ANTIGRIPAL') {
					return <img src={vacunas_assigned} alt="Ubicacion de preasginados" width="40" height="46" />
				}
				return <img src={assigned_services} alt="Ubicacion de asignados" />
			case 'preassigned':
				if (service === 'VACUNA ANTIGRIPAL') {
					return <img src={vacunas_preassigned} alt="Ubicacion de preasginados" width="40" height="46" />
				}
				return <img src={marker} alt="Ubicacion de preasginados" />
			case 'myLocation':
				return <img src={myLocation} alt='Tu Ubicacion'/>
			case 'freeCar':
				return <FaCarSide/>
			case 'assignedCar':
				return <>
					<IconContext.Provider value={{ color: '#f56c42' }}>
						<FaCarSide />
					</IconContext.Provider>
				</>
			default:
				return null
		}
	}

	return (
		<div className='mapMarker'>

            <div className='mapMarker__container mapMarker__address'>
                <p className='mapMarker__container--text'>{id} - {address?.split(',')[0]} - {name}</p>
            </div>
			{ ['freeCar', 'assignedCar'].includes(type) &&
			<div className='mapMarker__container mapMarker__address_visible'>
                <p className='mapMarker__container--text'>{id}</p>
            </div>
			}
			
			<div className='mapMarker__container'>
				<span className='mapMarker__container--icon'>
					{showMarker(type)}
				</span>
			</div>
		</div>
	);
}

export default Marker;
