/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import GoogleMapReact from 'google-map-react';
import { useSelector } from 'react-redux'
import { handleApiLoaded, mapBounds, mapConfig } from '../../../../config/mapsApiHandlers';
import Marker from './Marker';
import './Map.scss'
import db from '../../../../config/db';
import Header from '../../../Header';
import moment from 'moment';

let obs = () => {}
function HisopadosPreassignMap() {
	const rol = useSelector((state: any) => state.user.user.rol)
	const firestore = db.firestore()
	const [hisopados, setHisopados] = useState<any>([])
	const [assignedServices, setAssignedServices] = useState<any>([]);
	const [freeCars, setFreeCars] = useState<any>([]);
	const [assignedCars, setAssignedCars] = useState<any>([]);
	const [map, setMap] = useState<any>();
	const [mapsApi, setMapsApi] = useState<any>();
	const [directionsDisplay, setDirectionsDisplay] = useState<any>();
	const [mapBounder, setMapBounder] = useState<any>()
	const [dateToQuery, setDateToQuery] = useState<any>({type: 'promise',from: moment().subtract(1, 'day').format('YYYY-MM-DD'), to: moment().format('YYYY-MM-DD')});
    const [currentPosition, setCurrentPosition] = useState({
        lat: -34.563080000,
        lng: -58.473610000
    })	
	useEffect(() => {
		if (rol) {
			obs()
			let query = firestore.collection('events/requests/delivery') as any
			 if (dateToQuery.type === 'promise') {
				query = query.orderBy(dateToQuery.type)
				.startAt(dateToQuery.from)
				.endAt(dateToQuery.to)
			} else {
				query = query
				.where(dateToQuery.type, '>=', new Date(moment(dateToQuery.from, 'YYYY-MM-DD').toString()))
				.where(dateToQuery.type, '<=', new Date(moment(dateToQuery.to, 'YYYY-MM-DD').toString()))
			}
			obs = query.onSnapshot((snap: any) => {
					let atts = [] as any;
					let assigned = [] as any;
					snap.forEach((att: any) => {
						let data = att.data();
						data = { ...data, doc_id: att.id };
						if([ "PREASSIGN"].includes(data.status)) {
							atts.push(data);
						} else if(["ASSIGN:DELIVERY", "ASSIGN:ARRIVED"].includes(data.status)) {
							assigned.push(data)
						}
					});
					setHisopados(atts);
					setAssignedServices(assigned);
				},
				(err:any) => {
					console.log(err);
				}
			);
		}
	}, [rol, dateToQuery.from, dateToQuery.to, dateToQuery.type]);

	useEffect(() => {
		try {
			let actualTime: any = new Date()
			let timeLimit: any = actualTime.setHours(actualTime.getHours() - 4)
			let formatedLimit = new Date(timeLimit)
			let subscribe = db
					.firestore()
					.collection("providers")
					.where("last_location.dateTime", ">", formatedLimit)
			subscribe.onSnapshot(
			(snap: any) => {
				let freeCarsArr = [] as any;
				let assignedCarsArr = [] as any;
				snap.forEach((att: any) => {
					let data = att.data();
					data = { ...data, doc_id: att.id };
					if(data.last_location.car_status === 'assigned') {
						assignedCarsArr.push(data)
					} else if(data.last_location.car_status === 'free') {
						freeCarsArr.push(data);
					}
				});
				setFreeCars(freeCarsArr);
				setAssignedCars(assignedCarsArr);
			},
			(err) => {
				console.log(err);
			}
		);
		} catch (err) {
			console.error(err)
			alert(err)
		}
	}, [])

	const handleMapLoaded = ({ map, maps }:any) => {
		handleApiLoaded(setCurrentPosition);
		setMap(map);
		setMapsApi(maps);
		setDirectionsDisplay(new maps.DirectionsRenderer());
		setMapBounder(() => mapBounds(map, maps));
	};
	
	useEffect(() => {
		if (typeof mapBounder === 'function') {
			mapBounder([...hisopados]);
		}
	}, [mapsApi, hisopados]);


	useEffect(() => {
		if (!!directionsDisplay) { directionsDisplay.setMap(map); }
	}, [directionsDisplay]);

	return (
        <>
        <Header />
		<div className='deliveryService__filtrosDeQuery'>
			<div>
				<span>Mostrando por fecha de </span>     
				<select 
					value={dateToQuery.type}
					onChange={(e) => setDateToQuery({...dateToQuery, type: e.target.value})} 
				>
					<option value='dt_cierre'>Cierre</option>
					<option value='dt_create'>Creación</option>
					<option value='dt_assign'>Asignación</option>
					<option value='dt_payment_success'>Pago</option>
					<option value='dt_cancel'>Cancelación</option>
					<option value='promise'>Promesa</option>
				</select>           
				<span>{dateToQuery.type !== 'promise' ? 'desde: ' : 'de: '}</span>
				<input onChange={(e) => setDateToQuery({...dateToQuery, from: e.target.value})} type='date' value={dateToQuery.from}></input>
				<span>hasta: </span>                
				<input onChange={(e) => setDateToQuery({...dateToQuery, to: e.target.value})} min={dateToQuery.from} type='date' value={dateToQuery.to}></input>
				<span> Preassignados: {hisopados.length}   </span>
				{/* <span>En curso: {assignedCars.length}</span> */}
			</div>
			<div>
				<span>Referencia de colores</span>
				<ul>
					<li>
						<div className="color hisopado"></div>
						Antígenos/PCR pendiente
					</li>
					<li>
						<div className="color hisopadoCurso"></div>
						Antígenos/PCR en curso
					</li>
					<li>
						<div className="color vacuna"></div>
						Vacuna pendiente
					</li>
					<li>
						<div className="color vacunaCurso"></div>
						Vacuna en curso
					</li>
					<li>
						<div className="color auto"></div>
						Auto libre
					</li>
					<li>
						<div className="color autoCurso"></div>
						Auto en servicio
					</li>
				</ul>
			</div>
		</div>		
		<div className='deliveryService__map'>
			<GoogleMapReact
				{...mapConfig({lat: currentPosition.lat, lng: currentPosition.lng })}
				onGoogleApiLoaded={handleMapLoaded}
			>
				{
					assignedServices
						.map((service: any) => {
							return (
								<Marker
									lat={service.destination.user_lat}
									lng={service.destination.user_lon}
									address={service.destination?.user_address}
									id={service.doc_id}
									type={'assigned'}
									name={service.patient.user}
									service={service.service}
								/>
							)
						})
				}
				{	
					hisopados
						.filter(({ destination }:any) => !!destination.user_lat && !!destination.user_lon)
						.map((hisopado:any) => {
							return (
								<Marker
									lat={hisopado.destination.user_lat}
									lng={hisopado.destination.user_lon}
									id={hisopado.doc_id}
									key={hisopado.incidente_id}
									address={hisopado.destination?.user_address}
									type={'preassigned'}
									name={hisopado.patient.user}
									service={hisopado.service}
								/>
           		 			);
						})
				}
				{
					assignedCars
						.map((car: any) => {
							return (
								<Marker
									lat={car.last_location?.lat || -34.5826842}
									lng={car.last_location?.lon || -58.460416}
									name={car.fullname}
									id={car.cuit}
									type={'assignedCar'}
								/>
							)
						})
				}
				{
					freeCars
						.map((car: any) => {
							return (
								<Marker
									lat={car.last_location?.lat || -34.5826842} 
									lng={car.last_location?.lon || -58.460416}
									name={car.fullname}
									id={car.cuit}
									type={'freeCar'}
								/>
							)
						})
				}
			</GoogleMapReact>
		</div>
        </>
	);
}
export default HisopadosPreassignMap;
