import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers';
import { composeWithDevTools,  } from 'redux-devtools-extension';

const options = {
    trace: false,
}

const composeEnhancers = composeWithDevTools(options);
export const store = createStore(rootReducer, {}, composeEnhancers(applyMiddleware(thunk)));